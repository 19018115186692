import { signOut } from "firebase/auth";
import { auth } from "../../Config/firebase";
import { Link, useNavigate } from "react-router-dom";
import talabat from '../../assets/new-employee.png'
import m7twa from '../../assets/pro-1-4.png'
import React, { useEffect, useState } from "react";
import { txtDB } from "../../Config/firebase";
import {  deleteDoc,doc, collection, getDocs } from "firebase/firestore";
import img3 from '../../assets/STATS4.png'
import img4 from '../../assets/pro-1-1.png'
import img5 from '../../assets/About-card-1.png'
import img6 from '../../assets/About-card2.png'
import check from '../../assets/cross.png'
import Swal from 'sweetalert2'











const ComplaintsRequests = () => {

  const [data,setData] = useState([])
  const navigate = useNavigate();

  const logout = async () => {
    await signOut(auth);
    navigate("/");
  };

  const getData = async () =>{
    const valRef = collection(txtDB,'Complaints Requests')
    const dataDb = await getDocs(valRef)
    const allData = dataDb.docs.map(val=>({...val.data(),id:val.id}))
    setData(allData)
}



useEffect(()=>{
    getData()
})

  return (
    <div className="p-3 md:pt-16">
      <div>
        <span className=" flex justify-between  text-center text-2xl text-black font-bold">
          Welcome Admin
          <div className=" justify-start">

          <button
            onClick={logout}
            className="btn bg-[rgb(47,114,140)] text-white rounded-md  "            >
            تسجيل الخروج
          </button>
              </div>
        </span>
      </div>
      {/* -----------------------ComplaintsRequests---------------------- */}
      <div className=" grid grid-cols-2 gap-2 pt-9 md:grid md:grid-cols-3 ">
        <Link to='/ContentMangment' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto"  width={70} height={70} src={m7twa} alt="Content"/>  <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center"> ادارة المحتوى</h1> </Link>
        <Link to='/IndividualRequests' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto" width={70} height={70} src={talabat} alt="Individual"/> <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center"> طلبات تمويل للافراد </h1> </Link>
        <Link to='/CompanyRequests' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto"  width={70} height={70} src={img3} alt="Company"/>  <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center"> طلبات تمويل للشركات</h1> </Link>
        <Link to='/SliderController' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto" width={70} height={70} src={img4} alt="Personal"/> <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center">التحكم في السلايدز</h1> </Link>
        {/* <Link to='/PersonalRequests' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto" width={70} height={70} src={img4} alt="Personal"/> <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center">طلبات تمويل شخصي </h1> </Link> */}
        <Link to='/ComplaintsRequests' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto" width={70} height={70} src={img5} alt="Complaints"/> <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center">الشكاوي </h1> </Link>
        <Link to='/JobWantedRequests' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto" width={70} height={70} src={img6} alt="JobWanted"/> <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center">طلبات التوظيف </h1> </Link>
      </div>

      {/* ------------شكاوي------------- */}
      <p className=' text-2xl text-black text-right font-bold p-4'>الشكاوي</p>
      <div className=' py-6 text-black'>
      <div>
      <div className="overflow-x-auto">
  <table className="table">
    <thead className="bg-black text-white ">
      <tr>
        <td> الاسم</td>
        <td>البريد الاكتروني</td> 
        <td>رقم الجوال</td> 
        <td>محتوى الرسالة</td>  
        <td>تم الطلب</td>  
        <th></th> 
      </tr>
    </thead> 
    <tbody>
        <th></th> 
        {
          data.map((data)=>{
            return(
              <>
              <tr>
        <td key={data.id} className=" border border-black border-solid">{data.FirstName}</td>
        <td className=" border border-black border-solid">{data.Email}</td> 
        <td className=" border border-black border-solid">{data.Phone}</td> 
        <td className=" border border-black border-solid">{data.Message}</td> 
        <td className=" border border-black border-solid"><button className=" btn bg-red-200 border border-black rounded-lg p-2 text-white "
        onClick={ ()=>{
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-danger"
            },
            buttonsStyling: false
          });
          swalWithBootstrapButtons.fire({
            title: "هل أنت متأكد من حذف العنصر ؟",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "نعم",
            cancelButtonText: "الغاء",
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
              async function deleteItem (id){
                const userDoc = doc(txtDB , "Complaints Requests" , id)
                await deleteDoc(userDoc)
              }
              deleteItem(data.id)
                          } else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
            ) {
              swalWithBootstrapButtons.fire({
                title: "تم الالغاء",
                text: "Your imaginary file is safe :)",
                icon: "error"
              });
            }
          });
        }}> <img className="w-6 h-6" src={check} alt='حذف'/></button></td> 

    </tr>
        </>
        )
      })
    }
    </tbody> 
  </table>
</div>
      </div>
      </div>
    </div>
  );
};

export default ComplaintsRequests;
