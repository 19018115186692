import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { txtDB } from "../Config/firebase";
import { Link } from "react-router-dom";
import jobImg from '../assets/wanted.jpg'
import Slider from "../Component/Slider";
import { Helmet } from "react-helmet-async";
function Jobs() {
    const [data , setData] = useState([])

  const getData = async () =>{
    const valRef = collection(txtDB,' JobOffers ')
    const dataDb = await getDocs(valRef)
    const allData = dataDb.docs.map(val=>({...val.data(),id:val.id}))
    setData(allData)
  }

    
  useEffect(()=>{
    getData()
  },[])

  return (
    <>
      <Helmet>
        <title>وظائف رؤية للتمويل - رؤية لتمويل السيارات</title>
        <meta
      name="description"
      content="وظائف شركة رؤية لتمويل السيارات  "
      /> 
        <link rel="canonical" href="/Jobs"></link>
      </Helmet>
      <Slider/>
      <div className=" flex flex-col md:grid md:grid-cols-3  ">
      {
          data.map((data)=>{
            return(
              <div className='p-7 md:pt-[80px]  '>
              <div  data-aos="fade-up" className=' border border-slate-600 rounded-2xl p-6'>
          <div>
            <img className=' rounded-2xl pb-3' src={jobImg} alt='wanted'/>
          </div>
          <div className='text-right text-black'>
            <p>{data.date}</p>
            <p className=' font-bold text-xl'>{data.Title}</p>
            <p>{data.textExplain}</p>
            <div className="py-2">
            <button className=' rounded-xl px-2  bg-teal-500 text-white'><Link to='/JobApplication'>التقدم للوظيفة</Link></button>
            </div>
          </div>
              </div>
              </div>
            )
          })
    }
    </div>
    </>
  )
}

export default Jobs
