import React from "react";
import { Helmet } from "react-helmet-async";
const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title> سياسة الخصوصية - رؤية لتمويل السيارات</title>
        <meta
      name="description"
      content=" طلب تمويل شخصي لرؤية لتمويل السيارات للافراد والشركات وللتمويل الشخصي "
      /> 
        <link rel="canonical" href="/PrivacyPolicy"></link>

      </Helmet>
    <div data-aos="fade-up" className="px-4 text-right pt-1 md:pt-[80px] md:max-w-[1140px] md:mx-auto ">
      <h1 className="text-[rgb(47,114,140)] text-center text-4xl pt-5 font-bold pb-[40px]">
        سياسة الخصوصية لدى رؤية
      </h1>

      <h3 className="text-black text-right text-2xl  font-bold pb-3">
        سياسة الخصوصية لدى رؤية
      </h3>
      <p className="text-black text-right pb-5 text-xl">
        تهدف سياسة الخصوصية إلى وصف إدارة البيانات الشخصية الخاصة بكم
        ("البيانات") التي حصَّلت عليها شركة رؤية للتمويل "Roya" من خلال هذا
        الموقع بالامتثال مع القانون المعمول به.
      </p>
      {/* ---------------------------------------------------------------- */}
      <h3 className="text-black text-right text-2xl  font-bold pb-3">
        فيما يلي البيانات التي قد تُحصَّل
      </h3>
      <p className="text-black text-right text-xl pb-5 ">
        تهدف سياسة الخصوصية إلى وصف إدارة البيانات الشخصية الخاصة بكم
        ("البيانات") التي حصَّلت عليها شركة رؤية للتمويل "Roya" من خلال هذا
        الموقع بالامتثال مع القانون المعمول به.
      </p>
      {/* ----------------------------------------------------------------------- */}
      <h3 className="text-black text-right text-2xl  font-bold pb-3">
      -: فيما يلي البيانات التي قد تُحصَّل 
      </h3>
      <p className="text-black text-right text-xl pb-5 ">
        البيانات الشخصية المُقدَّمة لتسلُّم خدمة معيَّنة (مثل اسم وبيانات فرد
        التواصل)؛ وبيانات التصفُّح (مثل: عنوان بروتوكول الإنترنت، والموقع -
        البلدة - والمعلومات على الصفحات التي زارها المستخدم في المواقع
        الإلكترونية، ووقت الدخول إلى الموقع الإلكتروني، ووقت التصفح في كل صفحة،
        وتحليل نشاط المستخدمين للموقع الإلكتروني. بينما لا تحصِّل الشركة هذه
        المعلومات لربطها بمستخدمين مُحدَّدين إلا أنه من الممكن تحديد هؤلاء
        المستخدمين سواء بطريق مباشر عن طريق تلك المعلومات أو باستخدام معلومات
        أخرى تم تحصيلها)؛ وملفات تعريف الارتباط (أي الملفات ذات النص الصغير التي
        قد تُرسلها وتُسجِّلها المواقع الإلكترونية التي زارها المستخدم على الحاسب
        الآلي لديه، ثم يُعاد إرسالها إلى تلك المواقع نفسها عندما يزورها المستخدم
        مرةً أخرى. يُرجى الإحالة إلى الموقع الإلكتروني: Cookie Policy
        للتفاصيل).غرض المعالجة وإجراءاتها
      </p>
      {/* ---------------------------------------------------------------------------------- */}
      <h3 className="text-black text-right text-2xl font-bold pb-3">
        :قد تُستخدم البيانات المُحصَّلة للأغراض الآتية{" "}
      </h3>
      <p className="text-black text-right pb-5 text-xl ">
        تنفيذ طلب خاص بأي مستخدم مُحدَّد أو تقديم الخدمة المطلوبة ("الخدمة")؛
        والسماح للشركة بأداء مسح حول رضا العملاء ("رضا العملاء") فيما يتعلق
        بجودة بضائع الشركة وخدماتها بالتوافق مع مصالح الشركة الشرعية؛ وإرسال
        المراسلات التجارية فضلاً عن إرسال إعلانات عن منتجات الشركة وخدماتها أو
        أداء بحوث السوق ("التمويل")؛تحليل سلوككم، وعاداتكم، وميولكم للاستهلاك
        لتحسين المنتجات والخدمات التي تُقدِّمها الشركة فضلاً عن الوفاء بتوقعاتكم
        ("التشخيص")؛ وإرسال بيانات إلى الشركات التابعة لدى الشركة وشركاتها
        الفرعية -رهناً بموافقتكم الصريحة- وكذلك شركائها في قطاعات المركبات،
        والقطاعات المالية، وقطاعات التأمين، والاتصالات التي يُعالجونها ويُرسلون
        المراسلات التجارية فضلاً عن الإعلان عن منتجاتهم وخدماتهم أو يُؤدُّون
        بحوث السوق ("التمويل ")؛ وتحسين تجربة المستخدم في مواقع الشركة
        الإلكترونية. قد تُعالج البيانات في نسخة مطبوعة بطرق تلقائية أو
        إلكترونية، بما في ذلك البريد أو البريد الإلكتروني، والهاتف (مثل:
        المكالمات الهاتفية التلقائية، والرسائل النصية القصيرة، وخدمة رسائل
        الوسائط المتعددة)، والفاكس، وأي طرق أخرى (مثل: المواقع الإلكترونية
        وتطبيقات الهواتف).متسلِّمي البيانات قد تُعالج البيانات من قبل أشخاص
        طبيعيين و/أو هيئات قانونية تتصرف بالنيابة عن الشركة وبموجب التزامات
        تعاقدية مُحدَّدة. قد تُرسَل البيانات إلى الغير للامتثال مع التزامات
        قانونية لتنفيذ أوامر السلطات الحكومية أو لممارسة الشركة حقها أمام
        السلطات القضائية.
      </p>
      {/* ---------------------------------------------------------------------------------- */}
      <h3 className="text-black text-right text-2xl  font-bold pb-3">
        أين تُعالج البيانات ؟
      </h3>
      <p className="text-black text-right pb-5 text-xl ">
        قد تنقل الشركة -فيما يتعلق بعلاقاتها التعاقدية- البيانات إلى بلدان أخرى
        بخلاف البلد التي تمكثون فيها، على سبيل المثال: قد تحفظ الشركة بالبيانات
        في قاعدة بيانات تُديرها هيئات تتصرف بالنيابة عن الشركة. إدارة قاعدة
        البيانات ومعالجة البيانات مرهونةً بأغراض المعالجة وتُنفَّذ بالتوافق مع
        القانون المعمول به. قد تنقل الشركة البيانات إلى أي بلد لا توفر المستوى
        نفسه من حماية البيانات مثل البلدة التي تمكثون فيها ولا تصنفها الهيئة
        المختصة بموجب القوانين المعمول بها بأنها تُقدِّم المستوى المناسب من
        حماية البيانات. لا ننقل البيانات إلى تلك البلدان إلا رهناً بالتدابير
        الاحترازية المناسبة التي تضمن حماية البيانات.
      </p>
      {/* ---------------------------------------------------------------------------------- */}
      <h3 className="text-black text-right  text-2xl font-bold pb-3">
        : الربط مع مواقع الغير
      </h3>
      <p className="text-black text-right pb-5 text-xl ">
        تخضع صلاحية الدخول على مواقع الغير من هذا الموقع الإلكتروني لمسؤولية
        الغير. ترفض الشركة المسؤولية كافةً المتعلقة بالطلبات و/أو توفير البيانات
        الشخصية لمواقع الغير.
      </p>
      {/* ---------------------------------------------------------------------------------- */}
      <h3 className="text-black text-right text-2xl  font-bold pb-3">
        من هو المتحكم في البيانات؟{" "}
      </h3>
      <p className="text-black text-right pb-5 text-xl">
        شركة رؤية والمالك لموقع Roya هو "المتحكم في البيانات"؛ بحيث يُقرِّر
        طريقة معالجة البيانات والغرض منها. إذا كانت لديكم أي أسئلة عن هذه
        السياسة أو معالجتنا للبيانات يمكنكم التواصل معنا على البريد
        الإلكتروني(royafunding@gmail.com).
      </p>
      {/* ---------------------------------------------------------------------------------- */}
      <h3 className="text-black text-right text-2xl  font-bold pb-3">حفظ البيانات</h3>
      <p className="text-black text-right pb-5  text-xl">
        تحتفظ شركة رؤية والمالك لموقع رؤية بالبيانات التي تم معالجتها لتوفير
        الخدمة وتحقيق رضا العملاء وجمع المعلومات والتسويق للفترة التي تعتبر
        ضرورية للغاية لتحقيق هذه الأغراض. ومع ذلك، قد تستمر الشركة في تخزين هذه
        البيانات لفترة أطول حسب الضرورة لحماية مصالح الشركة المتعلقة بالمسؤولية
        المحتملة ذات صلة بتقديم الخدمة. إذا تحصَّلت الشركة على موافقتكم الصريحة
        لمعالجة البيانات لغرض معين تحتفظ الشركة بالبيانات التي تم معالجتها لهذا
        الغرض من اللحظة التي تمنحون فيها الموافقة حتى إلغائها. وبمجرد إلغاء
        الموافقة، لن تستخدم البيانات للأغراض ذات الصلة، الى أن الشركة قد تحتفظ
        بها لاسيما عند الضرورة لحماية مصالحها المتعلقة بالمسؤولية المحتملة
        المتعلقة بعملية المعالجة ما لم يُقدَّم مزيد من التوضيح بواسطة السلطة
        الإشرافية المختصة في هذا الصدد. يحتفظ بعملية معالجة البيانات لتحسين
        تجربة المستخدم على المواقع الإلكترونية للفترات المشار إليها في سياسة
        ملفات تعريف الارتباط الخاصة بالشركة.
      </p>
      {/* ---------------------------------------------------------------------------------- */}
      <h3 className="text-black text-right text-2xl font-bold pb-3">
        :حقوق أصحاب البيانات{" "}
      </h3>
      <p className="text-black text-right pb-5 text-xl ">
        لدى أصحاب البيانات الحق في: الوصول إلى البيانات؛ أي الحق في الاطلاع على
        البيانات التي تحتفظ بها الشركة والحصول عليها سواء كانت بياناتكم قيد
        المعالجة أم لا حسب الاقتضاء. التصحيح والتعديل والحذف؛ أي الحق في تصحيح
        أو تعديل البيانات غير الدقيقة وغير الصحيحة وغير المكتملة أو كلاهما معاً،
        وكذلك حذف البيانات المعدلة أو استبدالها إذا كان الطلب المقدم بذلك
        قانونياً. تقييد المعالجة؛ أي الحق في طلب تعليق المعالجة إذا كان الطلب
        المقدم بذلك قانونياً. قابلية نقل البيانات؛ أي الحق في الحصول على
        البيانات بتنسيق منظم وعادي ومستخدم وقابل للقراءة، وكذلك الحق في نقل
        البيانات إلى وحدات تحكم أخرى. الاعتراض؛ يعني حق الاعتراض على معالجة
        البيانات عندما يكون الطلب قانونياً، بما في ذلك عندما تُعالج البيانات
        لأغراض التسويق أو التشخيص حسب الاقتضاء. تقديم شكوى إلى سلطة إشرافية في
        حال المعالجة غير القانونية للبيانات. لدى صاحب البيانات الحق أيضاً في
        إلغاء الموافقة على معالجة البيانات في أي وقت. يُرجى العلم أن إلغاء
        الموافقة لا ينطبق إلا في المستقبل وليس له أثر رجعي. لا تتأثر المعالجة
        التي تم تنفيذها قبل إخطار الإلغاء. يمكن لأصحاب البيانات ممارسة الحقوق
        المذكورة أعلاه خطياً من خلال حدِّد عنوان البريد أو البريد الإلكتروني
        حدِّد عنوان البريد الإلكتروني.
      </p>
      {/* ---------------------------------------------------------------------------------- */}
      <h3 className="text-black text-right  font-bold pb-3 underline text-2xl">
        حُّررت سياسة الخصوصية هذه في (03-02-2024).
      </h3>
      <p className="text-black text-right pb-5 text-xl ">
        تحتفظ شركة رؤية  المالك لموقع رؤية بالحق في
        تعديل سياسة الخصوصية جزئياً أو كلياً، أو تحديث محتواها (على سبيل المثال:
        نتيجة التغييرات في القانون المعمول به). تنشر الشركة أي تحديثات على هذا
        الموقع الإلكتروني{" "}
      </p>
    </div>
    </>
  );
};

export default PrivacyPolicy;
