import { signOut } from "firebase/auth";
import { auth } from "../../Config/firebase";
import { Link, useNavigate } from "react-router-dom";
import talabat from '../../assets/new-employee.png'
import m7twa from '../../assets/pro-1-4.png'
import React, { useEffect, useState } from "react";
import { txtDB } from "../../Config/firebase";
import { deleteDoc, doc , collection, getDocs ,updateDoc } from "firebase/firestore";
import img3 from '../../assets/STATS4.png'
import img4 from '../../assets/pro-1-1.png'
import img5 from '../../assets/About-card-1.png'
import img6 from '../../assets/About-card2.png'
import check from '../../assets/cross.png'
import Swal from 'sweetalert2'

const CompanyRequests = () => {

  const [data,setData] = useState([])
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [RequestStatus , setRequestStatus ] = useState("")

  const filteredItems = selectedCategory === 'all' ? data : data.filter(item => item.RequestStatus === selectedCategory);

  const logout = async () => {
    await signOut(auth);
    navigate("/");
  };

  const getData = async () =>{
    const valRef = collection(txtDB,'Company Requests')
    const dataDb = await getDocs(valRef)
    const allData = dataDb.docs.map(val=>({...val.data(),id:val.id}))
    setData(allData)
}


useEffect(()=>{
    getData()
})


const updateItem = async (id)=> {
  const userDoc = doc(txtDB ,"Company Requests" , id  )
  const updateData = await updateDoc(userDoc , { RequestStatus: RequestStatus })
}


  return (
    <div className="p-3 md:pt-16">
      <div>
        <span className=" flex justify-between  text-center text-2xl text-black font-bold">
          Welcome Admin
          <div className=" justify-start">

          <button
            onClick={logout}
            className="btn bg-[rgb(47,114,140)] text-white rounded-md  "            >
            تسجيل الخروج
          </button>
              </div>
        </span>
      </div>
      {/* -----------------------CompanyRequests---------------------- */}
      <div className=" grid grid-cols-2 gap-2 pt-9 md:grid md:grid-cols-3 ">
        <Link to='/ContentMangment' className="border border-[rgbs(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto"  width={70} height={70} src={m7twa} alt="Content"/>  <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center"> ادارة المحتوى</h1> </Link>
        <Link to='/IndividualRequests' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto" width={70} height={70} src={talabat} alt="Individual"/> <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center"> طلبات تمويل للافراد </h1> </Link>
        <Link to='/CompanyReqsuests' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto"  width={70} height={70} src={img3} alt="Company"/>  <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center"> طلبات تمويل للشركات</h1> </Link>
        <Link to='/SliderController' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto" width={70} height={70} src={img4} alt="Personal"/> <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center">التحكم في السلايدز</h1> </Link>
        {/* <Link to='/PersonalRequests' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto" width={70} height={70} src={img4} alt="Personal"/> <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center">طلبات تمويل شخصي </h1> </Link> */}
        <Link to='/ComplaintsRequests' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto" width={70} height={70} src={img5} alt="Complaints"/> <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center">الشكاوي </h1> </Link>
        <Link to='/JobWantedRequests' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto" width={70} height={70} src={img6} alt="JobWanted"/> <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center">طلبات التوظيف </h1> </Link>
      </div>

      {/* ------------طلب الشركات------------- */}
      <p className=' text-2xl text-black text-right font-bold p-4'>طلبات الشركات</p>
      <div className=" text-white flex justify-center align-middle">
      {/* Category Filter */}
      <select className=" px-9 py-3 rounded-lg bg-white border border-black text-black" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
        <option value="all">كل الطلبات</option>
        {/* Assuming categories are derived from items */}
        {[...new Set(data.map(item => item.RequestStatus))].map(RequestStatus => (
          <option key={RequestStatus} value={RequestStatus}>{RequestStatus}</option>
        ))}
      </select>
      <p className="text-black text-xl py-2 font-bold ">     :الفلتر الحالي </p>
    </div>
      <div className=' py-6 text-black'>
      <div>
      <div className="overflow-x-auto">
  <table className="table ">
    <thead className=" bg-black text-white ">
      <tr>
        <td > اسم الشركة</td>
        <td>اسم مسؤول الشركة</td> 
        <td> رقم الجوال </td> 
        <td>البريد الاكتروني</td> 
        <td>نشاط الشركة</td> 
        <td>عمر الشركة</td> 
        <td>المدينة</td>
        <td>عدد الفروع</td>
        <td>عدد السيارات</td>
        <td>البنك</td>
        <td>رقم السجل التجاري</td>
        <td>الالتزامات المالية</td>
        <td>صورة السجل التجاري</td>
        <td>صورة رقم الايبان</td>
        <td>الملاحظات الاضافية</td>
        <td>حالة الطلب</td>
        <td>حذف الطلب</td>
        <td> التعديل على حالة الطلب</td>
        <th></th> 
      </tr>
    </thead> 
    <tbody>
        <th className='text-white'></th> 
        {
          filteredItems.map((data)=>{
            return(
              <>
              <tr>
        <td key={data.id} className=" border border-black border-solid">{data.CompanyNameVal}</td>
        <td className=" border border-black border-solid">{data.CompanyOwnerVal}</td> 
        <td className=" border border-black border-solid">{data.PhoneNumberVal}</td>
        <td className=" border border-black border-solid">{data.EmailVal}</td> 
        <td className=" border border-black border-solid">{data.CompanyFieldVal}</td> 
        <td className=" border border-black border-solid">{data.CompanyAgeVal}</td> 
        <td className=" border border-black border-solid">{data.CityVal}</td>
        <td className=" border border-black border-solid">{data.NumberOfBranchesVal}</td>
        <td className=" border border-black border-solid">{data.NumberOfCarsVal}</td>
        <td className=" border border-black border-solid">{data.BankVal}</td>
        <td className=" border border-black border-solid">{data.CommercialRegistrationVal}</td>
        <td className=" border border-black border-solid">{data.FinancialObligationsVal}</td>
        <td className=" border border-black border-solid"><a href={data.CommercialRegistrationImgVal} >صورة السجل التجاري </a></td>
        <td className=" border border-black border-solid"><a href={data.NumberOfIbanImgVal}>صورة الايبان </a></td>
        <td className=" border border-black border-solid">{data.ExtraNoteVal}</td>
        <td className=" border border-black border-solid">{data.RequestStatus}</td>
        <td className=" border border-black border-solid text-center "><button className=" w-20 btn bg-red-200 border border-black rounded-lg text-white " onClick={ ()=>{
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-danger"
            },
            buttonsStyling: false
          });
          swalWithBootstrapButtons.fire({
            title: "هل أنت متأكد من حذف العنصر ؟",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "نعم",
            cancelButtonText: "الغاء",
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
              async function deleteItem (id){
                const userDoc = doc(txtDB , "Company Requests" , id)
                await deleteDoc(userDoc)
              }
              deleteItem(data.id)
                          } else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
            ) {
              swalWithBootstrapButtons.fire({
                title: "تم الالغاء",
                icon: "error"
              });
            }
          });
        }} > <img className=" w-20 h-6" src={check} alt='حذف'/></button></td> 
        <td>
        <select className=" bg-white rounded-lg border border-black border-solid text-center text-white p-4 " onChange={(e)=>{setRequestStatus(e.target.value)} } onClick={()=>{updateItem(data.id)}}>
          <option className=" bg-gray-400" ></option>
          <option value="جديد">جديد</option>
          <option value="قيد العمل" >قيد العمل</option>
          <option value="مهم">مهم</option>
          </select> 
        </td>        
        </tr>
        </>
        )
      })
    }
    </tbody> 
  </table>
</div>
      </div>
      </div>
    </div>
  );
};

export default CompanyRequests;
