// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getStorage} from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCxrVzW0f99ypIQj4SJhJ3RQc9gco4kI0k",
  authDomain: "roya-funding.firebaseapp.com",
  projectId: "roya-funding",
  storageBucket: "roya-funding.appspot.com",
  messagingSenderId: "960032577631",
  appId: "1:960032577631:web:a84be88f833e5986ff63ea",
  measurementId: "G-1ZVDE8QKE7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app) 
export const txtDB = getFirestore(app)
export const imgDB = getStorage(app)

