import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { txtDB } from "../Config/firebase";
import { Link } from "react-router-dom";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import Slider from "../Component/Slider";
import { Helmet } from "react-helmet-async";

function Offers() {
const [selcetedCategory , setSelectedCategory] =useState('all')

const [loading, setLoading] = useState(true);
const [toggle , setToggle]= useState(true)

const [post , setPost] = useState([])


      const getData = async () =>{
        const valRef = collection(txtDB,'Offers')
        const dataDb = await getDocs(valRef)
        const allData = dataDb.docs.map(val=>({...val.data(),id:val.id}))
        setPost(allData)
        
      }

      useEffect(()=>{
        getData()
        setLoading(false);
        setToggle(false)
      },[])

      const [CarMarka , setCarMarka ] = useState([])
      const [Filter , setFilter ] = useState([])
    
      let Cars = [
        {
          name:" تويوتا ",
          TypeOfCars:[
            {
              name:"كامري"
            },
            {
              name:"يارس"
            },
            {
              name:"افالون"
            },
            {
              name:"كورولا"
            },
            {
              name:"راف فور"
            },
            {
              name:"لاندكروز"
            },
            {
              name:"هايلوكس"
            },
            {
              name:"هايلاندر"
            },
            {
              name:"فورتشنر"
            },
            {
              name:"اينوفا"
            },
            {
              name:"برادو"
            },
            {
              name:"شاص"
            },
            {
              name:"ايكو"
            },
            {
              name:" باص"
            },
            {
              name:"اف جي FG"
            },
            {
              name:" شاص"
            },
            {
              name:" ربع"
            },
            {
              name:"افانزا"
            },
            {
              name:"تندرا"
            },
            {
              name:"C-HR"
            },
            {
              name:"بريوس"
            },
            {
              name:"كرولا كروس"
            },
            {
              name:"راش"
            },
            {
              name:"ربع"
            },
            {
              name:"اريزو"
            },
            {
              name:"T-80"
            },
            {
              name:"رايز"
            },
            {
              name:"اوريون"
            },
            {
              name:"كرسيدا"
            },
            {
              name:"XA"
            },
      
            {
              name:"بريفيا"
            },
            {
              name:"سوبرا"
            },
            {
              name:"تويوتا86"
            },
            {
              name:"جرانفيا"
            },
            {
              name:"فيلوز"
            },
            {
              name:"كراون"
            },
            {
              name:"اوربان كروز "
            }
          ]
        },
        {
          name:" فورد ",
          TypeOfCars:[
            {
              name:"كراون فكتوريا"
            },
            {
              name:"جراند ماركيز"
            },
            {
              name:"اكسبلورر"
            },
            {
              name:"توروس"
            },
            {
              name:"اكسبيدشن"
            },
            {
              name:"موستنج"
            },
            {
              name:"ايدج"
            },
            {
              name:"F150"
            },
            {
              name:"فيوجن"
            },
            {
              name:"وندستار"
            },
            {
              name:"فلكس"
            },
            {
              name:"فوكس"
            },
            {
              name:"مونديو"
            },
            {
              name:" F250"
            },
            {
              name:"F350"
            },
            {
              name:" رينجر"
            },
            {
              name:" اكس كورجن"
            },
            {
              name:"بيك اب"
            },
            {
              name:"اسكيب"
            },
            {
              name:"سبلاش"
            },
            {
              name:"كوجر"
            },
            {
              name:"ثندريبرد"
            },
            {
              name:"F450"
            },
            {
              name:"F550"
            },
            {
              name:"إسكورت"
            },
            {
              name:"ابكوسبورت"
            },
            {
              name:"فان فورد"
            },
            {
              name:"فيجو"
            },
            {
              name:"برونكو"
            },
            {
              name:"تيريتوري"
            },
          ]
        },
        {
          name:" شيفروليه ",
          TypeOfCars:[
            {
              name:"كابريس"
            },
            {
              name:"تاهو"
            },
            {
              name:"سوبربان"
            },
            {
              name:"لومينا"
            },
            {
              name:"سلفرادو"
            },
            {
              name:"كمارو"
            },
            {
              name:"بليزر"
            },
            {
              name:"ابيكا"
            },
            {
              name:"ماليبو"
            },
            {
              name:"افيو"
            },
            {
              name:"كروز"
            },
            {
              name:"اوبترا"
            },
            {
              name:"تريل بليزر"
            },
            {
              name:" افلانش"
            },
            {
              name:"كورفيت"
            },
            {
              name:" فان"
            },
            {
              name:"امبالا"
            },
            {
              name:"ترافيس"
            },
            {
              name:"ابلاندر"
            },
            {
              name:"اكسبرس فان"
            },
            {
              name:"فنشر"
            },
            {
              name:"كابتيفا"
            },
            {
              name:"استروفان"
            },
            {
              name:"سونيك"
            },
            {
              name:"سبارك"
            },
            {
              name:"كرفان"
            },
            {
              name:"كافالير"
            },
            {
              name:"كولورادو"
            },
            {
              name:"جي فان"
            },
            {
              name:"اكوينوكس"
            },
            {
              name:"بولت"
            },
            {
              name:"جروف"
            },
          ]
        },
        {
          name:" نيسان ",
          TypeOfCars:[
            {
              name:"باترول"
            },
            {
              name:"ددسن"
            },
            {
              name:"التيما"
            },
            {
              name:"مكسيما"
            },
            {
              name:"باثفندر"
            },
            {
              name:"صني"
            },
            {
              name:"ارمادا"
            },
            {
              name:"اكستيرا"
            },
            {
              name:"فئة Z"
            },
            {
              name:"نيسان شاص"
            },
            {
              name:"نافارا"
            },
            {
              name:"مورانو"
            },
            {
              name:"تيدا"
            },
            {
              name:" اورفان"
            },
            {
              name:"سكاي لاين"
            },
            {
              name:" سنترا"
            },
            {
              name:"اكس تربل"
            },
            {
              name:"جلوريا"
            },
            {
              name:"بريميرا"
            },
            {
              name:"تيرانو"
            },
            {
              name:"قاشقاي"
            },
            {
              name:"جوك"
            },
            {
              name:"كيكس"
            },
            {
              name:"370Z"
            },
            {
              name:"GTR"
            },
            {
              name:"سيفيليان"
            },
            {
              name:"باترول سفاري"
            },
            {
              name:"سيدرك"
            },
            {
              name:"باترول نيسمو"
            },
          ]
        },
        {
          name:"هيونداي",
          TypeOfCars:[
            {
              name:"سوناتا"
            },
            {
              name:"النترا"
            },
            {
              name:"تراجيت"
            },
            {
              name:"i 40"
            },
            {
              name:"اكسنت"
            },
            {
              name:"كوبية"
            },
            {
              name:"فيراكروز"
            },
            {
              name:"تيريكان"
            },
            {
              name:"توسان"
            },
            {
              name:"جراند سنتافي"
            },
            {
              name:"ستنافي"
            },
            {
              name:"ازيرا"
            },
            {
              name:"جنيسس"
            },
            {
              name:"سنتنيال"
            },
            {
              name:"فيلوستر"
            },
            {
              name:"i-10"
            },
            {
              name:"كونا"
            },
            {
              name:"باليسيد"
            },
            {
              name:"جراند ستنافي"
            },
            {
              name:"H1"
            }
            ,
            {
              name:"ماتريكس"
            }
            ,
            {
              name:"جالوير"
            }
            ,
            {
              name:"كريتا"
            }
            ,
            {
              name:"i 30"
            }
            ,
            {
              name:"فينيو"
            }
          ]
        }
        ,
        {
          name:"لكزس",
          TypeOfCars:[
            {
              name:"LS"
            },
            {
              name:"LX"
            },
            {
              name:"ES"
            },
            {
              name:"GS"
            },
            {
              name:"IS"
            },
            {
              name:"RX"
            },
            {
              name:"GX"
            },
            {
              name:"SC"
            },
            {
              name:"NX"
            },
            {
              name:"LC"
            },
            {
              name:"RC"
            },
            {
              name:"RCF"
            },
            {
              name:"UX"
            },
            {
              name:"GSF"
            }
          ]
        },
        {
          name:" جي ام سي - GMC",
          TypeOfCars:[
            {
              name:"يوكن"
            },
            {
              name:"سوبربان"
            },
            {
              name:"سييرا"
            },
            {
              name:" بيك أب"
            },
            {
              name:"انفوي"
            },
            {
              name:"اكاديا"
            },
            {
              name:"فان"
            },
            {
              name:"سافانا"
            },
            {
              name:"سفاري"
            },
            {
              name:"تيرين"
            },
            {
              name:"جيمي"
            },
          ]
        },
        {
          name:"مرسيدس",
          TypeOfCars:[
            {
              name:"S"
            },
            {
              name:"E"
            },
            {
              name:"SE"
            },
            {
              name:"SEL"
            },
            {
              name:"AMG"
            },
            {
              name:"مرسيدس G"
            },
            {
              name:"C"
            },
            {
              name:"SL"
            },
            {
              name:"CLS"
            },
            {
              name:"ML"
            },
            {
              name:"CL"
            },
            {
              name:"CLK"
            },
            {
              name:"SEC"
            },
            {
              name:"SLK"
            },
            {
              name:"A الفئة"
            },
            {
              name:"GLS"
            },
            {
              name:"GLE"
            },
            {
              name:"GLC"
            },
            {
              name:"GLA"
            },
            {
              name:"CLA"
            }
            ,
            {
              name:"V الفئة"
            }
            ,
            {
              name:"B"
            }
            ,
            {
              name:"GL"
            }
            ,
            {
              name:"GLK"
            }
            ,
            {
              name:"GT مرسيدس"
            }
            ,
            {
              name:"GTS"
            }
            ,
            {
              name:"R"
            }
            ,
            {
              name:" SLC"
            }
            ,
            {
              name:" فان سبرنتر"
            }
            ,
            {
              name:"مايباخ"
            }
            ,
            {
              name:"GLB"
            }
          ]
        }
        ,
        {
          name:"هوندا",
          TypeOfCars:[
            {
              name:"اكورد"
            },
            {
              name:"سيفيك"
            },
            {
              name:"اوديسي"
            },
            {
              name:"CRV"
            },
            {
              name:"بايلوت"
            },
            {
              name:"سيتي"
            },
            {
              name:"ليجيند"
            },
            {
              name:"بريليود"
            },
            {
              name:"انتجرا"
            },
            {
              name:"HRV"
            }
          ]
        },
        {
          name:"بي ام دبليو - BMW",
          TypeOfCars:[
            {
              name:"الفئة السابعة"
            },
            {
              name:"الفئة الخامسة"
            },
            {
              name:"X الفئة "
            },
            {
              name:"الفئة الثالثة"
            },
            {
              name:"الفئة السادسة"
            },
            {
              name:"الفشة الأولى"
            },
            {
              name:" M الفئة"
            },
            {
              name:"ميني كوبر"
            },
            {
              name:"Z الفئة"
            },
            {
              name:"i الفئة"
            },
            {
              name:"الفئة الثامنة"
            },
            {
              name:"الفئة الثانية"
            },
            {
              name:"الفئة الرابعة"
            }
          ]
        },
        {
          name:"كيا",
          TypeOfCars:[
            {
              name:"اوبتيما"
            },
            {
              name:"سيراتو"
            },
            {
              name:"ريو"
            },
            {
              name:"كارنيفال"
            },
            {
              name:"سبورتاج"
            },
            {
              name:"كادينزا"
            },
            {
              name:"اوبيروس"
            },
            {
              name:"سورنتو"
            },
            {
              name:"كارينز"
            },
            {
              name:"بيكانتو"
            },
            {
              name:"موهافي"
            },
            {
              name:"كوريس"
            },
            {
              name:"سول"
            },
            {
              name:"سيفيا"
            },
            {
              name:"K900"
            },
            {
              name:"بيجاس"
            },
            {
              name:"تيلورايد"
            },
            {
              name:"ستينجر"
            },
            {
              name:"سيلتوس"
            },
            {
              name:"نيرو"
            }
            ,
            {
              name:"K5"
            }
            ,
            {
              name:"سونيت"
            }
            ,
            {
              name:"K8"
            }
          ]
        },
        {
          name:"دودج",
          TypeOfCars:[
            {
              name:"تشارجر"
            },
            {
              name:"تشالنجر"
            },
            {
              name:"دورانجو"
            },
            {
              name:"كارافان"
            },
            {
              name:"رام"
            },
            {
              name:"نيترو"
            },
            {
              name:"كاليبر"
            },
            {
              name:"فايبر"
            },
            {
              name:"دودج بيك أب"
            },
            {
              name:"كارافان"
            },
            {
              name:"انتربيد"
            }
          ]
        },
        {
          name:"ميتسوبيشي",
          TypeOfCars:[
            {
              name:"باجيرو"
            },
            {
              name:"لانسر"
            },
            {
              name:"L200"
            },
            {
              name:"ناتيفا"
            },
            {
              name:"جالنت"
            },
            {
              name:"كولت"
            },
            {
              name:"ماجنا"
            },
            {
              name:"سيجما"
            },
            {
              name:"ASX"
            },
            {
              name:"اتراج"
            },
            {
              name:" اكليبس كروس"
            },
            {
              name:"اوتلاند"
            },
            {
              name:"سبيس ستار"
            },
            {
              name:"مونتيرو"
            },
            {
              name:"اكسباندر"
            },
            {
              name:"جرانديز"
            }
          ]
        },
        {
          name:"مازدا",
          TypeOfCars:[
            {
              name:"مازدا6"
            },
            {
              name:"CX9"
            },
            {
              name:"مازدا 3"
            },
            {
              name:"323"
            },
            {
              name:"626"
            },
            {
              name:"CX7"
            },
            {
              name:"BT50"
            },
            {
              name:"MPV"
            },
            {
              name:"CX5"
            },
            {
              name:"CX2"
            },
            {
              name:"RX8"
            },
            {
              name:"MX-5"
            },
            {
              name:"CX3"
            },
            {
              name:"مازدا 2"
            },
            {
              name:"مازدا 5"
            },
            {
              name:"CX30"
            }
          ]
        },
        {
          name:"ايسوزو",
          TypeOfCars:[
            {
              name:"اسندر"
            },
            {
              name:"ديماكس"
            },
            {
              name:"MUX"
            }
          ]
        },
        {
          name:"سوزوكي",
          TypeOfCars:[
            {
              name:"فيتارا"
            },
            {
              name:"ساموراي"
            },
            {
              name:"سويفت"
            },
            {
              name:"جمني"
            },
            {
              name:"ليانا"
            },
            {
              name:"SX4"
            },
            {
              name:"ارتيجا"
            },
            {
              name:"بالينو"
            },
            {
              name:"جراند فيتارا"
            },
            {
              name:"سياز"
            },
            {
              name:"سيليريو"
            },
            {
              name:"APV بيك أب"
            },
            {
              name:"APV فان"
            },
            {
              name:"APV كاري"
            },
            {
              name:"ديزاير"
            },
            {
              name:"كيزاشي"
            }
          ]
        },
        {
          name:"جيلي",
          TypeOfCars:[
            {
              name:"EC7"
            },
            {
              name:"EC8"
            },
            {
              name:"باندا"
            },
            {
              name:"امجراند7"
            },
            {
              name:"GS امجراند"
            },
            {
              name:"X7 امجراند"
            },
            {
              name:"بينراي"
            },
            {
              name:"كولراي"
            },
            {
              name:"ازكارا"
            },
            {
              name:"توجيلا"
            },
            {
              name:"اوكافانجو "
            },
            {
              name:"مونجارو"
            }
          ]
        },
        {
          name:"بيجو",
          TypeOfCars:[
            {
              name:"307"
            },
            {
              name:"407"
            },
            {
              name:"206"
            },
            {
              name:"508"
            },
            {
              name:"406"
            },
            {
              name:"بارتنر"
            },
            {
              name:"607"
            },
            {
              name:"404"
            },
            {
              name:"3008"
            },
            {
              name:"301"
            },
            {
              name:"5008"
            },
            {
              name:"بوكسر"
            },
            {
              name:"اكسبيرت"
            }
          ]
        },
        {
          name:"بايك",
          TypeOfCars:[
            {
              name:" D50 "
            },
            {
              name:" X7 "
            }
          ]
        },
        {
          name:"فاو",
          TypeOfCars:[
            {
              name:"B70"
            },
            {
              name:"T70"
            }
          ]
        },
        {
          name:"هافال",
          TypeOfCars:[
            {
              name:"جوليان"
            },
            {
              name:"H6"
            }
          ]
        },
        {
          name:"ماكسوس",
          TypeOfCars:[
            {
              name:"T90"
            },
            {
              name:"T60"
            }
          ]
        },
        {
          name:"MG ام جي",
          TypeOfCars:[
            {
              name:"5"
            },
            {
              name:"6"
            },
            {
              name:"HS"
            },
            {
              name:"MG RX8"
            },
            {
              name:"RX5"
            },
            {
              name:"ZS"
            },
            {
              name:"T60"
            },
            {
              name:"ام جي جي تي"
            },
          ]
        },
        {
          name:"كيا",
          TypeOfCars:[
            {
              name:"اوبتيما"
            },
            {
              name:"سيراتو"
            },
            {
              name:"ريو"
            },
            {
              name:"كارنيفال"
            },
            {
              name:"سبورتاج"
            },
            {
              name:"كادينزا"
            },
            {
              name:"اوبيروس"
            },
            {
              name:"سورنتو"
            },
            {
              name:"كارينز"
            },
            {
              name:"بيكانتو"
            },
            {
              name:"موهافي"
            },
            {
              name:"كوريس"
            },
            {
              name:"سول"
            },
            {
              name:"سيفيا"
            },
            {
              name:"K900"
            },
            {
              name:"بيجاس"
            },
            {
              name:"تيلورايد"
            },
            {
              name:"ستينجر"
            },
            {
              name:"سيلتوس"
            },
            {
              name:"نيرو"
            }
            ,
            {
              name:"K5"
            }
            ,
            {
              name:"سونيت"
            }
            ,
            {
              name:"K8"
            }
          ]
        },
        {
          name:"شانجان",
          TypeOfCars:[
            {
              name:"ايدو"
            },
            {
              name:"CS35"
            },
            {
              name:"CS75"
            },
            {
              name:"CS95"
            },
            {
              name:" V7 شانجان"
            },
            {
              name:"CS85"
            },
            {
              name:"السفن "
            },
            {
              name:"هنتر"
            },
            {
              name:"CS35 بلس"
            },
            {
              name:"CS75 بلس"
            },
            {
              name:"يوني تي"
            },
            {
              name:"يوني كي"
            },
            {
              name:"يوني في"
            }
            
          ]
        },
        {
          name:"رينو",
          TypeOfCars:[
            {
              name:"ميجان"
            },
            {
              name:"فلوانس"
            },
            {
              name:"سافران"
            },
            {
              name:"لاغونا"
            },
            {
              name:" كليو"
            },
            {
              name:"تاليسمان"
            },
            {
              name:"داستر "
            },
            {
              name:"دوكر فان"
            },
            {
              name:"سيمبول"
            },
            {
              name:"كابشتر"
            },
            {
              name:"كوليوس"
            },
            {
              name:"ماستر"
            },
            {
              name:"ميجان GT"
            }
            ,
            {
              name:"ميجان RS"
            }
          ]
        },
        {
          name:"شيري",
          TypeOfCars:[
            {
              name:"QQ"
            },
            {
              name:"A5 شيري"
            },
            {
              name:"ايستار"
            },
            {
              name:"كوين"
            },
            {
              name:" A3 شيري "
            },
            {
              name:"A1 شيري"
            },
            {
              name:"اريزو 3 "
            },
            {
              name:"اريزو 5 "
            },
            {
              name:"اريزو 6"
            },
            {
              name:"تيجو 2"
            },
            {
              name:"تيجو7"
            },
            {
              name:"تيجو 8"
            },
            {
              name:"تيجو 4"
            },
            {
              name:"ميجان GT"
            }
            ,
            {
              name:"ميجان RS"
            }
          ]
        },
        {
          name:"GAC جي اي سي ",
          TypeOfCars:[
            {
              name:"GA3"
            },
            {
              name:"GA4"
            },
            {
              name:"GA8"
            },
            {
              name:"GS3"
            },
            {
              name:" GS4"
            },
            {
              name:"GS8"
            },
            {
              name:"GN6 "
            },
            {
              name:"GN8"
            },
            {
              name:"GS5"
            },
            {
              name:"GA6"
            },
            {
              name:"امباو"
            }
          ]
        },
        {
          name:"GREAT WALL جريت وول",
          TypeOfCars:[
            {
              name:"ونجل 5"
            },
            {
              name:"ونجل 6"
            },
            {
              name:"ونجل 7"
            },
            {
              name:"باور"
            }
          ]
        },
        {
          name:"تاتا",
          TypeOfCars:[
            {
              name:"زينون"
            }
          ]
        },
        {
          name:"جيتور",
          TypeOfCars:[
            {
              name:"X70"
            },
            {
              name:"X70S"
            },
            {
              name:"X90"
            },
            {
              name:"داشينج"
            }
          ]
        },
        {
          name:"ماكسيس",
          TypeOfCars:[
            {
              name:"D90"
            },
            {
              name:"V80 ماكسيس"
            },
            {
              name:"T60 ماكسيس"
            },
            {
              name:"V90"
            },
            {
              name:" T70"
            },
            {
              name:"G50"
            },
            {
              name:"G10 "
            },
            {
              name:"D90 برو "
            },
            {
              name:"D60"
            },
            {
              name:"60 تورنيدو"
            }
          ]
        },
        {
          name:"JAC",
          TypeOfCars:[
            {
              name:"جيمعها متاحة "
            }
          ]
        }
      ]

      const changeMarka = (e)=>{
        setToggle(true)
        setCarMarka(e.target.value)
        setFilter(Cars.find(ctr => ctr.name === e.target.value).TypeOfCars)
      }

      const FilteredCars =  CarMarka==='all' ? post : post.filter(item => item.MarkaVal === CarMarka)
      const FilteredItems = selcetedCategory ==='all' || CarMarka==='all' ? post : post.filter(item => item.TypeOfCarVal === selcetedCategory)
  return (
    <>
      <Helmet>
        <title>  العروض - رؤية لتمويل السيارات</title>
        <meta
      name="description"
      content=" عروض سيارات شركة رؤية لتمويل السيارات للافراد والشركات وللتمويل الشخصي "
      /> 
      <link rel="canonical" href="/Offers"></link>

      </Helmet>
    <div className=' text-black text-3xl font-bold '>
        <div className=' '>
      <Slider/>
        </div>  
        <div className=" p-4">
 <div >
</div>

<div>
<p className='text-right p-2 rounded-lg mx-auto text-white bg-[rgb(47,114,140)] py-2'>: ماركة السيارة</p>
<div className=" py-2">
      <select required value={CarMarka} onChange={changeMarka} className='bg-white text-xl w-full h-[40px] rounded-lg text-right border border-[rgb(47,114,140)]'>
        <option value='all'>جميع السيارات</option>
        {
          Cars.map((data)=>{
            return( 
              <option key={data.name} value={data.name}>{data.name}</option>
            )
          })
        }
      </select>
</div>
      <p className=' text-right p-2 rounded-lg mx-auto text-white bg-[rgb(47,114,140)] py-2'>: نوع السيارة</p>
      <div className=" py-2">
      <select required value={selcetedCategory} onChange={(e)=> {setSelectedCategory(e.target.value) 
        setToggle(false)
      }}  className='bg-white text-xl w-full h-[40px] rounded-lg text-right border border-[rgb(47,114,140)]'>
        <option >الرجاء اختيار نوع السيارة </option>
        {
          Filter.map((data)=>{
            return(
              <option key={data.name} value={data.name}>{data.name}</option>
            )
          })
        }
      </select>
        </div>
</div>
</div>
<div className=" md:grid md:grid-cols-4 gap-x-4 ">
  {
    toggle ? 
    FilteredCars.map((data)=>{
      return( 
        <div className="px-5">
        <br/>
         {loading ? (
          <div className=" flex flex-row">
    <div className="max-w-sm w-full mx-auto shadow-lg rounded-lg overflow-hidden">
          <div className="animate-pulse bg-gray-300 h-64"></div>
          <div className="p-6">
            <div className="bg-gray-300 h-6 w-3/4 mb-4"></div>
            <div className="bg-gray-300 h-4 w-1/2"></div>
          </div>
        </div>
    </div>
          ) : (
            <div  key={data.id} >
      <div  data-aos="fade-up" className=" flex-col p-2 border border-[rgb(47,114,140)] rounded-lg">
    <img className=" rounded-2xl w-fit h-[450px] object-fill " src={data.imgUrl} alt='/'/>
      <div className=" text-end text-xl text-[rgb(47,114,140)]  bg-slate-100 border border-[rgb(47,114,140)] rounded-md py-2 ">
      <p className=" text-center text-[rgb(27,102,111)] RTLL  ">{data.CarDetails}</p>
      </div>
      <div className="py-2">
      <Link to='/IndividualRequest' className="  w-full btn text-white bg-[rgb(47,114,140)] text-xl" >طلب تمويل</Link>
      </div>
        </div>
      <br/>
            </div>
          )}
    
    </div>    )})
    :
FilteredItems.map((data)=>{
return(
  <div className="px-5">
    <br/>
     {loading ? (
      <div className=" flex flex-row">
<div className="max-w-sm w-full mx-auto shadow-lg rounded-lg overflow-hidden">
      <div className="animate-pulse bg-gray-300 h-64"></div>
      <div className="p-6">
        <div className="bg-gray-300 h-6 w-3/4 mb-4"></div>
        <div className="bg-gray-300 h-4 w-1/2"></div>
      </div>
    </div>
</div>
      ) : (
        <div key={data.id} >
  <div  data-aos="fade-up" className=" flex-col p-2 border border-[rgb(47,114,140)] rounded-lg">
<img className=" rounded-2xl w-fit h-[450px] object-fill " src={data.imgUrl} alt='/'/>
  <div className=" text-end text-xl text-[rgb(47,114,140)]  bg-slate-100 border border-[rgb(47,114,140)] rounded-md py-2 ">
  <p className=" text-center text-[rgb(27,102,111)] RTLL  ">{data.CarDetails}</p>
  </div>
  <div className="py-2">
  <Link to='/IndividualRequest' className="  w-full btn text-white bg-[rgb(47,114,140)] text-xl" >طلب تمويل</Link>
  </div>
    </div>
  <br/>
        </div>
      )}

</div>
)}) }
</div>
</div>
</>
  )
}

export default Offers

// import React, { useEffect, useState } from "react";
// import { collection, getDocs } from "firebase/firestore";
// import { txtDB } from "../Config/firebase";
// import { Link } from "react-router-dom";
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';
// import 'swiper/css/autoplay';
// import Slider from "../Component/Slider";
// import { Helmet } from "react-helmet-async";

// function Offers() {
// const [selcetedCategory , setSelectedCategory] =useState('all')

// const [loading, setLoading] = useState(true);

// const [post , setPost] = useState([])

//       const getData = async () =>{
//         const valRef = collection(txtDB,'Offers')
//         const dataDb = await getDocs(valRef)
//         const allData = dataDb.docs.map(val=>({...val.data(),id:val.id}))
//         setPost(allData)
//       }
  
//       useEffect(()=>{
//         getData()
//         setLoading(false);
//       },[])

//       const [CarMarka , setCarMarka ] = useState('')
//       const [Filter , setFilter ] = useState([])
    
//       let Cars = [
//           {
//             name:" تويوتا ",
//             TypeOfCars:[
//               {
//                 name:"كامري"
//               },
//               {
//                 name:"يارس"
//               },
//               {
//                 name:"افالون"
//               },
//               {
//                 name:"كورولا"
//               },
//               {
//                 name:"راف فور"
//               },
//               {
//                 name:"لاندكروز"
//               },
//               {
//                 name:"هايلوكس"
//               },
//               {
//                 name:"هايلاندر"
//               },
//               {
//                 name:"فورتشنر"
//               },
//               {
//                 name:"اينوفا"
//               },
//               {
//                 name:"برادو"
//               },
//               {
//                 name:"شاص"
//               },
//               {
//                 name:"ايكو"
//               },
//               {
//                 name:" باص"
//               },
//               {
//                 name:"اف جي FG"
//               },
//               {
//                 name:" شاص"
//               },
//               {
//                 name:" ربع"
//               },
//               {
//                 name:"افانزا"
//               },
//               {
//                 name:"تندرا"
//               },
//               {
//                 name:"C-HR"
//               },
//               {
//                 name:"بريوس"
//               },
//               {
//                 name:"كرولا كروس"
//               },
//               {
//                 name:"راش"
//               },
//               {
//                 name:"ربع"
//               },
//               {
//                 name:"اريزو"
//               },
//               {
//                 name:"T-80"
//               },
//               {
//                 name:"رايز"
//               },
//               {
//                 name:"اوريون"
//               },
//               {
//                 name:"كرسيدا"
//               },
//               {
//                 name:"انوفا"
//               },
//               {
//                 name:"XA"
//               },
    
//               {
//                 name:"بريفيا"
//               },
//               {
//                 name:"سوبرا"
//               },
//               {
//                 name:"تويوتا86"
//               },
//               {
//                 name:"جرانفيا"
//               },
//               {
//                 name:"فيلوز"
//               },
//               {
//                 name:"كراون"
//               },
//               {
//                 name:"اوربان كروز "
//               }
//             ]
//           },
//           {
//             name:" فورد ",
//             TypeOfCars:[
//               {
//                 name:"كراون فكتوريا"
//               },
//               {
//                 name:"جراند ماركيز"
//               },
//               {
//                 name:"اكسبلورر"
//               },
//               {
//                 name:"توروس"
//               },
//               {
//                 name:"اكسبيدشن"
//               },
//               {
//                 name:"موستنج"
//               },
//               {
//                 name:"ايدج"
//               },
//               {
//                 name:"F150"
//               },
//               {
//                 name:"فيوجن"
//               },
//               {
//                 name:"وندستار"
//               },
//               {
//                 name:"فلكس"
//               },
//               {
//                 name:"فوكس"
//               },
//               {
//                 name:"مونديو"
//               },
//               {
//                 name:" F250"
//               },
//               {
//                 name:"F350"
//               },
//               {
//                 name:" رينجر"
//               },
//               {
//                 name:" اكس كورجن"
//               },
//               {
//                 name:"بيك اب"
//               },
//               {
//                 name:"اسكيب"
//               },
//               {
//                 name:"سبلاش"
//               },
//               {
//                 name:"كوجر"
//               },
//               {
//                 name:"ثندريبرد"
//               },
//               {
//                 name:"F450"
//               },
//               {
//                 name:"F550"
//               },
//               {
//                 name:"إسكورت"
//               },
//               {
//                 name:"ابكوسبورت"
//               },
//               {
//                 name:"فان فورد"
//               },
//               {
//                 name:"فيجو"
//               },
//               {
//                 name:"برونكو"
//               },
//               {
//                 name:"تيريتوري"
//               },
//             ]
//           },
//           {
//             name:" شيفروليه ",
//             TypeOfCars:[
//               {
//                 name:"كابريس"
//               },
//               {
//                 name:"تاهو"
//               },
//               {
//                 name:"سوبربان"
//               },
//               {
//                 name:"لومينا"
//               },
//               {
//                 name:"سلفرادو"
//               },
//               {
//                 name:"كمارو"
//               },
//               {
//                 name:"بليزر"
//               },
//               {
//                 name:"ابيكا"
//               },
//               {
//                 name:"ماليبو"
//               },
//               {
//                 name:"افيو"
//               },
//               {
//                 name:"كروز"
//               },
//               {
//                 name:"اوبترا"
//               },
//               {
//                 name:"تريل بليزر"
//               },
//               {
//                 name:" افلانش"
//               },
//               {
//                 name:"كورفيت"
//               },
//               {
//                 name:" فان"
//               },
//               {
//                 name:"امبالا"
//               },
//               {
//                 name:"ترافيس"
//               },
//               {
//                 name:"ابلاندر"
//               },
//               {
//                 name:"اكسبرس فان"
//               },
//               {
//                 name:"فنشر"
//               },
//               {
//                 name:"كابتيفا"
//               },
//               {
//                 name:"استروفان"
//               },
//               {
//                 name:"سونيك"
//               },
//               {
//                 name:"سبارك"
//               },
//               {
//                 name:"كرفان"
//               },
//               {
//                 name:"كافالير"
//               },
//               {
//                 name:"كولورادو"
//               },
//               {
//                 name:"جي فان"
//               },
//               {
//                 name:"اكوينوكس"
//               },
//               {
//                 name:"بولت"
//               },
//               {
//                 name:"جروف"
//               },
//             ]
//           },
//           {
//             name:" نيسان ",
//             TypeOfCars:[
//               {
//                 name:"باترول"
//               },
//               {
//                 name:"ددسن"
//               },
//               {
//                 name:"التيما"
//               },
//               {
//                 name:"مكسيما"
//               },
//               {
//                 name:"باثفندر"
//               },
//               {
//                 name:"صني"
//               },
//               {
//                 name:"ارمادا"
//               },
//               {
//                 name:"اكستيرا"
//               },
//               {
//                 name:"فئة Z"
//               },
//               {
//                 name:"نيسان شاص"
//               },
//               {
//                 name:"نافارا"
//               },
//               {
//                 name:"مورانو"
//               },
//               {
//                 name:"تيدا"
//               },
//               {
//                 name:" اورفان"
//               },
//               {
//                 name:"سكاي لاين"
//               },
//               {
//                 name:" سنترا"
//               },
//               {
//                 name:"اكس تربل"
//               },
//               {
//                 name:"جلوريا"
//               },
//               {
//                 name:"بريميرا"
//               },
//               {
//                 name:"تيرانو"
//               },
//               {
//                 name:"قاشقاي"
//               },
//               {
//                 name:"جوك"
//               },
//               {
//                 name:"كيكس"
//               },
//               {
//                 name:"370Z"
//               },
//               {
//                 name:"GTR"
//               },
//               {
//                 name:"سيفيليان"
//               },
//               {
//                 name:"باترول سفاري"
//               },
//               {
//                 name:"سيدرك"
//               },
//               {
//                 name:"باترول نيسمو"
//               },
//             ]
//           },
//           {
//             name:"هيونداي",
//             TypeOfCars:[
//               {
//                 name:"سوناتا"
//               },
//               {
//                 name:"النترا"
//               },
//               {
//                 name:"تراجيت"
//               },
//               {
//                 name:"i 40"
//               },
//               {
//                 name:"اكسنت"
//               },
//               {
//                 name:"كوبية"
//               },
//               {
//                 name:"فيراكروز"
//               },
//               {
//                 name:"تيريكان"
//               },
//               {
//                 name:"توسان"
//               },
//               {
//                 name:"جراند سنتافي"
//               },
//               {
//                 name:"ستنافي"
//               },
//               {
//                 name:"ازيرا"
//               },
//               {
//                 name:"جنيسس"
//               },
//               {
//                 name:"سنتنيال"
//               },
//               {
//                 name:"فيلوستر"
//               },
//               {
//                 name:"i-10"
//               },
//               {
//                 name:"كونا"
//               },
//               {
//                 name:"باليسيد"
//               },
//               {
//                 name:"جراند ستنافي"
//               },
//               {
//                 name:"H1"
//               }
//               ,
//               {
//                 name:"ماتريكس"
//               }
//               ,
//               {
//                 name:"جالوير"
//               }
//               ,
//               {
//                 name:"كريتا"
//               }
//               ,
//               {
//                 name:"i 30"
//               }
//               ,
//               {
//                 name:"فينيو"
//               }
//             ]
//           }
//           ,
//           {
//             name:"لكزس",
//             TypeOfCars:[
//               {
//                 name:"LS"
//               },
//               {
//                 name:"LX"
//               },
//               {
//                 name:"ES"
//               },
//               {
//                 name:"GS"
//               },
//               {
//                 name:"IS"
//               },
//               {
//                 name:"RX"
//               },
//               {
//                 name:"GX"
//               },
//               {
//                 name:"SC"
//               },
//               {
//                 name:"NX"
//               },
//               {
//                 name:"LC"
//               },
//               {
//                 name:"RC"
//               },
//               {
//                 name:"RCF"
//               },
//               {
//                 name:"UX"
//               },
//               {
//                 name:"GSF"
//               }
//             ]
//           },
//           {
//             name:" جي ام سي - GMC",
//             TypeOfCars:[
//               {
//                 name:"يوكن"
//               },
//               {
//                 name:"سوبربان"
//               },
//               {
//                 name:"سييرا"
//               },
//               {
//                 name:" بيك أب"
//               },
//               {
//                 name:"انفوي"
//               },
//               {
//                 name:"اكاديا"
//               },
//               {
//                 name:"فان"
//               },
//               {
//                 name:"سافانا"
//               },
//               {
//                 name:"سفاري"
//               },
//               {
//                 name:"تيرين"
//               },
//               {
//                 name:"جيمي"
//               },
//             ]
//           },
//           {
//             name:"مرسيدس",
//             TypeOfCars:[
//               {
//                 name:"S"
//               },
//               {
//                 name:"E"
//               },
//               {
//                 name:"SE"
//               },
//               {
//                 name:"SEL"
//               },
//               {
//                 name:"AMG"
//               },
//               {
//                 name:"مرسيدس G"
//               },
//               {
//                 name:"C"
//               },
//               {
//                 name:"SL"
//               },
//               {
//                 name:"CLS"
//               },
//               {
//                 name:"ML"
//               },
//               {
//                 name:"CL"
//               },
//               {
//                 name:"CLK"
//               },
//               {
//                 name:"SEC"
//               },
//               {
//                 name:"SLK"
//               },
//               {
//                 name:"A الفئة"
//               },
//               {
//                 name:"GLS"
//               },
//               {
//                 name:"GLE"
//               },
//               {
//                 name:"GLC"
//               },
//               {
//                 name:"GLA"
//               },
//               {
//                 name:"CLA"
//               }
//               ,
//               {
//                 name:"V الفئة"
//               }
//               ,
//               {
//                 name:"B"
//               }
//               ,
//               {
//                 name:"GL"
//               }
//               ,
//               {
//                 name:"GLK"
//               }
//               ,
//               {
//                 name:"GT مرسيدس"
//               }
//               ,
//               {
//                 name:"GTS"
//               }
//               ,
//               {
//                 name:"R"
//               }
//               ,
//               {
//                 name:" SLC"
//               }
//               ,
//               {
//                 name:" فان سبرنتر"
//               }
//               ,
//               {
//                 name:"مايباخ"
//               }
//               ,
//               {
//                 name:"GLB"
//               }
//             ]
//           }
//           ,
//           {
//             name:"هوندا",
//             TypeOfCars:[
//               {
//                 name:"اكورد"
//               },
//               {
//                 name:"سيفيك"
//               },
//               {
//                 name:"اوديسي"
//               },
//               {
//                 name:"CRV"
//               },
//               {
//                 name:"بايلوت"
//               },
//               {
//                 name:"سيتي"
//               },
//               {
//                 name:"ليجيند"
//               },
//               {
//                 name:"بريليود"
//               },
//               {
//                 name:"انتجرا"
//               },
//               {
//                 name:"HRV"
//               }
//             ]
//           },
//           {
//             name:"بي ام دبليو - BMW",
//             TypeOfCars:[
//               {
//                 name:"الفئة السابعة"
//               },
//               {
//                 name:"الفئة الخامسة"
//               },
//               {
//                 name:"X الفئة "
//               },
//               {
//                 name:"الفئة الثالثة"
//               },
//               {
//                 name:"الفئة السادسة"
//               },
//               {
//                 name:"الفشة الأولى"
//               },
//               {
//                 name:" M الفئة"
//               },
//               {
//                 name:"ميني كوبر"
//               },
//               {
//                 name:"Z الفئة"
//               },
//               {
//                 name:"i الفئة"
//               },
//               {
//                 name:"الفئة الثامنة"
//               },
//               {
//                 name:"الفئة الثانية"
//               },
//               {
//                 name:"الفئة الرابعة"
//               }
//             ]
//           },
//           {
//             name:"كيا",
//             TypeOfCars:[
//               {
//                 name:"اوبتيما"
//               },
//               {
//                 name:"سيراتو"
//               },
//               {
//                 name:"ريو"
//               },
//               {
//                 name:"كارنيفال"
//               },
//               {
//                 name:"سبورتاج"
//               },
//               {
//                 name:"كادينزا"
//               },
//               {
//                 name:"اوبيروس"
//               },
//               {
//                 name:"سورنتو"
//               },
//               {
//                 name:"كارينز"
//               },
//               {
//                 name:"بيكانتو"
//               },
//               {
//                 name:"موهافي"
//               },
//               {
//                 name:"كوريس"
//               },
//               {
//                 name:"سول"
//               },
//               {
//                 name:"سيفيا"
//               },
//               {
//                 name:"K900"
//               },
//               {
//                 name:"بيجاس"
//               },
//               {
//                 name:"تيلورايد"
//               },
//               {
//                 name:"ستينجر"
//               },
//               {
//                 name:"سيلتوس"
//               },
//               {
//                 name:"نيرو"
//               }
//               ,
//               {
//                 name:"K5"
//               }
//               ,
//               {
//                 name:"سونيت"
//               }
//               ,
//               {
//                 name:"K8"
//               }
//             ]
//           },
//           {
//             name:"دودج",
//             TypeOfCars:[
//               {
//                 name:"تشارجر"
//               },
//               {
//                 name:"تشالنجر"
//               },
//               {
//                 name:"دورانجو"
//               },
//               {
//                 name:"كارافان"
//               },
//               {
//                 name:"رام"
//               },
//               {
//                 name:"نيترو"
//               },
//               {
//                 name:"كاليبر"
//               },
//               {
//                 name:"فايبر"
//               },
//               {
//                 name:"دودج بيك أب"
//               },
//               {
//                 name:"كارافان"
//               },
//               {
//                 name:"انتربيد"
//               }
//             ]
//           },
//           {
//             name:"ميتسوبيشي",
//             TypeOfCars:[
//               {
//                 name:"باجيرو"
//               },
//               {
//                 name:"لانسر"
//               },
//               {
//                 name:"L200"
//               },
//               {
//                 name:"ناتيفا"
//               },
//               {
//                 name:"جالنت"
//               },
//               {
//                 name:"كولت"
//               },
//               {
//                 name:"ماجنا"
//               },
//               {
//                 name:"سيجما"
//               },
//               {
//                 name:"ASX"
//               },
//               {
//                 name:"اتراج"
//               },
//               {
//                 name:" اكليبس كروس"
//               },
//               {
//                 name:"اوتلاند"
//               },
//               {
//                 name:"سبيس ستار"
//               },
//               {
//                 name:"مونتيرو"
//               },
//               {
//                 name:"اكسباندر"
//               },
//               {
//                 name:"جرانديز"
//               }
//             ]
//           },
//           {
//             name:"مازدا",
//             TypeOfCars:[
//               {
//                 name:"مازدا6"
//               },
//               {
//                 name:"CX9"
//               },
//               {
//                 name:"مازدا 3"
//               },
//               {
//                 name:"323"
//               },
//               {
//                 name:"626"
//               },
//               {
//                 name:"CX7"
//               },
//               {
//                 name:"BT50"
//               },
//               {
//                 name:"MPV"
//               },
//               {
//                 name:"CX5"
//               },
//               {
//                 name:"CX2"
//               },
//               {
//                 name:"RX8"
//               },
//               {
//                 name:"MX-5"
//               },
//               {
//                 name:"CX3"
//               },
//               {
//                 name:"مازدا 2"
//               },
//               {
//                 name:"مازدا 5"
//               },
//               {
//                 name:"CX30"
//               }
//             ]
//           },
//           {
//             name:"ايسوزو",
//             TypeOfCars:[
//               {
//                 name:"اسندر"
//               },
//               {
//                 name:"ديماكس"
//               },
//               {
//                 name:"MUX"
//               }
//             ]
//           },
//           {
//             name:"سوزوكي",
//             TypeOfCars:[
//               {
//                 name:"فيتارا"
//               },
//               {
//                 name:"ساموراي"
//               },
//               {
//                 name:"سويفت"
//               },
//               {
//                 name:"جمني"
//               },
//               {
//                 name:"ليانا"
//               },
//               {
//                 name:"SX4"
//               },
//               {
//                 name:"ارتيجا"
//               },
//               {
//                 name:"بالينو"
//               },
//               {
//                 name:"جراند فيتارا"
//               },
//               {
//                 name:"سياز"
//               },
//               {
//                 name:"سيليريو"
//               },
//               {
//                 name:"APV بيك أب"
//               },
//               {
//                 name:"APV فان"
//               },
//               {
//                 name:"ديزاير"
//               },
//               {
//                 name:"كيزاشي"
//               }
//             ]
//           },
//           {
//             name:"جيلي",
//             TypeOfCars:[
//               {
//                 name:"EC7"
//               },
//               {
//                 name:"EC8"
//               },
//               {
//                 name:"باندا"
//               },
//               {
//                 name:"امجراند7"
//               },
//               {
//                 name:"GS امجراند"
//               },
//               {
//                 name:"X7 امجراند"
//               },
//               {
//                 name:"بينراي"
//               },
//               {
//                 name:"كولراي"
//               },
//               {
//                 name:"ازكارا"
//               },
//               {
//                 name:"توجيلا"
//               },
//               {
//                 name:"اوكافانجو"
//               },
//               {
//                 name:"مونجارو"
//               }
//             ]
//           },
//           {
//             name:"بيجو",
//             TypeOfCars:[
//               {
//                 name:"307"
//               },
//               {
//                 name:"407"
//               },
//               {
//                 name:"206"
//               },
//               {
//                 name:"508"
//               },
//               {
//                 name:"406"
//               },
//               {
//                 name:"بارتنر"
//               },
//               {
//                 name:"607"
//               },
//               {
//                 name:"404"
//               },
//               {
//                 name:"3008"
//               },
//               {
//                 name:"301"
//               },
//               {
//                 name:"5008"
//               },
//               {
//                 name:"بوكسر"
//               },
//               {
//                 name:"اكسبيرت"
//               }
//             ]
//           },
//           {
//             name:"MG",
//             TypeOfCars:[
//               {
//                 name:"5"
//               },
//               {
//                 name:"6"
//               },
//               {
//                 name:"HS"
//               },
//               {
//                 name:"MG RX8"
//               },
//               {
//                 name:"RX5"
//               },
//               {
//                 name:"ZS"
//               },
//               {
//                 name:"T60"
//               },
//               {
//                 name:"ام جي جي تي"
//               },
//             ]
//           },
//           {
//             name:"كيا",
//             TypeOfCars:[
//               {
//                 name:"اوبتيما"
//               },
//               {
//                 name:"سيراتو"
//               },
//               {
//                 name:"ريو"
//               },
//               {
//                 name:"كارنيفال"
//               },
//               {
//                 name:"سبورتاج"
//               },
//               {
//                 name:"كادينزا"
//               },
//               {
//                 name:"اوبيروس"
//               },
//               {
//                 name:"سورنتو"
//               },
//               {
//                 name:"كارينز"
//               },
//               {
//                 name:"بيكانتو"
//               },
//               {
//                 name:"موهافي"
//               },
//               {
//                 name:"كوريس"
//               },
//               {
//                 name:"سول"
//               },
//               {
//                 name:"سيفيا"
//               },
//               {
//                 name:"K900"
//               },
//               {
//                 name:"بيجاس"
//               },
//               {
//                 name:"تيلورايد"
//               },
//               {
//                 name:"ستينجر"
//               },
//               {
//                 name:"سيلتوس"
//               },
//               {
//                 name:"نيرو"
//               }
//               ,
//               {
//                 name:"K5"
//               }
//               ,
//               {
//                 name:"سونيت"
//               }
//               ,
//               {
//                 name:"K8"
//               }
//             ]
//           },
//           {
//             name:"شانجان",
//             TypeOfCars:[
//               {
//                 name:"ايدو"
//               },
//               {
//                 name:"CS35"
//               },
//               {
//                 name:"CS75"
//               },
//               {
//                 name:"CS95"
//               },
//               {
//                 name:" V7 شانجان"
//               },
//               {
//                 name:"CS85"
//               },
//               {
//                 name:"السفن "
//               },
//               {
//                 name:"هنتر"
//               },
//               {
//                 name:"CS35 بلس"
//               },
//               {
//                 name:"CS75 بلس"
//               },
//               {
//                 name:"يوني تي"
//               },
//               {
//                 name:"يوني كي"
//               },
//               {
//                 name:"يوني في"
//               }
              
//             ]
//           },
//           {
//             name:"رينو",
//             TypeOfCars:[
//               {
//                 name:"ميغان"
//               },
//               {
//                 name:"فلوانس"
//               },
//               {
//                 name:"سافران"
//               },
//               {
//                 name:"لاغونا"
//               },
//               {
//                 name:" كليو"
//               },
//               {
//                 name:"تاليسمان"
//               },
//               {
//                 name:"داستر "
//               },
//               {
//                 name:"دوكر فان"
//               },
//               {
//                 name:"سيمبول"
//               },
//               {
//                 name:"كابشتر"
//               },
//               {
//                 name:"كوليوس"
//               },
//               {
//                 name:"ماستر"
//               },
//               {
//                 name:"ميجان GT"
//               }
//               ,
//               {
//                 name:"ميجان RS"
//               }
//             ]
//           },
//           {
//             name:"شيري",
//             TypeOfCars:[
//               {
//                 name:"QQ"
//               },
//               {
//                 name:"A5 شيري"
//               },
//               {
//                 name:"ايستار"
//               },
//               {
//                 name:"كوين"
//               },
//               {
//                 name:" A3 شيري "
//               },
//               {
//                 name:"A1 شيري"
//               },
//               {
//                 name:"اريزو 3 "
//               },
//               {
//                 name:"اريزو 6"
//               },
//               {
//                 name:"تيجو 2"
//               },
//               {
//                 name:"تيجو7"
//               },
//               {
//                 name:"تيكو 8"
//               },
//               {
//                 name:"تيكو 4"
//               },
//               {
//                 name:"ميجان GT"
//               }
//               ,
//               {
//                 name:"ميجان RS"
//               }
//             ]
//           },
//           {
//             name:"GAC جي اي سي ",
//             TypeOfCars:[
//               {
//                 name:"GA3"
//               },
//               {
//                 name:"GA4"
//               },
//               {
//                 name:"GA8"
//               },
//               {
//                 name:"GS3"
//               },
//               {
//                 name:" GS4"
//               },
//               {
//                 name:"GS8"
//               },
//               {
//                 name:"GN6 "
//               },
//               {
//                 name:"GN8"
//               },
//               {
//                 name:"GS5"
//               },
//               {
//                 name:"GA6"
//               },
//               {
//                 name:"امباو"
//               }
//             ]
//           },
//           {
//             name:"GREAT WALL جريت وول",
//             TypeOfCars:[
//               {
//                 name:"ونجل 5"
//               },
//               {
//                 name:"ونجل 6"
//               },
//               {
//                 name:"ونجل 7"
//               },
//               {
//                 name:"باور"
//               }
//             ]
//           },
//           {
//             name:"تاتا",
//             TypeOfCars:[
//               {
//                 name:"زينون"
//               }
//             ]
//           },
//           {
//             name:"جيتور",
//             TypeOfCars:[
//               {
//                 name:"X70"
//               },
//               {
//                 name:"X70S"
//               },
//               {
//                 name:"X90"
//               },
//               {
//                 name:"داشينج"
//               }
//             ]
//           },
//           {
//             name:"ماكسيس",
//             TypeOfCars:[
//               {
//                 name:"D90"
//               },
//               {
//                 name:"V80 ماكسيس"
//               },
//               {
//                 name:"T60 ماكسيس"
//               },
//               {
//                 name:"V90"
//               },
//               {
//                 name:" T70"
//               },
//               {
//                 name:"G50"
//               },
//               {
//                 name:"G10 "
//               },
//               {
//                 name:"D90 برو "
//               },
//               {
//                 name:"D60"
//               },
//               {
//                 name:"60 تورنيدو"
//               }
//             ]
//           },
//           {
//             name:"JAC",
//             TypeOfCars:[
//               {
//                 name:"جيمعها متاحة "
//               }
//             ]
//           }
//       ]

//       const changeMarka = (e)=>{
//         setCarMarka(e.target.value)
//         setFilter(Cars.find(ctr => ctr.name === e.target.value).TypeOfCars)
//       }


//       const FilteredItems = selcetedCategory ==='all' || CarMarka==='all' ? post : post.filter(item => item.TypeOfCarVal === selcetedCategory)
//   return (
//     <>
//       <Helmet>
//         <title>  العروض - رؤية لتمويل السيارات</title>
//         <meta
//       name="description"
//       content=" عروض سيارات شركة رؤية لتمويل السيارات للافراد والشركات وللتمويل الشخصي "
//       /> 
//       <link rel="canonical" href="/Offers"></link>

//       </Helmet>
//     <div className=' text-black text-3xl font-bold '>
//         <div className=' '>
//       <Slider/>
//         </div>  
//         <div className=" p-4">
//  <div >
// </div>

// <div>
// <p className='text-right p-2 rounded-lg mx-auto text-white bg-[rgb(47,114,140)] py-2'>: ماركة السيارة</p>
// <div className=" py-2">
//       <select required value={CarMarka} onChange={changeMarka} className='bg-white text-xl w-full h-[40px] rounded-lg text-right border border-[rgb(47,114,140)]'>
//         <option value='all'>جميع السيارات</option>
//         {
//           Cars.map((data)=>{
//             return( 
//               <option key={data.name} value={data.name}>{data.name}</option>
//             )
//           })
//         }
//       </select>
// </div>
//       <p className=' text-right p-2 rounded-lg mx-auto text-white bg-[rgb(47,114,140)] py-2'>: نوع السيارة</p>
//       <div className=" py-2">
//       <select required value={selcetedCategory} onChange={(e)=> {setSelectedCategory(e.target.value)}}  className='bg-white text-xl w-full h-[40px] rounded-lg text-right border border-[rgb(47,114,140)]'>
//         <option >الرجاء اختيار نوع السيارة </option>
//         {
//           Filter.map((data)=>{
//             return(
//               <option key={data.name} value={data.name}>{data.name}</option>
//             )
//           })
//         }
//       </select>
//         </div>
// </div>
// </div>
// <div className=" md:grid md:grid-cols-4 gap-x-4 ">
//   {
    
// FilteredItems.map((data)=>{
// return(
//   <div className="px-5">
//     <br/>
//      {loading ? (
//       <div className=" flex flex-row">
// <div className="max-w-sm w-full mx-auto shadow-lg rounded-lg overflow-hidden">
//       <div className="animate-pulse bg-gray-300 h-64"></div>
//       <div className="p-6">
//         <div className="bg-gray-300 h-6 w-3/4 mb-4"></div>
//         <div className="bg-gray-300 h-4 w-1/2"></div>
//       </div>
//     </div>
// </div>
//       ) : (
//         <div>
//   <div  data-aos="fade-up" className=" flex-col p-2 border border-[rgb(47,114,140)] rounded-lg">
// <img className=" rounded-2xl w-fit h-[450px] object-fill " src={data.imgUrl} alt='/'/>
//   <div className=" text-end text-xl text-[rgb(47,114,140)]  bg-slate-100 border border-[rgb(47,114,140)] rounded-md py-2 ">
//   <p className=" text-center text-[rgb(27,102,111)] RTLL  ">{data.CarDetails}</p>
//   </div>
//   <div className="py-2">
//   <Link to='/IndividualRequest' className="  w-full btn text-white bg-[rgb(47,114,140)] text-xl" >طلب تمويل</Link>
//   </div>
//     </div>
//   <br/>
//         </div>
//       )}

// </div>
// )}) }
// </div>
// </div>
// </>
//   )
// }

// export default Offers