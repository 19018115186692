import {React, useEffect, useState } from "react";
import { collection,addDoc , getDocs } from "firebase/firestore";
import { txtDB } from "../Config/firebase";
import Select from 'react-select'
import Slider from "../Component/Slider";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";


function JobApplication() {
      const [api , setApi] = useState([])
      const [selectJob , setSelectJob]= useState(null)
  
      const [Name,setName] = useState('')
      const [Email,setEmail] = useState('')
      const [Phone,setPhone] = useState('')
      const [textExplain,settextExplain] = useState('')

      const navigate = useNavigate()

      const getData = async () =>{
        const valRef = collection(txtDB,'JobOffers')
        const dataDb = await getDocs(valRef)
        const allData = dataDb.docs.map(val=>({...val.data(),id:val.id}))
        setApi(allData)
      }
      useEffect(()=>{
        getData()
      },[])

      const handleClick = async () =>{
        const valRef = collection(txtDB,'JobsRequests ')
        await addDoc(valRef,{ FirstName:Name, Email:Email  , Phone:Phone , textExplain:textExplain ,selectJob:selectJob })
        alert("تم ارسال طلب توظيفك ،سنتواصل معك في اقرب وقت !")
        navigate('/jobs')
    }

        const JobsVal = Array.from (
          new Set(api.map((res)=>
            res.Title   )))
  

        const JobsOptions = JobsVal.map((Title)=>({
          value: Title , 
          label: Title
        }))


  return (
    <>
          <Helmet>
        <title>  انضم لنا - رؤية لتمويل السيارات</title>
        <meta
      name="description"
      content="طلب تقديم لشركة رؤية لتمويل السيارات "
      /> 
      <link rel="canonical" href="/JobApplication"></link>
      </Helmet>
    <Slider/>
<div data-aos="fade-up" className=' text-black text-3xl font-bold md:pt-[80px]' >
<div className=" p-4">
 <div >
</div>
</div>
<div type='form' className='text-right px-5 py-9 font-bold'>
    <div className="py-4">
        <p className='text-[rgb(47,114,140)]'>! انضم لفريقنا المحترف  </p>
        <p className=' text-3xl text-black '> طلب توظيف لدى شركة رؤية </p>
    </div>

        <div className=' rounded-3xl  bg-white text-right text-black '>
<Select 
className=" pb-5 text-lg "
options={JobsOptions}
isClearable
placeholder="اختر الوظيفة "
onChange={(selectOption)=> setSelectJob(selectOption)}
value={selectJob}
/>
<div className="py-2">
        <input className='bg-white w-full rounded-xl py-4 px-9  text-right border text-lg border-[rgb(47,114,140)] ' placeholder='اسمك'onChange={(e)=>setName(e.target.value)}/>
</div>
<div className="py-2">
        <input className='bg-white w-full rounded-xl py-4 px-9  text-right border text-lg border-[rgb(47,114,140)] ' placeholder='البريد الالكتروني 'onChange={(e)=>setEmail(e.target.value)}/>
</div>
<div className="py-2">
        <input className='bg-white w-full rounded-xl py-4 px-9  text-right border text-lg border-[rgb(47,114,140)] ' placeholder='رقم الجوال 'onChange={(e)=>setPhone(e.target.value)}/>
</div>
<div className="py-2">
        <textarea className='bg-white w-full rounded-xl pb-9 px-9  text-right border border-[rgb(47,114,140)] ' placeholder='المؤهلات' onChange={(e)=>settextExplain(e.target.value)}/>
</div>
        <button onClick={handleClick} className="  w-full btn text-white bg-[rgb(47,114,140)] text-xl" >ارسال الطلب</button>
        </div>
        </div>

</div>
</>
  )
}

export default JobApplication
