import React from 'react'
import { useNavigate } from "react-router-dom";
import head from '../assets/headset-solid.svg'
import at from '../assets/arroba.png'
import req from '../assets/quote-request.png'

const NavbarMenuDesktop = () => {

    const navigate = useNavigate()

  return (
    <div > 
      {/* ----------- Information for contact------------- */}
    <div  className=' hidden lg:flex '>
    <div data-aos="fade-up" className=' rounded-lg top-2  px-14 py-3  absolute z-20 bg-[#e9e0c8]  left-[260px] justify-center '>
    <img className=' mx-auto  ' src={head} width={40} height={40} alt='/'/>
    <h1 className=' text-black font-bold text-center'>اتصل بنا </h1>
    <h1 className=' text-[rgb(47,114,140)] font-bold text-center'>+966 55 713 4101</h1>
    </div>

    <div data-aos="fade-up" className=' rounded-lg top-2 p-2 py-3  absolute z-20 bg-[rgb(47,114,140)] left-[50px] justify-center '>
    <img className=' mx-auto ' src={at} width={40} height={40} alt='/'/>
    <h1 className=' text-white font-bold text-center'>تواصل معنا</h1>
    <h1 className=' text-white font-bold text-center'>royafunding@gmail.com</h1>
    </div>
  </div>

    <div className='flex md:flex md:justify-end pt-5'>
    <div  className='  md:flex md:justify-end hidden md:px-[30px] md:font-bold md:text-right  md:rounded-l-3xl  md:bg-[rgb(47,114,140)]  md:w-[1190px] md:h-[70px] '>

    <div className="dropdown text-white md:top-1 md:right-[260px] md:text-center">
      <p className='font-thin text-sm absolute left-3 '>قدم طلبك الان ؟</p>
      <img src={req} className=' absolute w-12 h-10 top-2 left-[120px]' alt='/'/>
<div tabIndex={0} role="button" className=" bg-[rgb(47,114,140)]  md:hover:bg-amber-600 px-2 py-5 text-white rounded-lg ">طلبات التمويل </div>
<ul tabIndex={0} className="dropdown-content bg-[rgb(47,114,140)]  z-[2] menu p-2 shadow rounded-box w-52 text-center">
  <li><a href='/IndividualRequest'>تمويل افراد</a ></li>
  <li><a href='/CompanyRequest'>تمويل شركات</a></li>
  {/* <li><a href='/PersonalRequest'>تمويل شخصي</a></li> */}
</ul>
</div>

  <button onClick={()=>{ navigate('/signin')}}  className=' md:text-white px-2 py-5 rounded-lg  md:hover:bg-amber-600'> تسجيل الدخول</button>
  {/* <button onClick={()=>{ navigate('/WhyUs')}}  className=' md:text-white px-2 py-5 rounded-lg  md:hover:bg-amber-600'> من نحن ؟</button> */}
  <button onClick={()=>{ navigate('/PrivacyPolicy')}}  className=' md:text-white px-2 py-5 rounded-lg  md:hover:bg-amber-600'> سياسة الخصوصية </button>
  <button onClick={()=>{ navigate('/jobs')}}  className=' md:text-white px-2 py-5 rounded-lg  md:hover:bg-amber-600'> الوظائف </button>
  <button onClick={()=>{ navigate('/contact')}}  className=' md:text-white px-2 py-5 rounded-lg  md:hover:bg-amber-600'> اتصل بنا </button>
  <button onClick={()=>{ navigate('/OurServices')}}  className=' md:text-white px-2 py-5 rounded-lg  md:hover:bg-amber-600'> خدماتنا</button>
  <button onClick={()=>{ navigate('/offers')}}  className=' md:text-white px-2 py-5 rounded-lg  md:hover:bg-amber-600'> العروض</button>
  <button onClick={()=>{ navigate('/about')}}  className=' md:text-white px-2 py-5 rounded-lg  md:hover:bg-amber-600'> عن الشركة</button>
  <button onClick={()=>{ navigate('/')}} className=' md:text-white px-2 py-5 rounded-lg  md:hover:bg-amber-600'> الرئيسية</button>
  


</div>
</div>
</div>
  )
}

export default NavbarMenuDesktop