import React from 'react'
import Logo from '../assets/Logo_Left_Cutted_-removebg-preview.png'
import { useNavigate } from "react-router-dom";
import logoBg from '../assets/Logo_Down_Cutted_-removebg-preview.png'
function NavbarMenu() {

  const navigate = useNavigate()

  return (
    <div className=' flex justify-between ' >
       {/* ---------------------ON DESKTOP---------------------- */}

     
<div >
<div className=" drawer z-10 ">
    <input id="my-drawer" type="checkbox" className="drawer-toggle" />
    <div className="drawer-content px-6 ">
      {/* Page content here */}
  {/* /////// -------------Menu ICON ----------/////// */}
  <div className=' relative mt-9 md:hidden ' >
  <label htmlFor="my-drawer" className="btn my-auto py-[30px] bg-[rgb(47,114,140)] rounded-lg swap swap-rotate drawer-button">
    
    {/* this hidden checkbox controls the state */}
    <input type="checkbox" />
    
    {/* hamburger icon */}
    <svg className="swap-off fill-white " xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512"><path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z"/></svg>
    
    {/* close icon */}
    <svg className="swap-on fill-white" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512"><polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49"/></svg>
  </label>
  </div>
  {/* ///////////////////// */}
      {/* </label> */}
    </div> 
    <div className="drawer-side text-white ">
      <label htmlFor="my-drawer" aria-label="close sidebar" className="drawer-overlay"></label>
      <ul className="text-center w-[260px] pt-1 px-4 max-h-fit space-y-4 text-white  bg-[rgb(47,114,140)]">
        {/* Sidebar content here */}
        <div>
      <img src={logoBg}  className=' bg-slate-700 rounded-lg container pt-4 h-[150px]' alt='/'/>
        </div>
<label onClick={()=>{ navigate('/')}} htmlFor="my-drawer" className="btn align-top bg-inherit text-white border border-white hover:bg-amber-600 rounded-full  w-full py-2 cursor-pointer my-auto  bg-[rgb(47,114,140)]  swap swap-rotate drawer-button">
    <input type="checkbox" />
  <p>الرئيسية</p> 
  </label>
<label onClick={()=>{ navigate('/about')}} htmlFor="my-drawer" className="btn align-top bg-inherit text-white border border-white hover:bg-amber-600 rounded-full  w-full py-2 cursor-pointer my-auto  bg-[rgb(47,114,140)]  swap swap-rotate drawer-button">
    <input type="checkbox" />
  <p>عن الشركة</p> 
  </label>
<label onClick={()=>{ navigate('/Offers')}} htmlFor="my-drawer" className="btn align-top bg-inherit text-white border border-white hover:bg-amber-600 rounded-full  w-full py-2 cursor-pointer my-auto  bg-[rgb(47,114,140)]  swap swap-rotate drawer-button">
    <input type="checkbox" />
  <p> العروض</p> 
  </label>
<label onClick={()=>{ navigate('/OurServices')}} htmlFor="my-drawer" className="btn align-top bg-inherit text-white border border-white hover:bg-amber-600 rounded-full  w-full py-2 cursor-pointer my-auto  bg-[rgb(47,114,140)]  swap swap-rotate drawer-button">
    <input type="checkbox" />
  <p> خدماتنا</p> 
  </label>
<label onClick={()=>{ navigate('/contact')}} htmlFor="my-drawer" className="btn align-top bg-inherit text-white border border-white hover:bg-amber-600 rounded-full  w-full py-2 cursor-pointer my-auto  bg-[rgb(47,114,140)]  swap swap-rotate drawer-button">
    <input type="checkbox" />
  <p> اتصل بنا</p> 
  </label>
<label onClick={()=>{ navigate('/jobs')}} htmlFor="my-drawer" className="btn align-top bg-inherit text-white border border-white hover:bg-amber-600 rounded-full  w-full py-2 cursor-pointer my-auto  bg-[rgb(47,114,140)]  swap swap-rotate drawer-button">
    <input type="checkbox" />
  <p>الوظائف</p> 
  </label>
<label onClick={()=>{ navigate('/signin')}} htmlFor="my-drawer" className="btn align-top bg-inherit text-white border border-white hover:bg-amber-600 rounded-full  w-full py-2 cursor-pointer my-auto  bg-[rgb(47,114,140)]  swap swap-rotate drawer-button">
    <input type="checkbox" />
  <p> تسجيل الدخول</p> 
  </label>
<label onClick={()=>{ navigate('/IndividualRequest')}} htmlFor="my-drawer" className="btn align-top bg-inherit text-white border border-white hover:bg-amber-600 rounded-full  w-full py-2 cursor-pointer my-auto  bg-[rgb(47,114,140)]  swap swap-rotate drawer-button">
    <input type="checkbox" />
  <p> تمويل افراد</p> 
  </label>
<label onClick={()=>{ navigate('/CompanyRequest')}} htmlFor="my-drawer" className="btn align-top bg-inherit text-white border border-white hover:bg-amber-600 rounded-full  w-full py-2 cursor-pointer my-auto  bg-[rgb(47,114,140)]  swap swap-rotate drawer-button">
    <input type="checkbox" />
  <p> تمويل شركات</p> 
  </label>

{/* <label onClick={()=>{ navigate('/PersonalRequest')}} htmlFor="my-drawer" className="btn align-top bg-inherit text-white border border-white hover:bg-amber-600 rounded-full  w-full py-2 cursor-pointer my-auto  bg-[rgb(47,114,140)]  swap swap-rotate drawer-button">
    <input type="checkbox" />
  <p> تمويل شخصي</p> 
  </label> */}
        <button className='bg-[rgb(47,114,140)] pb-[50px] '> 
        </button>
      </ul>
    </div>
  </div>
</div>
    <div >
    <img src={Logo} onClick={()=> navigate('/')} className='w-[200px] mt-[30px] mb-[15px] mr-[15px] cursor-pointer rounded-xl bg-slate-700' alt='/'/>
    </div>
    </div>
  )
}

export default NavbarMenu