import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {  BrowserRouter  } from 'react-router-dom';
import {  HelmetProvider } from 'react-helmet-async';
import { hydrate, render } from "react-dom";

 
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
  <HelmetProvider>
<BrowserRouter>
<React.StrictMode>
  <App />
</React.StrictMode>
</BrowserRouter>
</HelmetProvider>, rootElement);
} else {
  render(
<HelmetProvider>
<BrowserRouter>
<React.StrictMode>
  <App />
</React.StrictMode>
</BrowserRouter>
</HelmetProvider>, rootElement);
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
