import React, {  useState } from "react";
import { imgDB,txtDB } from "../Config/firebase";
import { v4 } from "uuid";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { addDoc, collection } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import { Helmet } from "react-helmet-async";

function CompanyReq () {

  const [CompanyName , setCompanyName]=useState('')
  const [CompanyOwner , setCompanyOwner]=useState('')
  const [Email , setEmail]=useState('')
  const [CompanyField , setCompanyField]=useState('')
  const [CompanyAge , setCompanyAge]=useState('')
  const [City , setCity]=useState('')
  const [NumberOfBranches , setNumberOfBranches]=useState('')
  const [ Bank ,setBank ]=useState('')
  const [ NumberOfCars ,setNumberOfCars ]=useState('')
  const [ CommercialRegistration ,setCommercialRegistration ]=useState('')

  const [FinancialObligations , setFinancialObligations]=useState('')
  const [PhoneNumber , setPhoneNumber]=useState('')

  const [ CommercialRegistrationImg ,setCommercialRegistrationImg ]=useState('')
  const [ NumberOfIbanImg ,setNumberOfIbanImg ]=useState('')
  const [ ExtraNote ,setExtraNote ]=useState('')
  

  const navigate = useNavigate()

// ------صورة السجل التجاري--------
    const handleUpload1 = (e) =>{
        console.log(e.target.files[0])
        const imgs = ref(imgDB,`Imgs/Company/Commercial_Registration_Imgs/${v4()}`)
        uploadBytes(imgs,e.target.files[0]).then(data=>{
  
            getDownloadURL(data.ref).then(val=>{
                setCommercialRegistrationImg(val)
            })
        })
    }
    
// ------صورة الايبان --------
const handleUpload2 = (e) =>{
    console.log(e.target.files[0])
    const imgs = ref(imgDB,`Imgs/Company/Number_Of_Iban_Imgs/${v4()}`)
    uploadBytes(imgs,e.target.files[0]).then(data=>{
        getDownloadURL(data.ref).then(val=>{
          setNumberOfIbanImg(val)
        })
    })
}


  return (
    <>
            <Helmet>
        <title> طلب تمويل سيارات للشركات - رؤية لتمويل السيارات</title>
        <meta
      name="description"
      content=" طلب تمويل سيارات للشركات "
      /> 
        <link rel="canonical" href="/CompanyRequest"></link>

      </Helmet>
    <div data-aos="fade-up" type='form' className='bg-[#f5f5f5] p-4 md:pt-11' >
      <h1 className="text-center text-3xl text-black font-extrabold pb-9"> طلب تمويل الشركات</h1>
      <div className='flex-col text-right text-black text-lg '>
        <h1 className="font-extrabold text-xl text-center text-[rgb(47,114,140)]">نرجو منكم التكرم بتعبئة النموذج التالي وسيتم الرد عليكم خلال مدة اقصاها 24 ساعة</h1>  

        <p className=' font-bold py-2'>: اسم الشركة</p>
      <input required onChange={(e)=>{setCompanyName(e.target.value)}} className=' border border-[rgb(47,114,140)]  bg-white text-right text-black rounded-lg w-full h-[40px] ' />

      <p className=' font-bold py-2'>: اسم مسؤول الشركة</p>
      <input required onChange={(e)=>{setCompanyOwner(e.target.value)}}  className=' border border-[rgb(47,114,140)]  bg-white text-black rounded-lg w-full h-[40px] text-right ' />

      <p className=' font-bold py-2'>: البريد الالكتروني</p>
      <input  required type="email" onChange={(e)=>{setEmail(e.target.value)}} className=' border border-[rgb(47,114,140)]  bg-white text-black rounded-lg w-full h-[40px] text-right ' />


      <p className=' font-bold py-2' >:نشاط الشركة </p>
      <input  required onChange={(e)=>{setCompanyField(e.target.value)}} className=' border border-[rgb(47,114,140)]  bg-white text-black rounded-lg w-full h-[40px] text-right ' />

      <p className=' font-bold py-2' >:عمر الشركة </p>
      <input type="number" onChange={(e)=>{setCompanyAge(e.target.value)}} className=' border border-[rgb(47,114,140)]  bg-white text-black rounded-lg w-full h-[40px] text-right ' />
      
      <p className=' font-bold py-2'>:المدينة</p>
      <select required onChange={(e)=>setCity(e.target.value)}  className='bg-white w-full h-[40px] rounded-lg text-right border-[rgb(47,114,140)]'>
      المدينة
      <option>اختار المدينة</option>
      <option>مكة</option>
      <option> المدينة</option>
      <option>الرياض</option>
      <option>ابها </option>
      <option> الاحساء</option>
      <option> الباحة</option>
      <option> الجبيل</option>
      <option> الجوف</option>
      <option> الدمام</option>
      <option> الطائف</option>
      <option> القصيم</option>
      <option> تبوك</option>
      <option> جدة</option>
      <option> حفر </option>
      <option> حائل </option>
      <option> عرعر </option>
      <option> عسير </option>
      <option> نجران </option>
      <option> ينبع </option>
      <option> عفيف </option>
      <option> القطيف </option>
      <option> الحاير </option>
      <option> الافلاج </option>
      <option> القويعية </option>
      <option> وادي الدواسر </option>
      <option> خميس مشيط</option>
      </select>
      

      <p className=' font-bold py-2'>: عدد الفروع</p>
      <p><input onChange={(e)=>{setNumberOfBranches(e.target.value)}}  className= 'border border-[rgb(47,114,140)] bg-white w-full h-[40px] rounded-lg text-right' type='number'placeholder='عدد الفروع'/> </p>

      <p className=' font-bold py-2'>: عدد السيارات</p>
      <p><input required onChange={(e)=>{setNumberOfCars(e.target.value)}} className=' border border-[rgb(47,114,140)]  bg-white w-full h-[40px] rounded-lg text-right' type='number' placeholder='عدد السيارات '/> </p>

      <p className=' font-bold py-2'>: البنك</p>
      <p>
        <select required onChange={(e)=>{setBank(e.target.value)}} className='bg-white w-full h-[40px] rounded-lg text-right border-[rgb(47,114,140)]'>
        <option>اختار البنك</option>
        <option>بنك الراجحي</option>
        <option> بنك الاهلي</option>
        <option>بنك الرياض </option>
        <option>البنك السعودي الفرنسي</option>
        <option>البنك الاول</option>
        <option>بنك البلاد</option>
        <option>بنك الامارات </option>
        <option>بنك الاستثمار</option>
        <option>بنك الجزيرة</option>
        <option>بنك العربي</option>
        <option>بنك سامبا</option>
        <option>بنك ساب</option>
        <option>بنك الانماء </option>
        <option>كاش</option>
        <option>يستلم بشيك</option>
        <option>تحويل وليس ايداع</option>
      </select>
      </p>
      <br/>

      <p className=' font-bold py-2'>: رقم السجل التجاري</p>
      <p><input required typeof="number" onChange={(e)=>{setCommercialRegistration(e.target.value)}} className=' border border-[rgb(47,114,140)]  bg-white w-full h-[40px] rounded-lg text-right' type='number' placeholder='رقم السجل التجاري '/> </p>

      <p className=' font-bold py-2'>: الالتزامات المالية</p>
      <p><input onChange={(e)=>{setFinancialObligations(e.target.value)}} className=' border border-[rgb(47,114,140)]  bg-white w-full h-[40px] rounded-lg text-right' type='number' placeholder='الاتزامات المالية '/> </p>

      <p className=' font-bold py-2'>: رقم الجوال للتواصل</p>
      <p><input required onChange={(e)=>{setPhoneNumber(e.target.value)}} className=' border border-[rgb(47,114,140)]  bg-white w-full h-[40px] rounded-lg text-right' type='number' placeholder='رقم الجوال للتواصل '/> </p>

      <br/>
      <br/>

      <p className=' font-bold py-2'>:صورة سجل التجاري  </p>
      <input onChange={(e)=>{setCommercialRegistrationImg(e.target.value)}} onInput={handleUpload1} type="file" className=" bg-white"/>
      
      <p className=' font-bold py-2'>:صورة رقم الأيبان  </p>
      <input onChange={(e)=>{setNumberOfIbanImg(e.target.value)}}  onInput={handleUpload2}  type="file" className=" bg-white"/>
      <div className=" md:pb-9">
      <p className=' font-bold py-2 '>:الملاحظات الاضافية  </p>
      <textarea onChange={(e)=>{setExtraNote(e.target.value)}}   className=" bg-white pb-9 rounded-xl w-full border border-black"/>
      </div>

      <div className=" py-4 flex justify-center">
      <button onClick={ ()=>{
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-danger"
            },
            buttonsStyling: false
          });
          swalWithBootstrapButtons.fire({
            title: "هل أنت متأكد من ارسال البيانات ؟",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "نعم",
            cancelButtonText: "لا ",
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
              const handleClick = async () =>{
                const valRef = collection(txtDB,'Company Requests')
                await addDoc(valRef,{ 
                  CompanyNameVal:CompanyName,
                  CompanyOwnerVal:CompanyOwner,
                  EmailVal:Email,
                  CompanyFieldVal:CompanyField,
                  CompanyAgeVal:CompanyAge,
                  CityVal:City,
                  NumberOfBranchesVal:NumberOfBranches,
                  BankVal:Bank,
                  NumberOfCarsVal:NumberOfCars,
                  FinancialObligationsVal:FinancialObligations,
                  PhoneNumberVal:PhoneNumber,
                  CommercialRegistrationImgVal:CommercialRegistrationImg,
                  NumberOfIbanImgVal:NumberOfIbanImg,
                  ExtraNoteVal:ExtraNote,
                  CommercialRegistrationVal:CommercialRegistration,
                  RequestStatus:"جديد"
              })
              navigate('/')
            }
            handleClick()
            swalWithBootstrapButtons.fire({
              title: "تم الارسال سيتم التواصل معك في اقرب وقت ",
              icon: "success"
            });
                          } else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
            ) {
              swalWithBootstrapButtons.fire({
                title: "تم الالغاء",
                icon: "error"
              });
            }
          });
        }}  className=" px-[80px] md:w-[550px] mx-auto btn text-white bg-[rgb(47,114,140)]" > ارسال الطلب</button>
      </div>
      </div>
    </div>
    </>
  )
}

export default CompanyReq 