import { signOut } from "firebase/auth";
import { auth } from "../../Config/firebase";
import { Link, useNavigate } from "react-router-dom";
import talabat from '../../assets/new-employee.png'
import m7twa from '../../assets/pro-1-4.png'
import {React, useState, useEffect } from "react";
import { imgDB, txtDB } from "../../Config/firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import {getDocs, addDoc, collection, doc, deleteDoc } from "firebase/firestore";
import img3 from '../../assets/STATS4.png'
import img4 from '../../assets/pro-1-1.png'
import img5 from '../../assets/About-card-1.png'
import img6 from '../../assets/About-card2.png'
import { v4 } from "uuid";
import check from '../../assets/cross.png'
import Swal from 'sweetalert2'







const ContentMangment = () => {

  const [img,setImg] = useState('')
  const [date,setDate] = useState('')
  const [Details,setDetails] = useState('')

  const navigate = useNavigate();

  const AddOffer = async () =>{
    const valRef = collection(txtDB,'Offers')
    await addDoc(valRef,{imgUrl:img,MarkaVal:CarMarka,TypeOfCarVal:TypeOfCar,dateVal:date,CarDetails:Details})
    alert("تم اضافة هذا المنتج بنجاح الى مجموعتك ")
    navigate('/ContentMangment')
  }

  const logout = async () => {
    await signOut(auth);
    navigate("/");
  };



const handleUpload = (e) =>{
  console.log(e.target.files[0])
  const imgs = ref(imgDB,`Imgs/Offers/${v4()}`)
  uploadBytes(imgs,e.target.files[0]).then(data=>{
      console.log(data,"imgs")
      getDownloadURL(data.ref).then(val=>{
          setImg(val)
      })
  })
}

const [data,setData] = useState([])

const getData = async () =>{
  const valRef = collection(txtDB,'Offers')
  const dataDb = await getDocs(valRef)
  const allData = dataDb.docs.map(val=>({...val.data(),id:val.id}))
  setData(allData)
}


useEffect(()=>{
  getData()
})

// ----------------------- HAS BEEN MOVED TO THE CILCK IT SELF---------------
// const deleteItem = async (id)=> {
//  const userDoc = doc(txtDB , "Offers" , id)
//  await deleteDoc(userDoc)
// }



const [CarMarka , setCarMarka ] = useState('')
const [TypeOfCar , setTypeOfCar ] = useState('')

const [Filter , setFilter ] = useState([])

let Cars = [
  {
    name:" تويوتا ",
    TypeOfCars:[
      {
        name:"كامري"
      },
      {
        name:"يارس"
      },
      {
        name:"افالون"
      },
      {
        name:"كورولا"
      },
      {
        name:"راف فور"
      },
      {
        name:"لاندكروز"
      },
      {
        name:"هايلوكس"
      },
      {
        name:"هايلاندر"
      },
      {
        name:"فورتشنر"
      },
      {
        name:"اينوفا"
      },
      {
        name:"برادو"
      },
      {
        name:"شاص"
      },
      {
        name:"ايكو"
      },
      {
        name:" باص"
      },
      {
        name:"اف جي FG"
      },
      {
        name:" شاص"
      },
      {
        name:" ربع"
      },
      {
        name:"افانزا"
      },
      {
        name:"تندرا"
      },
      {
        name:"C-HR"
      },
      {
        name:"بريوس"
      },
      {
        name:"كرولا كروس"
      },
      {
        name:"راش"
      },
      {
        name:"ربع"
      },
      {
        name:"اريزو"
      },
      {
        name:"T-80"
      },
      {
        name:"رايز"
      },
      {
        name:"اوريون"
      },
      {
        name:"كرسيدا"
      },
      {
        name:"XA"
      },

      {
        name:"بريفيا"
      },
      {
        name:"سوبرا"
      },
      {
        name:"تويوتا86"
      },
      {
        name:"جرانفيا"
      },
      {
        name:"فيلوز"
      },
      {
        name:"كراون"
      },
      {
        name:"اوربان كروز "
      }
    ]
  },
  {
    name:" فورد ",
    TypeOfCars:[
      {
        name:"كراون فكتوريا"
      },
      {
        name:"جراند ماركيز"
      },
      {
        name:"اكسبلورر"
      },
      {
        name:"توروس"
      },
      {
        name:"اكسبيدشن"
      },
      {
        name:"موستنج"
      },
      {
        name:"ايدج"
      },
      {
        name:"F150"
      },
      {
        name:"فيوجن"
      },
      {
        name:"وندستار"
      },
      {
        name:"فلكس"
      },
      {
        name:"فوكس"
      },
      {
        name:"مونديو"
      },
      {
        name:" F250"
      },
      {
        name:"F350"
      },
      {
        name:" رينجر"
      },
      {
        name:" اكس كورجن"
      },
      {
        name:"بيك اب"
      },
      {
        name:"اسكيب"
      },
      {
        name:"سبلاش"
      },
      {
        name:"كوجر"
      },
      {
        name:"ثندريبرد"
      },
      {
        name:"F450"
      },
      {
        name:"F550"
      },
      {
        name:"إسكورت"
      },
      {
        name:"ابكوسبورت"
      },
      {
        name:"فان فورد"
      },
      {
        name:"فيجو"
      },
      {
        name:"برونكو"
      },
      {
        name:"تيريتوري"
      },
    ]
  },
  {
    name:" شيفروليه ",
    TypeOfCars:[
      {
        name:"كابريس"
      },
      {
        name:"تاهو"
      },
      {
        name:"سوبربان"
      },
      {
        name:"لومينا"
      },
      {
        name:"سلفرادو"
      },
      {
        name:"كمارو"
      },
      {
        name:"بليزر"
      },
      {
        name:"ابيكا"
      },
      {
        name:"ماليبو"
      },
      {
        name:"افيو"
      },
      {
        name:"كروز"
      },
      {
        name:"اوبترا"
      },
      {
        name:"تريل بليزر"
      },
      {
        name:" افلانش"
      },
      {
        name:"كورفيت"
      },
      {
        name:" فان"
      },
      {
        name:"امبالا"
      },
      {
        name:"ترافيس"
      },
      {
        name:"ابلاندر"
      },
      {
        name:"اكسبرس فان"
      },
      {
        name:"فنشر"
      },
      {
        name:"كابتيفا"
      },
      {
        name:"استروفان"
      },
      {
        name:"سونيك"
      },
      {
        name:"سبارك"
      },
      {
        name:"كرفان"
      },
      {
        name:"كافالير"
      },
      {
        name:"كولورادو"
      },
      {
        name:"جي فان"
      },
      {
        name:"اكوينوكس"
      },
      {
        name:"بولت"
      },
      {
        name:"جروف"
      },
    ]
  },
  {
    name:" نيسان ",
    TypeOfCars:[
      {
        name:"باترول"
      },
      {
        name:"ددسن"
      },
      {
        name:"التيما"
      },
      {
        name:"مكسيما"
      },
      {
        name:"باثفندر"
      },
      {
        name:"صني"
      },
      {
        name:"ارمادا"
      },
      {
        name:"اكستيرا"
      },
      {
        name:"فئة Z"
      },
      {
        name:"نيسان شاص"
      },
      {
        name:"نافارا"
      },
      {
        name:"مورانو"
      },
      {
        name:"تيدا"
      },
      {
        name:" اورفان"
      },
      {
        name:"سكاي لاين"
      },
      {
        name:" سنترا"
      },
      {
        name:"اكس تربل"
      },
      {
        name:"جلوريا"
      },
      {
        name:"بريميرا"
      },
      {
        name:"تيرانو"
      },
      {
        name:"قاشقاي"
      },
      {
        name:"جوك"
      },
      {
        name:"كيكس"
      },
      {
        name:"370Z"
      },
      {
        name:"GTR"
      },
      {
        name:"سيفيليان"
      },
      {
        name:"باترول سفاري"
      },
      {
        name:"سيدرك"
      },
      {
        name:"باترول نيسمو"
      },
    ]
  },
  {
    name:"هيونداي",
    TypeOfCars:[
      {
        name:"سوناتا"
      },
      {
        name:"النترا"
      },
      {
        name:"تراجيت"
      },
      {
        name:"i 40"
      },
      {
        name:"اكسنت"
      },
      {
        name:"كوبية"
      },
      {
        name:"فيراكروز"
      },
      {
        name:"تيريكان"
      },
      {
        name:"توسان"
      },
      {
        name:"جراند سنتافي"
      },
      {
        name:"ستنافي"
      },
      {
        name:"ازيرا"
      },
      {
        name:"جنيسس"
      },
      {
        name:"سنتنيال"
      },
      {
        name:"فيلوستر"
      },
      {
        name:"i-10"
      },
      {
        name:"كونا"
      },
      {
        name:"باليسيد"
      },
      {
        name:"جراند ستنافي"
      },
      {
        name:"H1"
      }
      ,
      {
        name:"ماتريكس"
      }
      ,
      {
        name:"جالوير"
      }
      ,
      {
        name:"كريتا"
      }
      ,
      {
        name:"i 30"
      }
      ,
      {
        name:"فينيو"
      }
    ]
  }
  ,
  {
    name:"لكزس",
    TypeOfCars:[
      {
        name:"LS"
      },
      {
        name:"LX"
      },
      {
        name:"ES"
      },
      {
        name:"GS"
      },
      {
        name:"IS"
      },
      {
        name:"RX"
      },
      {
        name:"GX"
      },
      {
        name:"SC"
      },
      {
        name:"NX"
      },
      {
        name:"LC"
      },
      {
        name:"RC"
      },
      {
        name:"RCF"
      },
      {
        name:"UX"
      },
      {
        name:"GSF"
      }
    ]
  },
  {
    name:" جي ام سي - GMC",
    TypeOfCars:[
      {
        name:"يوكن"
      },
      {
        name:"سوبربان"
      },
      {
        name:"سييرا"
      },
      {
        name:" بيك أب"
      },
      {
        name:"انفوي"
      },
      {
        name:"اكاديا"
      },
      {
        name:"فان"
      },
      {
        name:"سافانا"
      },
      {
        name:"سفاري"
      },
      {
        name:"تيرين"
      },
      {
        name:"جيمي"
      },
    ]
  },
  {
    name:"مرسيدس",
    TypeOfCars:[
      {
        name:"S"
      },
      {
        name:"E"
      },
      {
        name:"SE"
      },
      {
        name:"SEL"
      },
      {
        name:"AMG"
      },
      {
        name:"مرسيدس G"
      },
      {
        name:"C"
      },
      {
        name:"SL"
      },
      {
        name:"CLS"
      },
      {
        name:"ML"
      },
      {
        name:"CL"
      },
      {
        name:"CLK"
      },
      {
        name:"SEC"
      },
      {
        name:"SLK"
      },
      {
        name:"A الفئة"
      },
      {
        name:"GLS"
      },
      {
        name:"GLE"
      },
      {
        name:"GLC"
      },
      {
        name:"GLA"
      },
      {
        name:"CLA"
      }
      ,
      {
        name:"V الفئة"
      }
      ,
      {
        name:"B"
      }
      ,
      {
        name:"GL"
      }
      ,
      {
        name:"GLK"
      }
      ,
      {
        name:"GT مرسيدس"
      }
      ,
      {
        name:"GTS"
      }
      ,
      {
        name:"R"
      }
      ,
      {
        name:" SLC"
      }
      ,
      {
        name:" فان سبرنتر"
      }
      ,
      {
        name:"مايباخ"
      }
      ,
      {
        name:"GLB"
      }
    ]
  }
  ,
  {
    name:"هوندا",
    TypeOfCars:[
      {
        name:"اكورد"
      },
      {
        name:"سيفيك"
      },
      {
        name:"اوديسي"
      },
      {
        name:"CRV"
      },
      {
        name:"بايلوت"
      },
      {
        name:"سيتي"
      },
      {
        name:"ليجيند"
      },
      {
        name:"بريليود"
      },
      {
        name:"انتجرا"
      },
      {
        name:"HRV"
      }
    ]
  },
  {
    name:"بي ام دبليو - BMW",
    TypeOfCars:[
      {
        name:"الفئة السابعة"
      },
      {
        name:"الفئة الخامسة"
      },
      {
        name:"X الفئة "
      },
      {
        name:"الفئة الثالثة"
      },
      {
        name:"الفئة السادسة"
      },
      {
        name:"الفشة الأولى"
      },
      {
        name:" M الفئة"
      },
      {
        name:"ميني كوبر"
      },
      {
        name:"Z الفئة"
      },
      {
        name:"i الفئة"
      },
      {
        name:"الفئة الثامنة"
      },
      {
        name:"الفئة الثانية"
      },
      {
        name:"الفئة الرابعة"
      }
    ]
  },
  {
    name:"كيا",
    TypeOfCars:[
      {
        name:"اوبتيما"
      },
      {
        name:"سيراتو"
      },
      {
        name:"ريو"
      },
      {
        name:"كارنيفال"
      },
      {
        name:"سبورتاج"
      },
      {
        name:"كادينزا"
      },
      {
        name:"اوبيروس"
      },
      {
        name:"سورنتو"
      },
      {
        name:"كارينز"
      },
      {
        name:"بيكانتو"
      },
      {
        name:"موهافي"
      },
      {
        name:"كوريس"
      },
      {
        name:"سول"
      },
      {
        name:"سيفيا"
      },
      {
        name:"K900"
      },
      {
        name:"بيجاس"
      },
      {
        name:"تيلورايد"
      },
      {
        name:"ستينجر"
      },
      {
        name:"سيلتوس"
      },
      {
        name:"نيرو"
      }
      ,
      {
        name:"K5"
      }
      ,
      {
        name:"سونيت"
      }
      ,
      {
        name:"K8"
      }
    ]
  },
  {
    name:"دودج",
    TypeOfCars:[
      {
        name:"تشارجر"
      },
      {
        name:"تشالنجر"
      },
      {
        name:"دورانجو"
      },
      {
        name:"كارافان"
      },
      {
        name:"رام"
      },
      {
        name:"نيترو"
      },
      {
        name:"كاليبر"
      },
      {
        name:"فايبر"
      },
      {
        name:"دودج بيك أب"
      },
      {
        name:"كارافان"
      },
      {
        name:"انتربيد"
      }
    ]
  },
  {
    name:"ميتسوبيشي",
    TypeOfCars:[
      {
        name:"باجيرو"
      },
      {
        name:"لانسر"
      },
      {
        name:"L200"
      },
      {
        name:"ناتيفا"
      },
      {
        name:"جالنت"
      },
      {
        name:"كولت"
      },
      {
        name:"ماجنا"
      },
      {
        name:"سيجما"
      },
      {
        name:"ASX"
      },
      {
        name:"اتراج"
      },
      {
        name:" اكليبس كروس"
      },
      {
        name:"اوتلاند"
      },
      {
        name:"سبيس ستار"
      },
      {
        name:"مونتيرو"
      },
      {
        name:"اكسباندر"
      },
      {
        name:"جرانديز"
      }
    ]
  },
  {
    name:"مازدا",
    TypeOfCars:[
      {
        name:"مازدا6"
      },
      {
        name:"CX9"
      },
      {
        name:"مازدا 3"
      },
      {
        name:"323"
      },
      {
        name:"626"
      },
      {
        name:"CX7"
      },
      {
        name:"BT50"
      },
      {
        name:"MPV"
      },
      {
        name:"CX5"
      },
      {
        name:"CX2"
      },
      {
        name:"RX8"
      },
      {
        name:"MX-5"
      },
      {
        name:"CX3"
      },
      {
        name:"مازدا 2"
      },
      {
        name:"مازدا 5"
      },
      {
        name:"CX30"
      }
    ]
  },
  {
    name:"ايسوزو",
    TypeOfCars:[
      {
        name:"اسندر"
      },
      {
        name:"ديماكس"
      },
      {
        name:"MUX"
      }
    ]
  },
  {
    name:"سوزوكي",
    TypeOfCars:[
      {
        name:"فيتارا"
      },
      {
        name:"ساموراي"
      },
      {
        name:"سويفت"
      },
      {
        name:"جمني"
      },
      {
        name:"ليانا"
      },
      {
        name:"SX4"
      },
      {
        name:"ارتيجا"
      },
      {
        name:"بالينو"
      },
      {
        name:"جراند فيتارا"
      },
      {
        name:"سياز"
      },
      {
        name:"سيليريو"
      },
      {
        name:"APV بيك أب"
      },
      {
        name:"APV فان"
      },
      {
        name:"APV كاري"
      },
      {
        name:"ديزاير"
      },
      {
        name:"كيزاشي"
      }
    ]
  },
  {
    name:"جيلي",
    TypeOfCars:[
      {
        name:"EC7"
      },
      {
        name:"EC8"
      },
      {
        name:"باندا"
      },
      {
        name:"امجراند7"
      },
      {
        name:"GS امجراند"
      },
      {
        name:"X7 امجراند"
      },
      {
        name:"بينراي"
      },
      {
        name:"كولراي"
      },
      {
        name:"ازكارا"
      },
      {
        name:"توجيلا"
      },
      {
        name:"اوكافانجو "
      },
      {
        name:"مونجارو"
      }
    ]
  },
  {
    name:"بيجو",
    TypeOfCars:[
      {
        name:"307"
      },
      {
        name:"407"
      },
      {
        name:"206"
      },
      {
        name:"508"
      },
      {
        name:"406"
      },
      {
        name:"بارتنر"
      },
      {
        name:"607"
      },
      {
        name:"404"
      },
      {
        name:"3008"
      },
      {
        name:"301"
      },
      {
        name:"5008"
      },
      {
        name:"بوكسر"
      },
      {
        name:"اكسبيرت"
      }
    ]
  },
  {
    name:"بايك",
    TypeOfCars:[
      {
        name:" D50 "
      },
      {
        name:" X7 "
      }
    ]
  },
  {
    name:"فاو",
    TypeOfCars:[
      {
        name:"B70"
      },
      {
        name:"T70"
      }
    ]
  },
  {
    name:"هافال",
    TypeOfCars:[
      {
        name:"جوليان"
      },
      {
        name:"H6"
      }
    ]
  },
  {
    name:"ماكسوس",
    TypeOfCars:[
      {
        name:"T90"
      },
      {
        name:"T60"
      }
    ]
  },
  {
    name:"MG ام جي",
    TypeOfCars:[
      {
        name:"5"
      },
      {
        name:"6"
      },
      {
        name:"HS"
      },
      {
        name:"MG RX8"
      },
      {
        name:"RX5"
      },
      {
        name:"ZS"
      },
      {
        name:"T60"
      },
      {
        name:"ام جي جي تي"
      },
    ]
  },
  {
    name:"كيا",
    TypeOfCars:[
      {
        name:"اوبتيما"
      },
      {
        name:"سيراتو"
      },
      {
        name:"ريو"
      },
      {
        name:"كارنيفال"
      },
      {
        name:"سبورتاج"
      },
      {
        name:"كادينزا"
      },
      {
        name:"اوبيروس"
      },
      {
        name:"سورنتو"
      },
      {
        name:"كارينز"
      },
      {
        name:"بيكانتو"
      },
      {
        name:"موهافي"
      },
      {
        name:"كوريس"
      },
      {
        name:"سول"
      },
      {
        name:"سيفيا"
      },
      {
        name:"K900"
      },
      {
        name:"بيجاس"
      },
      {
        name:"تيلورايد"
      },
      {
        name:"ستينجر"
      },
      {
        name:"سيلتوس"
      },
      {
        name:"نيرو"
      }
      ,
      {
        name:"K5"
      }
      ,
      {
        name:"سونيت"
      }
      ,
      {
        name:"K8"
      }
    ]
  },
  {
    name:"شانجان",
    TypeOfCars:[
      {
        name:"ايدو"
      },
      {
        name:"CS35"
      },
      {
        name:"CS75"
      },
      {
        name:"CS95"
      },
      {
        name:" V7 شانجان"
      },
      {
        name:"CS85"
      },
      {
        name:"السفن "
      },
      {
        name:"هنتر"
      },
      {
        name:"CS35 بلس"
      },
      {
        name:"CS75 بلس"
      },
      {
        name:"يوني تي"
      },
      {
        name:"يوني كي"
      },
      {
        name:"يوني في"
      }
      
    ]
  },
  {
    name:"رينو",
    TypeOfCars:[
      {
        name:"ميجان"
      },
      {
        name:"فلوانس"
      },
      {
        name:"سافران"
      },
      {
        name:"لاغونا"
      },
      {
        name:" كليو"
      },
      {
        name:"تاليسمان"
      },
      {
        name:"داستر "
      },
      {
        name:"دوكر فان"
      },
      {
        name:"سيمبول"
      },
      {
        name:"كابشتر"
      },
      {
        name:"كوليوس"
      },
      {
        name:"ماستر"
      },
      {
        name:"ميجان GT"
      }
      ,
      {
        name:"ميجان RS"
      }
    ]
  },
  {
    name:"شيري",
    TypeOfCars:[
      {
        name:"QQ"
      },
      {
        name:"A5 شيري"
      },
      {
        name:"ايستار"
      },
      {
        name:"كوين"
      },
      {
        name:" A3 شيري "
      },
      {
        name:"A1 شيري"
      },
      {
        name:"اريزو 3 "
      },
      {
        name:"اريزو 5 "
      },
      {
        name:"اريزو 6"
      },
      {
        name:"تيجو 2"
      },
      {
        name:"تيجو7"
      },
      {
        name:"تيجو 8"
      },
      {
        name:"تيجو 4"
      },
      {
        name:"ميجان GT"
      }
      ,
      {
        name:"ميجان RS"
      }
    ]
  },
  {
    name:"GAC جي اي سي ",
    TypeOfCars:[
      {
        name:"GA3"
      },
      {
        name:"GA4"
      },
      {
        name:"GA8"
      },
      {
        name:"GS3"
      },
      {
        name:" GS4"
      },
      {
        name:"GS8"
      },
      {
        name:"GN6 "
      },
      {
        name:"GN8"
      },
      {
        name:"GS5"
      },
      {
        name:"GA6"
      },
      {
        name:"امباو"
      }
    ]
  },
  {
    name:"GREAT WALL جريت وول",
    TypeOfCars:[
      {
        name:"ونجل 5"
      },
      {
        name:"ونجل 6"
      },
      {
        name:"ونجل 7"
      },
      {
        name:"باور"
      }
    ]
  },
  {
    name:"تاتا",
    TypeOfCars:[
      {
        name:"زينون"
      }
    ]
  },
  {
    name:"جيتور",
    TypeOfCars:[
      {
        name:"X70"
      },
      {
        name:"X70S"
      },
      {
        name:"X90"
      },
      {
        name:"داشينج"
      }
    ]
  },
  {
    name:"ماكسيس",
    TypeOfCars:[
      {
        name:"D90"
      },
      {
        name:"V80 ماكسيس"
      },
      {
        name:"T60 ماكسيس"
      },
      {
        name:"V90"
      },
      {
        name:" T70"
      },
      {
        name:"G50"
      },
      {
        name:"G10 "
      },
      {
        name:"D90 برو "
      },
      {
        name:"D60"
      },
      {
        name:"60 تورنيدو"
      }
    ]
  },
  {
    name:"JAC",
    TypeOfCars:[
      {
        name:"جيمعها متاحة "
      }
    ]
  }
]

const changeMarka = (e)=>{
  setCarMarka(e.target.value)
  setFilter(Cars.find(ctr => ctr.name === e.target.value).TypeOfCars)
}
const changeType = (e)=>{
  setTypeOfCar(e.target.value)
}


  return (
    <div className="p-3 md:pt-16">
    <div>
      <span className=" flex justify-between text-center text-2xl text-black font-bold">
        Welcome Admin
        <div className=" justify-start">

        <button
          onClick={logout}
          className="btn bg-[rgb(47,114,140)] text-white rounded-md  "            >
          تسجيل الخروج
        </button>
            </div>
      </span>
    </div>
    {/* -----------------------ContentMangment---------------------- */}
    <div className=" grid grid-cols-2 md:grid md:grid-cols-3 gap-2 pt-9 ">
      <Link to='/ContentMangment' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto"  width={70} height={70} src={m7twa} alt="Content"/>  <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center"> ادارة المحتوى</h1> </Link>
      <Link to='/IndividualRequests' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto" width={70} height={70} src={talabat} alt="Individual"/> <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center"> طلبات تمويل للافراد </h1> </Link>
      <Link to='/CompanyRequests' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto"  width={70} height={70} src={img3} alt="Company"/>  <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center"> طلبات تمويل للشركات</h1> </Link>
      <Link to='/SliderController' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto" width={70} height={70} src={img4} alt="Personal"/> <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center">التحكم في السلايدز</h1> </Link>
      {/* <Link to='/PersonalRequests' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto" width={70} height={70} src={img4} alt="Personal"/> <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center">طلبات تمويل شخصي </h1> </Link> */}
      <Link to='/ComplaintsRequests' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto" width={70} height={70} src={img5} alt="Complaints"/> <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center">الشكاوي </h1> </Link>
      <Link to='/JobWantedRequests' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto" width={70} height={70} src={img6} alt="JobWanted"/> <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center">طلبات التوظيف </h1> </Link>
    </div>
    {/* ---------------------------ادارة المحتوى --------------------------- */}
    <div className='py-6 mx-auto text-black md:text-right md:grid md:grid-cols-4'>
    <input type="file" onChange={(e)=>handleUpload(e)} className="bg-white file-input file-input-bordered file-input-accent w-full max-w-xs" />
    <p className="text-right text-lg font-bold text-[rgb(47,114,140)] pt-3">
    <select required value={CarMarka} onChange={changeMarka} className='bg-white w-full border h-[40px] rounded-lg text-right border-[rgb(47,114,140)]'>
        <option>ماركة السيارة</option>
        {
          Cars.map((data)=>{
            return(
              <option key={data.id} value={data.name}>{data.name}</option>
            )
          })
        }
      </select>
</p>
<p className="text-right text-lg font-bold text-[rgb(47,114,140)] pt-3">
      <select required value={TypeOfCar} onChange={changeType}  className='bg-white w-full border h-[40px] rounded-lg text-right border-[rgb(47,114,140)]'>
        <option>نوع السيارة</option>
        {
          Filter.map((data)=>{
            return( 
              <option key={data.id} value={data.name}>{data.name}</option>
            )
          })
        }
      </select><br/></p>
      <div className=" py-3">
    <p className="text-right text-lg font-bold"><input  className="bg-white w-full border h-[40px] rounded-lg text-right border-[rgb(47,114,140)] text-[rgb(47,114,140)] " placeholder="موديل السيارة" onChange={(e)=>setDate(e.target.value)} /><br/></p>
        </div>
        <p><input  className="bg-white RTLL text-lg font-bold w-full border h-[40px] rounded-lg  border-[rgb(47,114,140)] text-[rgb(47,114,140)] "
     placeholder="وصف السيارة" onChange={(e)=>setDetails(e.target.value)} /></p>
    <div className="py-5 text-center">
    <button onClick={()=>{AddOffer()}} className=" py-2 px-9 mx-auto btn text-white bg-[rgb(47,114,140)]" > اضافة عرض للقائمة</button>
    </div>
    </div>
    <p className=' text-2xl text-black text-right font-bold p-4'>عروض السيارات</p>
      <div className=' py-6 text-black'>
      <div>
      <div className="overflow-x-auto">
        <table className=" table">
    <thead className="bg-black text-white ">
      <tr>
        <td> ماركة السيارة</td>
        <td>نوع السيارة</td> 
        <td>سنة الصنع</td> 
        <td>صورة السيارة</td>  
        <td>وصف السيارة</td>  
        <td> حذف العنصر؟</td>  
        <th></th> 
      </tr>
    </thead> 
    <tbody>
        <th ></th> 
        {
          data.map((data)=>{
            return(
              <>
              <tr>
        <td className=" border border-black border-solid" key={data.id}>{data.MarkaVal}</td>
        <td className=" border border-black border-solid">{data.TypeOfCarVal}</td> 
        <td className=" border border-black border-solid">{data.dateVal}</td> 
        <td className=" border border-black border-solid"><a href={data.imgUrl}> صورة</a></td> 
        <td className=" border border-black border-solid RTLL">{data.CarDetails}</td> 
        <td className=" border border-black border-solid"><button className=" btn bg-red-200 border border-black rounded-lg p-2 text-white " 
        onClick={ ()=>{
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-danger"
            },
            buttonsStyling: true
          });
          swalWithBootstrapButtons.fire({
            title: "هل أنت متأكد من حذف العنصر ؟",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "نعم",
            cancelButtonText: "الغاء",
            reverseButtons: false,
          }).then((result) => {
            if (result.isConfirmed) {
              async function deleteItem (id){
                const userDoc = doc(txtDB , "Offers" , id)
                await deleteDoc(userDoc)
              }
              deleteItem(data.id)
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire({
              title: "تم الالغاء",
              icon: "error"
            });
          }
          });
        }}> <img className="w-6 h-6" src={check} alt='حذف'/></button></td> 
    </tr>
              </>
        )
      })
    }
    </tbody> 
  </table>
</div>
      </div>

      </div>
  </div>
  )
}

export default ContentMangment
