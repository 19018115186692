import React from 'react'
import CARD1 from '../assets/About-card-1.png'
import CARD2 from '../assets/About-card2.png'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

function OurServices() {
  const navigate = useNavigate()

  return (
    <>
          <Helmet>
        <title> خدماتنا - رؤية لتمويل السيارات</title>
        <meta
      name="description"
      content=" الخدمات المتوفرة لدينا نحن شركة رؤية لتمويل السيارات للافراد والشركات وللتمويل الشخصي "
      /> 
      <link rel="canonical" href="/OurServices"></link>

      </Helmet>
      <div data-aos="fade-up" className=' pb-6'>
        <div className='  bg-white text-right md:px-7 px-0   '>
                    {/* تقسيط سيارات  للافراد فقط  */}
        {/* -------------------خدماتنا-------------- */}
        <div className='text-2xl text-slate-900 leading-9' >
          <p className=' text-center text-5xl font-bold pb-9 py-5 md:py-9'> خدماتنا</p>
          <p className=' px-9 pb-7 md:px-0'>

          نحن نقدم باقة متنوعة من الخدمات المصممة خصيًصا لتلبية احتياجاتك التمويلية:
نقدم لك استشارات مالية متخصصة تساعدك في اتخاذ قرارات مستنيرة.
مقارنة عروض التمويل: نساعدك في مقارنة وتحليل مختلف عروض التمويل المتاحة لتختار الأنسب.
تسهيل إجراءات التمويل: نتولى عنك كافة الإجراءات الورقية والادارية لتسريع عملية التمويل.
دعم مستمر بعد التمويل: نبقى معك حتى بعد استلام السيارة لضمان رضاك التام عن الخدمة
          </p>
        </div>
        
        <div className=' bg-[url(https://tasweqy.sa/new/assets/img/bg/process-bg-1.jpg)] bg-slate-300 py-[100px] text-center flex flex-col  font-bold rounded-3xl md:flex md:flex-row md:justify-around '>
      {/* Card-1 */}
      <div data-aos="fade-up" className=' self-center  py-6'>
        <img className='py-3 mx-auto pb-9' src={CARD1} alt=' تمويل للافراد ' />
        <p className=' text-blue-900 pb-4 text-xl'>تمويل سيارات للأفراد </p>
        <button onClick={()=>navigate('/IndividualRequest')} className=' px-4 py-2 rounded-2xl text-white bg-[#2f728c]'> تقدم بطلبك </button>
      </div>
      {/* Card-2 */}
      <div data-aos="fade-up" className='self-center py-6'>
        <img className='py-3 mx-auto pb-9' src={CARD2} alt='تمويل للشركات '/>
        <p className=' text-blue-900 pb-4 text-xl'>تمويل سيارات للشركات </p>
        <button onClick={()=>navigate('/CompanyRequest')} className=' px-4 py-2 rounded-2xl text-white bg- bg-[#2f728c]'> تقدم بطلبك </button>
      </div>

      {/* Card-3 */}
      {/* <div data-aos="fade-up" className='self-center py-6'>
        <img className='py-3 mx-auto pb-9' src={CARD4} alt='تمويل شخصي '/>
        <p className=' text-blue-900 pb-4 text-xl'>تمويل شخصي </p>
        <button onClick={()=>navigate('/PersonalRequest')} className=' px-4 py-2 rounded-2xl text-white bg-[#2f728c]'> تقدم بطلبك </button>
      </div> */}
    </div>
        </div>
        </div>
    </>
 )
}

export default OurServices