import {React, useState, useEffect } from "react";
import {getDocs,  collection } from "firebase/firestore";
import { txtDB } from ".././Config/firebase";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y , Autoplay  } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

function Slider() {
      const [data,setData] = useState([])
      const [loading, setLoading] = useState(true);


      const getData = async () =>{
        const valRef = collection(txtDB,'Slides')
        const dataDb = await getDocs(valRef)
        const allData = dataDb.docs.map(val=>({...val.data(),id:val.id}))
        setData(allData)
      }
      
      
      useEffect(()=>{
          getData()
        setLoading(false);
      }, [])
      
  return (
        <div className=' md:py-8 md:h-fit' >
<Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay ]}
      spaceBetween={50}
      slidesPerView={1}
      autoplay={{delay:2000}}
      scrollbar={{ draggable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      >
      {/* ------------------API MN EL M3RD BTA3 EL SOR--------- */}
      {
        data.map((data)=>{
          return(
            <div>
        {loading ? (
        <div class="loader mx-auto my-36"></div> 
      ) : (
        <>
        <SwiperSlide > 
        <img src={data.SlidesImg} className="p-2 w-fit h-fit md:w-full md:h-fit" alt=""/>
        </SwiperSlide>
        </>
      )}
            </div>
          )
        })
      }
    </Swiper>
        </div>
  )
}

export default Slider
