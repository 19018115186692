import { signOut } from "firebase/auth";
import { auth } from "../../Config/firebase";
import { Link, useNavigate } from "react-router-dom";
import talabat from '../../assets/new-employee.png'
import m7twa from '../../assets/pro-1-4.png'
import {React, useState, useEffect } from "react";
import { imgDB, txtDB } from "../../Config/firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import {getDocs, addDoc, collection, doc, deleteDoc } from "firebase/firestore";
import img3 from '../../assets/STATS4.png'
import img4 from '../../assets/pro-1-1.png'
import img5 from '../../assets/About-card-1.png'
import img6 from '../../assets/About-card2.png'
import { v4 } from "uuid";
import check from '../../assets/cross.png'
import Swal from 'sweetalert2'

const SliderController = () => {

  const [SlidesImg,setSlidesImg] = useState('')
  const navigate = useNavigate();

  const AddSlides = async () =>{
    const valRef = collection(txtDB,'Slides')
    await addDoc(valRef,{SlidesImg:SlidesImg})
    alert("تم اضافة هذا السلايد بنجاح الى مجموعتك ")
    navigate('/SliderController')
  }

  const logout = async () => {
    await signOut(auth);
    navigate("/");
  };


const handleUploadSliders = (e) =>{
  const imgs = ref(imgDB,`Imgs/Sliders/${v4()}`)
  uploadBytes(imgs,e.target.files[0]).then(data=>{
      getDownloadURL(data.ref).then(val=>{
          setSlidesImg(val)
      })
  })
}

const [Slidersdata,setSlidersData] = useState([])


const getDataSliders = async () =>{
  const valRef = collection(txtDB,'Slides')
  const dataDb = await getDocs(valRef)
  const allData = dataDb.docs.map(val=>({...val.data(),id:val.id}))
  setSlidersData(allData)
}

useEffect(()=>{
  getDataSliders()
})


  return (
    <div className="p-3 md:pt-16">
    <div>
      <span className=" flex justify-between text-center text-2xl text-black font-bold">
        Welcome Admin
        <div className=" justify-start">

        <button
          onClick={logout}
          className="btn bg-[rgb(47,114,140)] text-white rounded-md  "            >
          تسجيل الخروج
        </button>
            </div>
      </span>
    </div>
    {/* -----------------------ContentMangment---------------------- */}
    <div className=" grid grid-cols-2 md:grid md:grid-cols-3 gap-2 pt-9 ">
    <Link to='/ContentMangment' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto"  width={70} height={70} src={m7twa} alt="Content"/>  <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center"> ادارة المحتوى</h1> </Link>
        <Link to='/IndividualRequests' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto" width={70} height={70} src={talabat} alt="Individual"/> <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center"> طلبات تمويل للافراد </h1> </Link>
        <Link to='/CompanyRequests' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto"  width={70} height={70} src={img3} alt="Company"/>  <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center"> طلبات تمويل للشركات</h1> </Link>
        <Link to='/SliderController' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto" width={70} height={70} src={img4} alt="Personal"/> <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center">التحكم في السلايدز</h1> </Link>
        {/* <Link to='/PersonalRequests' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto" width={70} height={70} src={img4} alt="Personal"/> <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center">طلبات تمويل شخصي </h1> </Link> */}
        <Link to='/ComplaintsRequests' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto" width={70} height={70} src={img5} alt="Complaints"/> <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center">الشكاوي </h1> </Link>
        <Link to='/JobWantedRequests' className="border border-[rgb(47,114,140)] py-3  rounded-2xl"><img className=" mx-auto" width={70} height={70} src={img6} alt="JobWanted"/> <h1 className="text-[rgb(47,114,140)] font-bold text-xl text-center">طلبات التوظيف </h1> </Link>
    </div>
      {/* -----------------------------------------ادارة السلايدز----------------------------- */}
      <h1 className=" text-4xl text-black rounded-lg font-extrabold text-center border border-box shadow-lg py-4 border-black my-4 ">ادارة السلايدز</h1>
      <div>
        <div>
        <div className=" text-center">
      <input type="file" onChange={(e)=>handleUploadSliders(e)} className="bg-white text-center w-full md:w-[800px] file-input file-input-bordered file-input-accent max-w-xs" />
    <p className="text-right text-lg font-bold"><br/></p>
      </div>
    <div className="pb-5 text-center ">
    <button onClick={AddSlides} className=" text-xl font-extrabold w-full md:w-[800px] py-2 px-9 mx-auto btn text-white bg-[rgb(47,114,140)]" > اضافة سلايد</button>
    </div>
        </div>
      <div className="overflow-x-auto">
        <table className=" table">
    <thead className="bg-black text-white ">
      <tr className="text-center text-xl">
        <td>الصورة</td>
        <td>حذف العنصر</td>
      </tr>
    </thead> 
    <tbody>
        <th ></th> 
        {
          Slidersdata.map((data)=>{
            return(
              <>
              <tr>
        <td className=" border border-black border-solid"  key={data.id}>
          <img src={data.SlidesImg} alt="صورة سلايدز"/>
          </td> 
        <td className=" border border-black border-solid text-center"><button className=" btn bg-red-200 border border-black rounded-lg p-2 text-white " 
        onClick={ ()=>{
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-danger"
            },
            buttonsStyling: true
          });
          swalWithBootstrapButtons.fire({
            title: "هل أنت متأكد من حذف العنصر ؟",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "نعم",
            cancelButtonText: "الغاء",
            reverseButtons: false,
          }).then((result) => {
            if (result.isConfirmed) {
              async function deleteItem (id){
                const userDoc = doc(txtDB , "Slides" , id)
                await deleteDoc(userDoc)
              }
              deleteItem(data.id)
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire({
              title: "تم الالغاء",
              icon: "error"
            });
          }
          });
        }}> <img className="w-6 h-6" src={check}  alt='حذف'/></button></td> 
    </tr>
              </>
        )
      })
    }
    </tbody> 
  </table>
</div>
      </div>
      </div>
  )
}

export default SliderController