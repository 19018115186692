import React from 'react'
import { Navigation, Pagination, Scrollbar, A11y , Autoplay  } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import AboutImg from '../assets/About.jpeg' 
import CARD1 from '../assets/About-card-1.png'
import CARD2 from '../assets/About-card2.png'
import CARD4 from '../assets/About-Card-4.png'
import stats1 from '../assets/STATS-1.png'
import stats2 from '../assets/STATS2.png'
import stats3 from '../assets/STATS3.png'
import stats4 from '../assets/STATS4.png'
import heroAbout from '../assets/firefox.jpg'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';



function About() {
  const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <title>عن الشركة - رؤية لتمويل السيارات</title>
        <meta
      name="description"
      content="اعرف المزيد عنا في شركة رؤية لتمويل السيارات للشركات والافراد والشركات "
      /> 
            <link rel="canonical" href="/About"></link>
      </Helmet>
      <div  className='pt-9 flex flex-col w-[100%] h-fit'>
      {/* IMG-Section */}
      <div className=' flex justify-center items-center object-fill'>
      <img  className=' md:relative md:flex md:flex-row md:justify-start md:container md:w-[100%] md:max-h-[250px] ' src={AboutImg} alt=' Contact With us '/>
      </div>
        
        <div className='md:grid md:grid-cols-2'>
        <div data-aos="fade-up" className=' md:p-14 '>
      <img src={heroAbout} alt='/ تمويل سيارات' className=' invisible absolute md:visible md:static rounded-2xl'/>
    </div>
    <div data-aos="fade-up" className='Main text-right px-4 text-slate-500 font-sans p-14' >
    <h1 className=' text-[#2f728c] py-5 font-bold md:text-3xl '> رؤية تقدم لكم جميع التسهيلات وخدمات التمويل </h1>

    <div  data-aos="fade-up"className=' py-4'>
    <Swiper
        // install Swiper modules
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay ]}
      spaceBetween={50}
      slidesPerView={1}
      autoplay={{delay:1500}}
      scrollbar={{ draggable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      >
      <SwiperSlide>
              {/* -----------اللوحة الأولى ------------- */}
              <div className='py-6'>
              <h1 className=' text-orange-600 font-bold text-xl'> عن الشركة </h1>
      <p >  يعتبر موقع رؤية للتمويل  من المواقع الرائده في مجال تمويل السارات -العقاري،
        حيث تضع شريكها محور اولوياتها ،وتركو بدقة على تقديم افضل ا
        لخدمات المتطورة والحلول التمويلية الإسلامية المتنوعة المبنية على
        أسس ومعايير تلبي احتياجات وميزانيات شركائنا .  </p>
              </div>
</SwiperSlide>
      <SwiperSlide>   
      {/* -----------اللوحة الثانية ------------- */}
        <h1 className=' text-orange-600 font-bold text-xl'> رؤيتنا  </h1>
      <p >  نحن ملتزمون بتقديم اسهل طريقة لعملائنا،للحصول على تمويل السيارات -العقاري
        من خلال توفير حلول المويل المبتكرة من خلال متابعة
        عروض السوق والبنوك والشركات وجهات التمويل الاخرى   </p>
</SwiperSlide>
      <SwiperSlide>   
                 {/* ------------اللوحة الثالثة------------ */}
        <h1 className=' text-orange-600 font-bold text-xl'> الرسالة   </h1>
      <p> نقوم بجعمل شركائنا أقرب إلينا ،
        من خلال ابتكار حلول ، تجعل من تمويل السيارات -العقاري تجربة سلسة</p>
</SwiperSlide>
    </Swiper>
    </div>
    </div>

    </div>
{/* -------------------------------------Cards----------------------------- */}
    
    <div className=' bg-[url(https://tasweqy.sa/new/assets/img/bg/process-bg-1.jpg)] bg-slate-300 py-[100px] text-center flex flex-col  font-bold rounded-3xl md:flex md:flex-row md:justify-around'>
      {/* Card-1 */}
      <div data-aos="fade-up" className=' self-center  py-6'>
        <img className='py-3 mx-auto pb-9' src={CARD1} alt=' تمويل للافراد ' />
        <p className=' text-blue-900 pb-4 text-xl'>تمويل سيارات للأفراد </p>
        <button onClick={()=>navigate('/IndividualRequest')} className=' px-4 py-2 rounded-2xl text-white bg-[#2f728c]'> تقدم بطلبك </button>
      </div>
      {/* Card-2 */}
      <div data-aos="fade-up" className='self-center py-6'>
        <img className='py-3 mx-auto pb-9' src={CARD2} alt='تمويل للشركات '/>
        <p className=' text-blue-900 pb-4 text-xl'>تمويل سيارات للشركات </p>
        <button onClick={()=>navigate('/CompanyRequest')} className=' px-4 py-2 rounded-2xl text-white bg- bg-[#2f728c]'> تقدم بطلبك </button>
      </div>

      {/* Card-3 */}
      {/* <div data-aos="fade-up" className='self-center py-6'>
        <img className='py-3 mx-auto pb-9' src={CARD4} alt='تمويل شخصي '/>
        <p className=' text-blue-900 pb-4 text-xl'>تمويل شخصي </p>
        <button onClick={()=>navigate('/PersonalRequest')} className=' px-4 py-2 rounded-2xl text-white bg-[#2f728c]'> تقدم بطلبك </button>
      </div> */}
    </div>
    {/* ---------------------------------------------------About-Stats--------------------------------- */}
    <div className='bg-white flex flex-col pl-4 md:grid md:grid-cols-3 py-9 '>
      {/* Stats-1 */}
      <div className=' py-8'>

<div class="bg-white group pt-[50px] h-96 w-80  border shadow-2xl border-slate-700 rounded-3xl ">
<div class="bg-white relative  rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
<div data-aos="fade-up" data-aos-duration="1500" class="bg-white absolute inset-0 align-middle text-center">
<img class="rounded-xl mx-auto w-50 " src={stats1} alt=" الريادة" />
<h1 className='text-black text-2xl font-black pt-9'>الريادة  </h1>
<p className='text-slat-600 p-7'> نأخذ زمام المبادرة في تطوير قطاع تأجير السيارات ، بمواصلة
ريادتنا لهذا القطاغ 
لتحسين الخدمات المقدمة لشركائنا   </p>
</div>
<div class="absolute bg-white text-black inset-0 h-full w-full  rounded-xl bg-black/80 px-12 text-center  [transform:rotateY(180deg)] [backface-visibility:hidden]">


</div>
</div>
</div>
</div>
      {/* Stats-2 */}
      <div className=' py-8'>
<div class="group pt-[50px] h-96 w-80 bg-white border shadow-2xl border-slate-700 rounded-3xl ">
<div class="relative bg-[#020726] rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
<div data-aos="fade-up" data-aos-duration="1700" class="absolute inset-0 align-middle text-center">
<img class="rounded-xl mx-auto w-50 " src={stats2} alt="الابتكار " />
<h1 className='text-black text-2xl font-black pt-9'>الابتكار  </h1>
<p className='text-slat-600 p-7'> دمج أفكار فرق العمل لدينا معاً كفريق واحد، للتوصل إلى أكثر الحلول إبداعاً وابتكاراً، ملبّين فيها جميع احتياجات شركائنا </p>
</div>
<div class="absolute text-black inset-0 h-full w-full rounded-xl bg-black/80 px-12 text-center  [transform:rotateY(180deg)] [backface-visibility:hidden]">

</div>
</div>
</div>
      </div>
        {/* Stats-3 */}
      <div className=' py-8'>
<div class="group pt-[50px] h-96 w-80 bg-white border shadow-2xl border-slate-700 rounded-3xl ">
<div data-aos="fade-up" data-aos-duration="1900" class="relative bg-[#020726] rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
<div class="absolute inset-0 align-middle text-center">
<img class="rounded-xl mx-auto w-50 " src={stats3} alt="الرعاية" />
<h1 className='text-black text-2xl font-black pt-9'>الرعاية  </h1>
<p className='text-slat-600 p-7'> دمج أفكار فرق العمل لدينا معاً كفريق واحد، للتوصل إلى أكثر الحلول إبداعاً وابتكاراً، ملبّين فيها جميع احتياجات شركائنا </p>
</div>
<div class="absolute text-black inset-0 h-full w-full rounded-xl bg-black/80 px-12 text-center  [transform:rotateY(180deg)] [backface-visibility:hidden]">

</div>
</div>
</div>
      </div>
      {/* Stats-4 */}
      <div className=' py-8'>
<div class="group pt-[50px] h-96 w-80 bg-white border shadow-2xl border-slate-700 rounded-3xl ">
<div data-aos="fade-up" data-aos-duration="2000" class="relative bg-[#020726] rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
<div class="absolute inset-0 align-middle text-center">
<img class="rounded-xl mx-auto w-50 " src={stats4} alt="الاستشارات" />
<h1 className='text-black text-2xl font-black pt-9'>الاستشارات  </h1>
<p className='text-slat-600 p-7'> دمج أفكار فرق العمل لدينا معاً كفريق واحد، للتوصل إلى أكثر الحلول إبداعاً وابتكاراً، ملبّين فيها جميع احتياجات شركائنا </p>
</div>
<div class="absolute text-black inset-0 h-full w-full rounded-xl bg-black/80 px-12 text-center  [transform:rotateY(180deg)] [backface-visibility:hidden]">

</div>
</div>
</div>
      </div>
    </div>
  </div>
  </>
  )
}

export default About