import checked from '../assets/check.png' 
import { Navigation,  Scrollbar, A11y , Autoplay  } from 'swiper/modules';
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import HeroImg from '../assets/Hero.jpg'
import slideImg1 from '../assets/slide1.jpg'
import slideImg2 from '../assets/slide2.png'
import slideImg3 from '../assets/slide3.jpg'
import slideImg4 from '../assets/Slide4.png'
import slideImg5 from '../assets/Slide5.png'
import firstImage from '../assets/pro-1-1.png'
import SecondImage from '../assets/pro-1-2.png'
import thirdImage from '../assets/pro-1-3.png'
import fourthImage from '../assets/pro-1-4.png'
import list from '../assets/list-check.svg'
import Coop1 from '../assets/Cooapration/abdelatef.jpg';
import Coop2 from '../assets/Cooapration/ahle.jpg'
import Coop3 from '../assets/Cooapration/Alinma.png'
import Coop4 from '../assets/Cooapration/aloula.jpg'
import Coop5 from '../assets/Cooapration/altayseer.jpg'
import Coop6 from '../assets/Cooapration/elryad.jpg'
import Coop7 from '../assets/Cooapration/emakn.jpg'
import Coop8 from '../assets/Cooapration/raghe.jpg'
import Coop9 from '../assets/Cooapration/ysr.jpg'
import Coop10 from '../assets/Cooapration/emirate.PNG'
import Coop11 from '../assets/Cooapration/arabe.jpg'
import allCompanies from '../assets/Cooapration/Companies.jpg'
import listo from '../assets/new-employee.png'
import img1 from '../assets/fe-m-2-1.png'
import img2 from '../assets/fe-m-2-2.png'
import img3 from '../assets/fe-m-2-3.png'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import Slider from '../Component/Slider';
import { Helmet } from 'react-helmet-async';


function Home() {
  const navigate = useNavigate()

  return (
    <>
          <Helmet>
        <title>  الصفحة الرئيسية - رؤية لتمويل السيارات</title>
        <meta
      name="description"
      content=" الصفحة الرئيسية رؤية لتمويل السيارات للافراد والشركات وللتمويل الشخصي "
      /> 
      <link rel="canonical" href="/Home"></link>
      </Helmet>
      <Slider/>
        <div data-aos="fade-up" className=' bg-white font-extrabold px-2 text-base text-right p-5 '>
          <div className='md:flex md:flex-row-reverse md:justify-between '>
      <div  className='md:flex-col p-7'>
      <p className='text-black text-3xl font-bold md:text-3xl'>
        <span className=' space-x-3 font-sans py-3 font-bold  text-[rgb(47,114,140)] underline rounded-3xl '
        >رؤية</span>  لتمويل السيارات بأسهل الطرق وأفضل الشروط

      </p>
      <div className=' text-gray-600 pt-7 pb-9 text-xl md:text-lg md:w-[600px]  '   >
      مع رؤية استفيد من خبراتنا الواسعة في تقديم حلول تمويل السيارات المناسبة لك.
       دعنا نساعدك في تحقيق أحلامك بامتالك السيارة التي تستحقها، بشروط ميسرة وإجراءات سريعة
      
      </div>
      </div>
      <div>
        <img data-aos="fade-up" data-aos-duration="1000" className=' rounded-2xl ' src={HeroImg} alt='رؤية للتمويل'/>
      </div>
          </div>

    </div>
    {/* ---------------------Middle Card---------------- */}
    <div  data-aos="fade-up" className=' flex justify-end px-3 md:absolute md:top-[930px] md:left-[400px] '>
 <div  className=' bg-white text-right md:rounded-xl   '>

<div className=' w-[320px]  px-3 py-5 border border-slate-600 text-slate-700  rounded-2xl shadow-lg'>


<p className=' flex justify-between pb-2'>
<img src={checked} alt='/ رؤية لتمويل السيارات' className='w-6 h-6  bg-[rgb(47,114,140)] rounded-full'/>
وسيط رقمي كامل

</p>

<p className=' flex justify-between pb-2'>
<img src={checked} alt='/ رؤية لتمويل السيارات' className='w-6 h-6 bg-[rgb(47,114,140)] rounded-full'/>
افضل فائده وسعر داخل المملكة 
</p>


<p className=' flex justify-between '>
<img src={checked} alt='/ رؤية لتمويل السيارات' className='w-6 h-6 bg-[rgb(47,114,140)] rounded-full space-x-7'/>
نخدمك بحب دائما على مدار الاسبوع
</p>

</div>
</div> 
    </div>
    {/* -----------------------------------------SWEAPERS------------------------------------ */}
    <div data-aos="fade-up" className=''>
        <div className='  bg-white text-right pt-[3em] px-7  '>
                    {/* تقسيط سيارات  للافراد فقط  */}
        <p className='pb-[1em] text-3xl text-black font-extrabold'>تقسيط سيارات للافراد</p>
        {/* -------------------First Card-------------- */}
        <Swiper
        // install Swiper modules
      modules={[Navigation, Scrollbar, A11y, Autoplay ]}
      breakpoints={{
        320: {
          slidesPerView: 1,
          spaceBetween: 40,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 5 ,
          spaceBetween: 2,
        },
      }}
      autoplay={{delay:4000}}
      scrollbar={{ draggable: true }}
      onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide> 
        <div className='border border-[rgb(47,114,140)] rounded-2xl w-[250px] max-h-fit'>
          <div className='p-7 max-h-fit'>
          {/* --------TOP Side LAZEN SETUP FAMILYY W EZBOT EL 5TOTT-------- */}
          <div>
        <img src={slideImg1} alt='/رؤية لتمويل السيارات للشركات والافراد وللتمويل الشخصي' className=' rounded-2xl pb-9'/>
          </div>
          {/* -------------BOTTOM Sise ---------- */}
          <div className='min-h-fit text-lg text-red-500' >
            <h1 className='text-3xl pb-4 text-black font-bold '>تمويل المرابحة </h1>
            <p className='text-gray-800  min-h-fit text-lg py-2 '>
              برنامج التمويل التكميلي من بنك البلاد يقدم فرص تمويل مرنة ومُيّسرة للغاية
              تتيح لعملائنا الحصول على تمويل إضافي بسهولة ودون عناء في أي وقت.</p>
          </div>
          <button onClick={()=> navigate('/IndividualRequest') }  className='btn bg-[rgb(47,114,140)] text-white justify-center mx-auto' > قدم على طلب تمويل</button>
          </div>
        </div>
</SwiperSlide>
<SwiperSlide> 
<div  className='border border-[rgb(47,114,140)] rounded-2xl w-[250px] max-h-fit'>
          <div className='p-7 max-h-fit'>
          {/* --------TOP Side LAZEN SETUP FAMILYY W EZBOT EL 5TOTT-------- */}
          <div>
        <img src={slideImg2} alt='/ رؤية لتمويل السيارات للشركات والافراد ' className=' rounded-2xl pb-9'/>
          </div>
          {/* -------------BOTTOM Sise ---------- */}
          <div className='min-h-fit text-lg text-red-500' >
            <h1 className='text-3xl pb-4 text-black font-bold   '>تمويل شراء سيارات </h1>
            <p className='text-gray-800  min-h-fit text-lg py-2 '>
              برنامج التمويل التكميلي من بنك البلاد يقدم فرص تمويل مرنة ومُيّسرة للغاية
              تتيح لعملائنا الحصول على تمويل إضافي بسهولة ودون عناء في أي وقت.</p>
          </div>
          <div className='flex'>
          <button onClick={()=> navigate('/IndividualRequest') }  className='btn bg-[rgb(47,114,140)] text-white  mx-auto ' > قدم على طلب تمويل  </button>
          </div>
          </div>
        </div>
</SwiperSlide>
<SwiperSlide> 
<div  className='border border-[rgb(47,114,140)] rounded-2xl w-[250px] max-h-fit'>
          <div className='p-7 max-h-fit'>
          {/* --------TOP Side LAZEN SETUP FAMILYY W EZBOT EL 5TOTT-------- */}
          <div>
        <img src={slideImg3} alt='/ رؤية لتمويل السيارات للشركات والافراد ' className=' rounded-2xl pb-9'/>
          </div>
          {/* -------------BOTTOM Sise ---------- */}
          <div className='min-h-fit text-lg text-red-500' >
            <h1 className='text-3xl pb-4 text-black font-bold   '>تمويل اصحاب الاعمال الحرة </h1>
            <p className='text-gray-800  min-h-fit text-lg py-2 '>
              برنامج التمويل التكميلي من بنك البلاد يقدم فرص تمويل مرنة ومُيّسرة للغاية
              تتيح لعملائنا الحصول على تمويل إضافي بسهولة ودون عناء في أي وقت.</p>
          </div>
          <div className='flex'>
          <button onClick={()=> navigate('/IndividualRequest') }  className='btn bg-[rgb(47,114,140)] text-white justify-center mx-auto' > قدم على طلب تمويل</button>
          </div>
          </div>
        </div>
</SwiperSlide>
<SwiperSlide> 
<div  className='border border-[rgb(47,114,140)] rounded-2xl w-[250px] max-h-fit'>
          <div className='p-7 max-h-fit'>
          {/* --------TOP Side LAZEN SETUP FAMILYY W EZBOT EL 5TOTT-------- */}
          <div>
        <img src={slideImg2} alt='/ رؤية لتمويل السيارات للشركات والافراد ' className=' rounded-2xl pb-9'/>
          </div>
          {/* -------------BOTTOM Sise ---------- */}
          <div className='min-h-fit text-lg text-red-500' >
            <h1 className='text-3xl pb-4 text-black  font-bold  '>تمويل اعمال شخصية </h1>
            <p className='text-gray-800  min-h-fit text-lg py-2 '>
              برنامج التمويل التكميلي من بنك البلاد يقدم فرص تمويل مرنة ومُيّسرة للغاية
              تتيح لعملائنا الحصول على تمويل إضافي بسهولة ودون عناء في أي وقت.</p>
          </div>
          <div className='flex'>
          <button onClick={()=> navigate('/CompanyRequest') }  className='btn bg-[rgb(47,114,140)] text-white justify-center mx-auto' > قدم على طلب تمويل</button>
          </div>
          </div>
        </div>
</SwiperSlide>
<SwiperSlide> 
        <div className='border border-[rgb(47,114,140)] rounded-2xl w-[250px] max-h-fit'>
          <div className='p-7 max-h-fit'>
          {/* --------TOP Side LAZEN SETUP FAMILYY W EZBOT EL 5TOTT-------- */}
          <div>
        <img src={slideImg1} alt='/ رؤية لتمويل السيارات للشركات والافراد ' className=' rounded-2xl pb-9'/>
          </div>
          {/* -------------BOTTOM Sise ---------- */}
          <div className='min-h-fit text-lg text-red-500' >
            <h1 className='text-3xl pb-4 text-black font-bold '>تمويل المرابحة </h1>
            <p className='text-gray-800  min-h-fit text-lg py-2 '>
              برنامج التمويل التكميلي من بنك البلاد يقدم فرص تمويل مرنة ومُيّسرة للغاية
              تتيح لعملائنا الحصول على تمويل إضافي بسهولة ودون عناء في أي وقت.</p>
          </div>
          <button onClick={()=> navigate('/IndividualRequest') }  className='btn bg-[rgb(47,114,140)] text-white justify-center mx-auto' > قدم على طلب تمويل</button>
          </div>
        </div>
</SwiperSlide>
<SwiperSlide> 
        <div className='border border-[rgb(47,114,140)] rounded-2xl w-[250px] max-h-fit'>
          <div className='p-7 max-h-fit'>
          {/* --------TOP Side LAZEN SETUP FAMILYY W EZBOT EL 5TOTT-------- */}
          <div>
        <img src={slideImg1} alt='/ رؤية لتمويل السيارات للشركات والافراد ' className=' rounded-2xl pb-9'/>
          </div>
          {/* -------------BOTTOM Sise ---------- */}
          <div className='min-h-fit text-lg text-red-500' >
            <h1 className='text-3xl pb-4 text-black font-bold '>تمويل المرابحة </h1>
            <p className='text-gray-800  min-h-fit text-lg py-2 '>
              برنامج التمويل التكميلي من بنك البلاد يقدم فرص تمويل مرنة ومُيّسرة للغاية
              تتيح لعملائنا الحصول على تمويل إضافي بسهولة ودون عناء في أي وقت.</p>
          </div>
          <button onClick={()=> navigate('/IndividualRequest') }  className='btn bg-[rgb(47,114,140)] text-white justify-center mx-auto' > قدم على طلب تمويل</button>
          </div>
        </div>
</SwiperSlide>
<SwiperSlide> 
        <div className='border border-[rgb(47,114,140)] rounded-2xl w-[250px] max-h-fit'>
          <div className='p-7 max-h-fit'>
          {/* --------TOP Side LAZEN SETUP FAMILYY W EZBOT EL 5TOTT-------- */}
          <div>
        <img src={slideImg1} alt='/ رؤية لتمويل السيارات للشركات والافراد ' className=' rounded-2xl pb-9'/>
          </div>
          {/* -------------BOTTOM Sise ---------- */}
          <div className='min-h-fit text-lg text-red-500' >
            <h1 className='text-3xl pb-4 text-black font-bold '>تمويل المرابحة </h1>
            <p className='text-gray-800  min-h-fit text-lg py-2 '>
              برنامج التمويل التكميلي من بنك البلاد يقدم فرص تمويل مرنة ومُيّسرة للغاية
              تتيح لعملائنا الحصول على تمويل إضافي بسهولة ودون عناء في أي وقت.</p>
          </div>
          <button onClick={()=> navigate('/IndividualRequest') }  className='btn bg-[rgb(47,114,140)] text-white justify-center mx-auto' > قدم على طلب تمويل</button>
          </div>
        </div>
</SwiperSlide>
    </Swiper>
        
        </div>
        {/* --------------------------------------تقسيط شركات --------------------------- */}
        <div data-aos="fade-up" className='bg-white text-right pt-[3em] px-7 '>
{/* تقسيط سيارات فقط  */}
        <p className='pb-[1em] text-3xl text-black font-extrabold'>تمويل شركات </p>
        {/* -------------------First Card-------------- */}
        <Swiper
        // install Swiper modules
      modules={[Navigation, Scrollbar, A11y, Autoplay ]}
      slidesPerView="auto"
      breakpoints={{
        320: {
          slidesPerView: 1,
          spaceBetween: 40,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 5 ,
          spaceBetween: 2,
        },
      }}
      autoplay={{delay:4000}}
      scrollbar={{ draggable: true }}
      onSwiper={(swiper) => console.log(swiper)}
    >
      {/* ------------------API MN EL M3RD BTA3 EL SOR--------- */}
      <SwiperSlide> 
      <div className='border border-[rgb(47,114,140)] rounded-2xl w-[250px] max-h-fit'>
          <div className='p-7 max-h-fit'>
          {/* --------TOP Side LAZEN SETUP FAMILYY W EZBOT EL 5TOTT-------- */}
          <div>
        <img src={slideImg1} alt='/ رؤية لتمويل السيارات للشركات والافراد ' className=' rounded-2xl pb-9'/>
          </div>
          {/* -------------BOTTOM Sise ---------- */}
          <div className='min-h-fit text-lg text-red-500' >
            <h1 className='text-3xl pb-4 text-black  font-bold  '>تمويل شراء سيارات للشركات </h1>
            <p className='text-gray-800  min-h-fit text-lg py-2 '>
              برنامج التمويل التكميلي من بنك البلاد يقدم فرص تمويل مرنة ومُيّسرة للغاية
              تتيح لعملائنا الحصول على تمويل إضافي بسهولة ودون عناء في أي وقت.</p>
          </div>
          <div className='flex'>
          <button onClick={()=> navigate('/CompanyRequest') }  className='btn bg-[rgb(47,114,140)] text-white justify-center mx-auto' > قدم على طلب تمويل</button>
          </div>
                    </div>
        </div>
</SwiperSlide>
<SwiperSlide> 
<div className='border border-[rgb(47,114,140)] rounded-2xl w-[250px] max-h-fit'>
          <div className='p-7 max-h-fit'>
          {/* --------TOP Side LAZEN SETUP FAMILYY W EZBOT EL 5TOTT-------- */}
          <div>
        <img src={slideImg2} alt='/ رؤية لتمويل السيارات للشركات والافراد ' className=' rounded-2xl pb-9'/>
          </div>
          {/* -------------BOTTOM Sise ---------- */}
          <div className='min-h-fit text-lg text-red-500' >
            <h1 className='text-3xl pb-4 text-black  font-bold  '>تمويل شراء سيارات بالقسط للشركات </h1>
            <p className='text-gray-800  min-h-fit text-lg py-2 '>
              برنامج التمويل التكميلي من بنك البلاد يقدم فرص تمويل مرنة ومُيّسرة للغاية
              تتيح لعملائنا الحصول على تمويل إضافي بسهولة ودون عناء في أي وقت.</p>
          </div>
          <div className='flex'>
          <button onClick={()=> navigate('/CompanyRequest') }  className='btn bg-[rgb(47,114,140)] text-white justify-center mx-auto' > قدم على طلب تمويل</button>
          </div>
                    </div>
        </div>
</SwiperSlide>
<SwiperSlide> 
<div className='border border-[rgb(47,114,140)] rounded-2xl w-[250px] max-h-fit'>
          <div className='p-7 max-h-fit'>
          {/* --------TOP Side LAZEN SETUP FAMILYY W EZBOT EL 5TOTT-------- */}
          <div>
        <img src={slideImg3} alt='/ رؤية لتمويل السيارات للشركات والافراد ' className=' rounded-2xl pb-9'/>
          </div>
          {/* -------------BOTTOM Sise ---------- */}
          <div className='min-h-fit text-lg text-red-500' >
            <h1 className='text-3xl pb-4 text-black  font-bold  '>تمويل اصحاب الشركات الحرة  </h1>
            <p className='text-gray-800  min-h-fit text-lg py-2 '>
              برنامج التمويل التكميلي من بنك البلاد يقدم فرص تمويل مرنة ومُيّسرة للغاية
              تتيح لعملائنا الحصول على تمويل إضافي بسهولة ودون عناء في أي وقت.</p>
          </div>
          <div className='flex'>
          <button onClick={()=> navigate('/CompanyRequest') }  className='btn bg-[rgb(47,114,140)] text-white justify-center mx-auto' > قدم على طلب تمويل</button>
          </div>
                    </div>
        </div>
</SwiperSlide>
<SwiperSlide> 
<div className='border border-[rgb(47,114,140)] rounded-2xl w-[250px] max-h-fit'>
          <div className='p-7 max-h-fit'>
          {/* --------TOP Side LAZEN SETUP FAMILYY W EZBOT EL 5TOTT-------- */}
          <div>
        <img src={slideImg1} alt='/ رؤية لتمويل السيارات للشركات والافراد ' className=' rounded-2xl pb-9'/>
          </div>
          {/* -------------BOTTOM Sise ---------- */}
          <div className='min-h-fit text-lg text-red-500' >
            <h1 className='text-3xl pb-4 text-black  font-bold  '>تمويل شراء سيارات للشركات </h1>
            <p className='text-gray-800  min-h-fit text-lg py-2 '>
              برنامج التمويل التكميلي من بنك البلاد يقدم فرص تمويل مرنة ومُيّسرة للغاية
              تتيح لعملائنا الحصول على تمويل إضافي بسهولة ودون عناء في أي وقت.</p>
          </div>
          <div className='flex'>
          <button onClick={()=> navigate('/CompanyRequest') }  className='btn bg-[rgb(47,114,140)] text-white justify-center mx-auto' > قدم على طلب تمويل</button>
          </div>    
                </div>
        </div>
</SwiperSlide>
<SwiperSlide> 
      <div className='border border-[rgb(47,114,140)] rounded-2xl w-[250px] max-h-fit'>
          <div className='p-7 max-h-fit'>
          {/* --------TOP Side LAZEN SETUP FAMILYY W EZBOT EL 5TOTT-------- */}
          <div>
        <img src={slideImg1} alt='/ رؤية لتمويل السيارات للشركات والافراد ' className=' rounded-2xl pb-9'/>
          </div>
          {/* -------------BOTTOM Sise ---------- */}
          <div className='min-h-fit text-lg text-red-500' >
            <h1 className='text-3xl pb-4 text-black  font-bold  '>تمويل شراء سيارات للشركات </h1>
            <p className='text-gray-800  min-h-fit text-lg py-2 '>
              برنامج التمويل التكميلي من بنك البلاد يقدم فرص تمويل مرنة ومُيّسرة للغاية
              تتيح لعملائنا الحصول على تمويل إضافي بسهولة ودون عناء في أي وقت.</p>
          </div>
          <div className='flex'>
          <button onClick={()=> navigate('/CompanyRequest') }  className='btn bg-[rgb(47,114,140)] text-white justify-center mx-auto' > قدم على طلب تمويل</button>
          </div>
                    </div>
        </div>
</SwiperSlide>
<SwiperSlide> 
      <div className='border border-[rgb(47,114,140)] rounded-2xl w-[250px] max-h-fit'>
          <div className='p-7 max-h-fit'>
          {/* --------TOP Side LAZEN SETUP FAMILYY W EZBOT EL 5TOTT-------- */}
          <div>
        <img src={slideImg1} alt='/ رؤية لتمويل السيارات للشركات والافراد ' className=' rounded-2xl pb-9'/>
          </div>
          {/* -------------BOTTOM Sise ---------- */}
          <div className='min-h-fit text-lg text-red-500' >
            <h1 className='text-3xl pb-4 text-black  font-bold  '>تمويل شراء سيارات للشركات </h1>
            <p className='text-gray-800  min-h-fit text-lg py-2 '>
              برنامج التمويل التكميلي من بنك البلاد يقدم فرص تمويل مرنة ومُيّسرة للغاية
              تتيح لعملائنا الحصول على تمويل إضافي بسهولة ودون عناء في أي وقت.</p>
          </div>
          <div className='flex'>
          <button onClick={()=> navigate('/CompanyRequest') }  className='btn bg-[rgb(47,114,140)] text-white justify-center mx-auto' > قدم على طلب تمويل</button>
          </div>
                    </div>
        </div>
</SwiperSlide>
    </Swiper>
        
        </div>
        </div>
<div className=' text-black text-3xl text-center py-3 h-[150px]'>
  <button className='btn text-white text-3xl py-5 bg-[rgb(47,114,140)] h-[100px] '> <span className="loading loading-ring loading-lg"></span> <a href='/Offers'>الذهاب للعروض</a>  <span className="loading loading-ring loading-lg"></span></button>
</div>
{/* --------------------------------كيفية  طلب تمويل ------------------------- */}
<div  class="bg-white py-24 sm:py-32 bg-[url(https://tasweqy.sa/new/assets/img/bg/process-bg-1.jpg)]">
  <div class="mx-auto max-w-7xl  lg:px-8">
  <div data-aos="fade-up" className='text-center '>
        <img src={list} alt='/ رؤية لتمويل السيارات للشركات والافراد ' className='w-[30px] h-[30px] m-auto'/>
        <h3 className='pb-[40px] pt-[20px] text-xl font-bold text[rgb(47,114,140)]'>كيفية طلب التمويل ؟ </h3> 
        <h1 className='text-black text-3xl font-bold pb-[70px] ' >خطوات طلب التمويل</h1>
      </div>
    <dl class="grid grid-cols-1 gap-x-8 gap-y-16 text-center md:grid-cols-4 px-4">
      {/* Card-1 */}
    <div data-aos="fade-up" className='py-5'>
      <div className='min-h-[320px]    hover:bg-amber-600 bg-white border shadow-md rounded-3xl ' >
      <div className="card   ">
      <div className="badge bg-[rgb(47,114,140)] text-white badge-lg m-2 h-[50px] w-[50px] text-3xl   border-8 border-slate-300 ">1</div>
      <figure><img src={firstImage} alt="car!"/></figure>
  <div className="card-body rounded-b-2xl   ">
    <p className=' text-[rgb(47,114,140)] text-center text-3xl font-extrabold' >ملئ طلب التمويل</p>
    <div className="card-actions justify-end">
    </div>
  </div>
</div>
      </div>
      </div>
            {/* Card-2 */}

      <div data-aos="fade-up" className='py-5'>
      <div className=' min-h-[320px]  hover:bg-amber-600 bg-white border shadow-md rounded-3xl ' >
      <div className="card    ">
      <div className="badge bg-[rgb(47,114,140)] text-white badge-lg m-2 h-[50px] w-[50px] text-3xl  border-8 border-slate-300 ">2</div>
      <figure><img src={SecondImage} alt="car!"/></figure>
  <div className="card-body rounded-b-2xl   ">
    <p className=' text-[rgb(47,114,140)] text-center text-3xl font-extrabold' >طلب العروض</p>
    <div className="card-actions justify-end">
    </div>
  </div>
</div>
      </div>
      </div>
            {/* Card-3 */}

      <div data-aos="fade-up" className='py-5'>
      <div className='min-h-[320px]   hover:bg-amber-600 bg-white border shadow-md rounded-3xl ' >
      <div className="card    ">
      <div className="badge bg-[rgb(47,114,140)] text-white badge-lg m-2 h-[50px] w-[50px] text-3xl  border-8 border-slate-300 ">3</div>
      <figure><img src={thirdImage} alt="car!"/></figure>
  <div className="card-body rounded-b-2xl   ">
    <p className=' text-[rgb(47,114,140)] text-center text-3xl font-extrabold' >اختيار العرض المناسب </p>
    <div className="card-actions justify-end">
    </div>
  </div>
</div>
      </div>
      </div>
            {/* Card-4 */}
      <div data-aos="fade-up" className='py-5'>
      <div className='min-h-[320px]   hover:bg-amber-600 bg-white border shadow-md rounded-3xl ' >
      <div className="card    ">
      <div className="badge bg-[rgb(47,114,140)] text-white badge-lg m-2 h-[50px] w-[50px] text-3xl  border-8 border-slate-300 ">4</div>
      <figure><img src={fourthImage} alt="car!"/></figure>
  <div className="card-body rounded-b-2xl   ">
    <p className=' text-[rgb(47,114,140)] text-center text-3xl font-extrabold' >الموافقة على الطلب</p>
    <div className="card-actions justify-end">
    </div>
  </div>
</div>
      </div>
      </div>
    </dl>
  </div>
</div>
{/* --------------------------------خطوات طلب تمويل ------------------------- */}

    <div className=' py-5 px-5 text-black bg-white'>
      <div className='text-center'>
        <img data-aos="fade-up" src={listo} alt='/ رؤية لتمويل السيارات للشركات والافراد ' className='w-[90px] h-[90px] m-auto'/>
        <h3 data-aos="fade-up" className='pb-[40px] pt-[20px] text-3xl  font-extrabold text-teal-700'>شركاء النجاح</h3> 
      </div>
      <div className='py-5'>
      <Swiper
        // install Swiper modules
      modules={[Navigation,  Scrollbar, A11y, Autoplay ]}
      spaceBetween={50}
      slidesPerView={1}
      autoplay={{delay:4000}}
      scrollbar={{ draggable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      >
      {/* ------------------API MN EL M3RD BTA3 EL SOR--------- */}
      <SwiperSlide> <img src={Coop1} alt='/ رؤية لتمويل السيارات للشركات والافراد ' className=" bg-center max-w-[500px] mx-auto" />
</SwiperSlide>

<SwiperSlide> <img src={Coop2} alt='/ رؤية لتمويل السيارات للشركات والافراد ' className="bg-center max-w-[500px] max-h-[200px] mx-auto" />
</SwiperSlide>

<SwiperSlide> <img src={Coop3} alt='/ رؤية لتمويل السيارات للشركات والافراد ' className="bg-center max-w-[500px] max-h-[200px] mx-auto" />
</SwiperSlide>

<SwiperSlide> <img src={Coop4} alt='/ رؤية لتمويل السيارات للشركات والافراد ' className="bg-center max-w-[500px] max-h-[200px] mx-auto" />
</SwiperSlide>

<SwiperSlide> <img src={Coop5} alt='/ رؤية لتمويل السيارات للشركات والافراد ' className="bg-center max-w-[500px] max-h-[200px] mx-auto" />
</SwiperSlide>

<SwiperSlide> <img src={Coop6} alt='/ رؤية لتمويل السيارات للشركات والافراد ' className="bg-center max-w-[500px] max-h-[200px] mx-auto" />
</SwiperSlide>

<SwiperSlide> <img src={Coop7} alt='/ رؤية لتمويل السيارات للشركات والافراد ' width={300} className="bg-center max-w-[500px] max-h-[200px] mx-auto" />
</SwiperSlide>

<SwiperSlide> <img src={Coop8} alt='/ رؤية لتمويل السيارات للشركات والافراد ' width={300} className=" max-w-[500px] max-h-[200px] mx-auto" />
</SwiperSlide>

<SwiperSlide> <img src={Coop9} alt='/ رؤية لتمويل السيارات للشركات والافراد ' className="bg-center max-w-[500px] max-h-[200px] mx-auto" />
</SwiperSlide>

<SwiperSlide> <img src={Coop10} alt='/ رؤية لتمويل السيارات للشركات والافراد ' width={300}  className="bg-center max-w-[500px] max-h-[200px] mx-auto" />
</SwiperSlide>

<SwiperSlide> <img src={Coop11} alt='/ رؤية لتمويل السيارات للشركات والافراد ' className="bg-center max-w-[500px] max-h-[200px] mx-auto" />
</SwiperSlide>

    </Swiper>
      </div>
      
      {/* --------ALL CO-OPERATIONS COMPANIES-----------  */}
      <div  data-aos="fade-up">
      <a href="https://tasweqy.sa/images/blog/1644086028.jpg">
    <img src={allCompanies} border="0" alt="Companies" />
      </a>  
      </div>
      </div>



      {/* ---------------STATS--------------- */}
        <div  className="px-4 py-6 grid grid-cols-1 md:grid-cols-3 md:gap-3 gap-y-2 ">
    {/* First CARD */}
    <div data-aos="fade-up"  data-aos-duration="1500" className=" flex-col py-3 card bg-[#2f728c]  w-70 h-52 rounded-2xl pb-6 justify-center "  >
    <img className=' self-center w-19 h-18 pb-2' src={img1} alt='/ رؤية لتمويل السيارات للشركات والافراد وللتمويل الشخصي'/>
    <div className='text-center text-3xl text-white'>
    <p>+300</p>
    <p>معرض سيارات </p>
    </div>
    </div>
    {/* SECOND CARD */}
    <div data-aos="fade-up" data-aos-duration="1500" className=" flex-col  card bg-[#bca058]  w-70 h-52 rounded-2xl pb-6 justify-center "  >
    <img className=' self-center w-19 h-18 pb-2' src={img2} alt='/ رؤية لتمويل السيارات للشركات والافراد وللتمويل الشخصي'/>
    <div className='text-center text-3xl text-white'>
    <p>+35k</p>
    <p> طلبات تمويل </p>
    </div>
    </div>
    {/* THIRD CARD */}
    <div data-aos="fade-up" data-aos-duration="1500" className=" flex-col card bg-[#2f728c]  w-70 h-52 rounded-2xl pb-6 justify-center "  >
    <img className=' self-center w-19 h-18 pb-2' src={img3} alt='/ رؤية لتمويل السيارات للشركات والافراد وللتمويل الشخصي'/>
    <div className='text-center text-3xl text-white'>
    <p>+20</p>
    <p> جهة تمويلية  </p>
    </div>
    </div>
    </div>
    </>
  )
}

export default Home