import { useState } from 'react'
import React  from 'react'
import contact3 from '../assets/contact-1-1.png'
import contact2 from '../assets/contact-1-2.png'
import contact1 from '../assets/contact-1-3.png'
import { txtDB } from "../Config/firebase";
import { addDoc, collection } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async'
import FacebookIcon from '../assets/facebook.png'
import InstgramIcon from '../assets/instagram.png'
function Contact() {
  const [Name,setName] = useState('')
  const [Email,setEmail] = useState('')
  const [Phone,setPhone] = useState('')
  const [Msg,setMsg] = useState('')
  const navigate = useNavigate()

  const handleClick = async () =>{
    const valRef = collection(txtDB,'Complaints Requests')
    // await addDoc(valRef,{txtVal:txt,TileVal:title,CategoryVal:category,MarkaVal:marka,imgUrl:img})
    await addDoc(valRef,{Email:Email , FirstName:Name , Phone:Phone , Message:Msg })
    navigate('/')
    alert("تم ارسال رسالتك ،سنتواصل معك في اقرب وقت !")
}

  return (
    <>
      <Helmet>
        <title> اتصل بنا - رؤية لتمويل السيارات</title>
        <meta
      name="description"
      content=" اتصل بنا التواصل مع الادراة"
      /> 
        <link rel="canonical" href="/Contact"></link>

      </Helmet>
      <div className='flex flex-col  text-center pt-7'>
        <div data-aos="fade-up" className='py-4 justify-center self-center md:grid md:grid-cols-3 md:gap-7  '>
        {/* CARD-1 */}
        <div className=' text-black card justify-center text-center w-[370px] h-[279px] shadow-xl border bg-[#f3f5fc] '>
        <img  className=' self-center border-4 border-double rounded-full p-3  border-[rgb(47,114,140)] 'src={contact1} alt='ظ'/>
        <h1 className='py-4 text-black'>: البريد الالكتروني</h1>
        <p>royafunding@gmail.com</p>
        </div>
        {/* CARD-2 */}
        <div className=' text-black py-4 bg-[#e7ecff] card justify-center text-center w-[370px] h-[279px] shadow-xl border'>
        <img className='card self-center border-4 border-double rounded-full p-3  border-[rgb(47,114,140)]' src={contact2} alt='رقم الشركة'/>
        <h1 className='py-4 text-black'>: اتصل بنا للمساعدة</h1>
        <p>+966 55 713 4101</p>
        </div>
        {/* CARD-3 */}
        <div className=' text-black card justify-center text-center w-[370px] h-[279px] shadow-xl border'>
        <img className='self-center border-4 border-double rounded-full p-3  border-[rgb(47,114,140)]' src={contact3} alt='عنوان الشركة '/>
        <h1 className='py-4 text-black'>: عنوان الشركة</h1>
        <p>المملكة العربية السعودية , الرياض </p>
        </div>
        {/* CARD-4 */}
        <div className=' text-black card justify-center text-center w-[370px] h-[279px] shadow-xl border'>
        <div className=' text-black py-4 bg-[#e7ecff] card justify-center text-center w-[370px] h-[279px] shadow-xl border'>
        <img className='card self-center border-4 border-double rounded-full p-3  border-[rgb(47,114,140)]' src={contact2} alt='رقم الشركة'/>
        <div className=' items-center flex flex-col pt-5'>
        <a href='https://www.facebook.com/profile.php?id=61564283364281&mibextid=ZbWKwL' className='text-xl py-3 flex flex-  '> تواصل معنا على الفيسبوك <img className='flex flex-row h-7 w-7  ml-3' src={FacebookIcon} alt='فيسبوك'/></a>
        <a href='https://www.instagram.com/royafunding' className='text-xl py-3 flex flex-row  '> تواصل معنا على الانستجرام<img className='flex flex-row h-7 w-7 ml-3' src={InstgramIcon} alt='انستجرام'/></a>
        </div>

        </div>
        </div>
        </div>
        {/* -----------------------Form------------------ */}
        <div data-aos="fade-up" type='form' className='text-right px-5 py-9 font-bold'>
        <p className='text-[rgb(47,114,140)]'> في اي وقت</p>
        <p className=' text-3xl text-black '>  ارسل لنا مشكلتك او سؤالك </p>

        <div className=' rounded-3xl  bg-white text-right text-black '>
          <div className=' md:grid md:grid-cols-2'>
        <input className='bg-white m-4 rounded-xl py-4 px-9  text-right border border-[rgb(47,114,140)] ' placeholder='اسمك'onChange={(e)=>setName(e.target.value)}/>
        <input className='bg-white m-4 rounded-xl py-4 px-9  text-right border border-[rgb(47,114,140)]  ' placeholder='البريد الالكتروني 'onChange={(e)=>setEmail(e.target.value)}/>
          </div>
        <input className='bg-white m-4 rounded-xl py-4 px-9  text-right border border-[rgb(47,114,140)] md:mx-auto md:w-[600px] ' placeholder='رقم الجوال 'onChange={(e)=>setPhone(e.target.value)}/>
        <input className='bg-white m-4 rounded-xl py-9 px-9  text-right border border-[rgb(47,114,140)] md:mx-auto md:w-[600px] ' placeholder='محتوى الرسالة ' onChange={(e)=>setMsg(e.target.value)}/>
        </div>
        <div className='text-center '>
        <button type='submit' className='bg-[rgb(47,114,140)] text-white px-7 py-4 rounded-3xl text-center  ' onClick={handleClick}> ارسل رسالتك الان   </button>
        </div>
        </div>
    </div>
    </>
  )
}

export default Contact