import React from 'react'
import { useState ,useEffect } from 'react'
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth'
import {auth} from '../Config/firebase'
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

const SignIn = () => {

  const navigate= useNavigate()
  const [loginEmail , setloginEmail]=useState("")
  const [loginPassword ,setloginPassword]=useState("")
  const [user,setUser] = useState({})

  useEffect(()=>{
    onAuthStateChanged(auth , (currentUser)=>{
      setUser(currentUser)
    })
    },[])

const login =async  ()=>{
  try{
    const user = await signInWithEmailAndPassword(auth, loginEmail , loginPassword )
    navigate('/ContentMangment')
  }catch (err){
    alert(" عذرا توجد مشكلة حاول مرة اخرى ")
  }
}



  return (
    <>
          <Helmet>
        <title> تسجيل الدخول - رؤية لتمويل السيارات</title>
        <meta
      name="description"
      content=" تسجيل دخول لرؤية لتمويل السيارات للافراد والشركات وللتمويل الشخصي "
      /> 
      <link rel="canonical" href="/SignIn"></link>
      </Helmet>
    <div>
    <div>
      <head>
        <title>تسجيل الدخول</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
          rel="stylesheet"
          />
        <link
          href="https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap"
          rel="stylesheet"
          />
      </head>
      <body
        className="flex font-poppins items-center justify-center  min-w-screen min-h-screen"
        >
        <div className="grid gap-8">
          <div
            id="back-div"
            className=" bg-[rgb(47,114,140)] rounded-[26px] m-4 "
            >
            <div
              className="border-[20px] border-transparent rounded-[20px]  bg-white shadow-lg xl:p-10 2xl:p-10 lg:p-10 md:p-10 sm:p-2 m-2"
              >
              <h1 className="pt-8 pb-6 font-bold text-3xl text-center cursor-default text-[rgb(47,114,140)]">
                تسجيل الدخول
              </h1>
              <div method="post" className="space-y-4">
                <div>
                  <label for="email" className="mb-2 text-[rgb(47,114,140)] text-lg">Email</label>
                  <input
                  onChange={(e)=>{setloginEmail(e.target.value)} }
                    id="email"
                    className="border bg-white text-black font-semi-bold p-3 shadow-md placeholder:text-base border-gray-300 rounded-lg w-full focus:scale-105 ease-in-out duration-300"
                    type="email"
                    placeholder="البريد الالكتروني"
                    required
                    />
                </div>
                <div>
                  <label for="password" className="mb-2 text-[rgb(47,114,140)] text-lg">Password</label>
                  <input
                  onChange={(e)=>{setloginPassword(e.target.value)}}
                  id="password"
                  className="border bg-white text-black font-semi-bold p-3 mb-2 shadow-md placeholder:text-base border-gray-300 rounded-lg w-full focus:scale-105 ease-in-out duration-300"
                  type="password"
                  placeholder="كلمة السر"
                  required
                  />
                </div>
                <button
                  onClick={login}
                  className=" bg-[rgb(47,114,140)] hover:bg-amber-600 shadow-lg mt-6 p-2 text-white rounded-lg w-full hover:scale-105  transition duration-300 ease-in-out"
                  >
                  تسجيل الدخول
                </button>
              </div>
              <div
                className="text-gray-500 flex text-center flex-col mt-4 items-center text-sm"
                >
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
    </div>
</> 
  )
}

export default SignIn

// ------------------------------------------
// import React from 'react'
// import { useState ,useEffect } from 'react'
// import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth'
// import {auth} from '../Config/firebase'
// import { useNavigate } from "react-router-dom";


// const SignIn = () => {
  
  //   const navigate= useNavigate()
  //   const [loginEmail , setloginEmail]=useState("")
  //   const [loginPassword ,setloginPassword]=useState("")
  //   const [user,setUser] = useState({})
  
  //   useEffect(()=>{
    //     onAuthStateChanged(auth , (currentUser)=>{
      //       setUser(currentUser)
      //     })
      //     },[])
      
      // const login =async  ()=>{
        //   try{
          //     const user = await signInWithEmailAndPassword(auth, loginEmail , loginPassword )
          //     navigate('/ContentMangment')
          //   }catch (err){
            //     alert(" عذرا توجد مشكلة حاول مرة اخرى ")
//   }
// }



//   return (
  //     <div><div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12 text-black">
  //     <div className="relative py-3 sm:max-w-xl sm:mx-auto">
  //       <div
//         className="absolute inset-0 bg-gradient-to-r to-[rgb(47,114,140)] from-sky-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl">
//       </div>
//       <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">

//         <div className="max-w-md mx-auto">
//           <div>
//             <h1 className=" text-black text-2xl font-semibold text-center ">تسجيل الدخول </h1>
//           </div>
//           <div className="divide-y divide-gray-200">
//           <div >
//               </div>
//             <div className="py-8 text-base text-black leading-6 space-y-4 sm:text-lg sm:leading-7">
//               <div className="relative">
//                 <input onChange={(e)=>{setloginEmail(e.target.value)} }  name="email" type="text" className=" bg-white border border-[rgb(47,114,140)] text-right h-10 w-full border-b-2 rounded-xl text-black focus:outline-none focus:borer-rose-600" placeholder="البريد الالكتروني " />
//               </div>
//               <div className="relative">
//                 <input onChange={(e)=>{setloginPassword(e.target.value)}} name="password" type="password" className=" bg-white border border-[rgb(47,114,140)] text-right h-10 w-full border-b-2 rounded-xl text-black focus:outline-none focus:borer-rose-600" placeholder='كلمة السر'  />
//               </div>
//               <div className="relative flex">
//                 <button onClick={login} className="bg-[rgb(47,114,140)] text-white rounded-md mx-auto px-[100px] py-3  ">تسجيل الدخول</button>
//               </div>
            
//             </div>
//           </div>
//         </div>

  
//       </div>
//     </div>
//   </div>
//   </div>
//   )
// }

// export default SignIn
