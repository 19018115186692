import { React,  useState } from "react";
import { imgDB,txtDB } from "../Config/firebase";
import { v4 } from "uuid";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { addDoc, collection } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import { Helmet } from "react-helmet-async";

function IndividualReq() {

  const [FullName , setFullName]=useState('')
  const [PhoneNumber , setPhoneNumber]=useState('')
  const [Email , setEmail]=useState('')
  const [Salary , setSalary]=useState('')
  const [FinancialObligations , setFinancialObligations]=useState('')
  const [City , setCity]=useState('')
  const [Nationalty , setNationalty]=useState('')
  const [Field , setField]=useState('')
  const [ Bank ,setBank ]=useState('')
  const [ Mortgage ,setMortgage ]=useState('')
  const [ Sema ,setSema ]=useState('')
  const [ Year ,setYear ]=useState('')
  const [ TrafficViolations ,setTrafficViolations ]=useState('')
  const [ DriveLicence ,setDriverLicence ]=useState('')
  const [ TypeOfRequest ,setTypeOfRequest ]=useState('')


  const [ImgDriverLicense,setImgDriverLicense] = useState('')
  const [ImgId,setImgId] = useState('')
  const [ImgSalary,setImgSalary] = useState('')
  

  const [CarMarka , setCarMarka ] = useState('')
  const [TypeOfCar , setTypeOfCar ] = useState('')

  const [Filter , setFilter ] = useState([])



  let Cars = [
    {
      name:" تويوتا ",
      TypeOfCars:[
        {
          name:"كامري"
        },
        {
          name:"يارس"
        },
        {
          name:"افالون"
        },
        {
          name:"كورولا"
        },
        {
          name:"راف فور"
        },
        {
          name:"لاندكروز"
        },
        {
          name:"هايلوكس"
        },
        {
          name:"هايلاندر"
        },
        {
          name:"فورتشنر"
        },
        {
          name:"اينوفا"
        },
        {
          name:"برادو"
        },
        {
          name:"شاص"
        },
        {
          name:"ايكو"
        },
        {
          name:" باص"
        },
        {
          name:"اف جي FG"
        },
        {
          name:" شاص"
        },
        {
          name:" ربع"
        },
        {
          name:"افانزا"
        },
        {
          name:"تندرا"
        },
        {
          name:"C-HR"
        },
        {
          name:"بريوس"
        },
        {
          name:"كرولا كروس"
        },
        {
          name:"راش"
        },
        {
          name:"ربع"
        },
        {
          name:"اريزو"
        },
        {
          name:"T-80"
        },
        {
          name:"رايز"
        },
        {
          name:"اوريون"
        },
        {
          name:"كرسيدا"
        },
        {
          name:"XA"
        },
  
        {
          name:"بريفيا"
        },
        {
          name:"سوبرا"
        },
        {
          name:"تويوتا86"
        },
        {
          name:"جرانفيا"
        },
        {
          name:"فيلوز"
        },
        {
          name:"كراون"
        },
        {
          name:"اوربان كروز "
        }
      ]
    },
    {
      name:" فورد ",
      TypeOfCars:[
        {
          name:"كراون فكتوريا"
        },
        {
          name:"جراند ماركيز"
        },
        {
          name:"اكسبلورر"
        },
        {
          name:"توروس"
        },
        {
          name:"اكسبيدشن"
        },
        {
          name:"موستنج"
        },
        {
          name:"ايدج"
        },
        {
          name:"F150"
        },
        {
          name:"فيوجن"
        },
        {
          name:"وندستار"
        },
        {
          name:"فلكس"
        },
        {
          name:"فوكس"
        },
        {
          name:"مونديو"
        },
        {
          name:" F250"
        },
        {
          name:"F350"
        },
        {
          name:" رينجر"
        },
        {
          name:" اكس كورجن"
        },
        {
          name:"بيك اب"
        },
        {
          name:"اسكيب"
        },
        {
          name:"سبلاش"
        },
        {
          name:"كوجر"
        },
        {
          name:"ثندريبرد"
        },
        {
          name:"F450"
        },
        {
          name:"F550"
        },
        {
          name:"إسكورت"
        },
        {
          name:"ابكوسبورت"
        },
        {
          name:"فان فورد"
        },
        {
          name:"فيجو"
        },
        {
          name:"برونكو"
        },
        {
          name:"تيريتوري"
        },
      ]
    },
    {
      name:" شيفروليه ",
      TypeOfCars:[
        {
          name:"كابريس"
        },
        {
          name:"تاهو"
        },
        {
          name:"سوبربان"
        },
        {
          name:"لومينا"
        },
        {
          name:"سلفرادو"
        },
        {
          name:"كمارو"
        },
        {
          name:"بليزر"
        },
        {
          name:"ابيكا"
        },
        {
          name:"ماليبو"
        },
        {
          name:"افيو"
        },
        {
          name:"كروز"
        },
        {
          name:"اوبترا"
        },
        {
          name:"تريل بليزر"
        },
        {
          name:" افلانش"
        },
        {
          name:"كورفيت"
        },
        {
          name:" فان"
        },
        {
          name:"امبالا"
        },
        {
          name:"ترافيس"
        },
        {
          name:"ابلاندر"
        },
        {
          name:"اكسبرس فان"
        },
        {
          name:"فنشر"
        },
        {
          name:"كابتيفا"
        },
        {
          name:"استروفان"
        },
        {
          name:"سونيك"
        },
        {
          name:"سبارك"
        },
        {
          name:"كرفان"
        },
        {
          name:"كافالير"
        },
        {
          name:"كولورادو"
        },
        {
          name:"جي فان"
        },
        {
          name:"اكوينوكس"
        },
        {
          name:"بولت"
        },
        {
          name:"جروف"
        },
      ]
    },
    {
      name:" نيسان ",
      TypeOfCars:[
        {
          name:"باترول"
        },
        {
          name:"ددسن"
        },
        {
          name:"التيما"
        },
        {
          name:"مكسيما"
        },
        {
          name:"باثفندر"
        },
        {
          name:"صني"
        },
        {
          name:"ارمادا"
        },
        {
          name:"اكستيرا"
        },
        {
          name:"فئة Z"
        },
        {
          name:"نيسان شاص"
        },
        {
          name:"نافارا"
        },
        {
          name:"مورانو"
        },
        {
          name:"تيدا"
        },
        {
          name:" اورفان"
        },
        {
          name:"سكاي لاين"
        },
        {
          name:" سنترا"
        },
        {
          name:"اكس تربل"
        },
        {
          name:"جلوريا"
        },
        {
          name:"بريميرا"
        },
        {
          name:"تيرانو"
        },
        {
          name:"قاشقاي"
        },
        {
          name:"جوك"
        },
        {
          name:"كيكس"
        },
        {
          name:"370Z"
        },
        {
          name:"GTR"
        },
        {
          name:"سيفيليان"
        },
        {
          name:"باترول سفاري"
        },
        {
          name:"سيدرك"
        },
        {
          name:"باترول نيسمو"
        },
      ]
    },
    {
      name:"هيونداي",
      TypeOfCars:[
        {
          name:"سوناتا"
        },
        {
          name:"النترا"
        },
        {
          name:"تراجيت"
        },
        {
          name:"i 40"
        },
        {
          name:"اكسنت"
        },
        {
          name:"كوبية"
        },
        {
          name:"فيراكروز"
        },
        {
          name:"تيريكان"
        },
        {
          name:"توسان"
        },
        {
          name:"جراند سنتافي"
        },
        {
          name:"ستنافي"
        },
        {
          name:"ازيرا"
        },
        {
          name:"جنيسس"
        },
        {
          name:"سنتنيال"
        },
        {
          name:"فيلوستر"
        },
        {
          name:"i-10"
        },
        {
          name:"كونا"
        },
        {
          name:"باليسيد"
        },
        {
          name:"جراند ستنافي"
        },
        {
          name:"H1"
        }
        ,
        {
          name:"ماتريكس"
        }
        ,
        {
          name:"جالوير"
        }
        ,
        {
          name:"كريتا"
        }
        ,
        {
          name:"i 30"
        }
        ,
        {
          name:"فينيو"
        }
      ]
    }
    ,
    {
      name:"لكزس",
      TypeOfCars:[
        {
          name:"LS"
        },
        {
          name:"LX"
        },
        {
          name:"ES"
        },
        {
          name:"GS"
        },
        {
          name:"IS"
        },
        {
          name:"RX"
        },
        {
          name:"GX"
        },
        {
          name:"SC"
        },
        {
          name:"NX"
        },
        {
          name:"LC"
        },
        {
          name:"RC"
        },
        {
          name:"RCF"
        },
        {
          name:"UX"
        },
        {
          name:"GSF"
        }
      ]
    },
    {
      name:" جي ام سي - GMC",
      TypeOfCars:[
        {
          name:"يوكن"
        },
        {
          name:"سوبربان"
        },
        {
          name:"سييرا"
        },
        {
          name:" بيك أب"
        },
        {
          name:"انفوي"
        },
        {
          name:"اكاديا"
        },
        {
          name:"فان"
        },
        {
          name:"سافانا"
        },
        {
          name:"سفاري"
        },
        {
          name:"تيرين"
        },
        {
          name:"جيمي"
        },
      ]
    },
    {
      name:"مرسيدس",
      TypeOfCars:[
        {
          name:"S"
        },
        {
          name:"E"
        },
        {
          name:"SE"
        },
        {
          name:"SEL"
        },
        {
          name:"AMG"
        },
        {
          name:"مرسيدس G"
        },
        {
          name:"C"
        },
        {
          name:"SL"
        },
        {
          name:"CLS"
        },
        {
          name:"ML"
        },
        {
          name:"CL"
        },
        {
          name:"CLK"
        },
        {
          name:"SEC"
        },
        {
          name:"SLK"
        },
        {
          name:"A الفئة"
        },
        {
          name:"GLS"
        },
        {
          name:"GLE"
        },
        {
          name:"GLC"
        },
        {
          name:"GLA"
        },
        {
          name:"CLA"
        }
        ,
        {
          name:"V الفئة"
        }
        ,
        {
          name:"B"
        }
        ,
        {
          name:"GL"
        }
        ,
        {
          name:"GLK"
        }
        ,
        {
          name:"GT مرسيدس"
        }
        ,
        {
          name:"GTS"
        }
        ,
        {
          name:"R"
        }
        ,
        {
          name:" SLC"
        }
        ,
        {
          name:" فان سبرنتر"
        }
        ,
        {
          name:"مايباخ"
        }
        ,
        {
          name:"GLB"
        }
      ]
    }
    ,
    {
      name:"هوندا",
      TypeOfCars:[
        {
          name:"اكورد"
        },
        {
          name:"سيفيك"
        },
        {
          name:"اوديسي"
        },
        {
          name:"CRV"
        },
        {
          name:"بايلوت"
        },
        {
          name:"سيتي"
        },
        {
          name:"ليجيند"
        },
        {
          name:"بريليود"
        },
        {
          name:"انتجرا"
        },
        {
          name:"HRV"
        }
      ]
    },
    {
      name:"بي ام دبليو - BMW",
      TypeOfCars:[
        {
          name:"الفئة السابعة"
        },
        {
          name:"الفئة الخامسة"
        },
        {
          name:"X الفئة "
        },
        {
          name:"الفئة الثالثة"
        },
        {
          name:"الفئة السادسة"
        },
        {
          name:"الفشة الأولى"
        },
        {
          name:" M الفئة"
        },
        {
          name:"ميني كوبر"
        },
        {
          name:"Z الفئة"
        },
        {
          name:"i الفئة"
        },
        {
          name:"الفئة الثامنة"
        },
        {
          name:"الفئة الثانية"
        },
        {
          name:"الفئة الرابعة"
        }
      ]
    },
    {
      name:"كيا",
      TypeOfCars:[
        {
          name:"اوبتيما"
        },
        {
          name:"سيراتو"
        },
        {
          name:"ريو"
        },
        {
          name:"كارنيفال"
        },
        {
          name:"سبورتاج"
        },
        {
          name:"كادينزا"
        },
        {
          name:"اوبيروس"
        },
        {
          name:"سورنتو"
        },
        {
          name:"كارينز"
        },
        {
          name:"بيكانتو"
        },
        {
          name:"موهافي"
        },
        {
          name:"كوريس"
        },
        {
          name:"سول"
        },
        {
          name:"سيفيا"
        },
        {
          name:"K900"
        },
        {
          name:"بيجاس"
        },
        {
          name:"تيلورايد"
        },
        {
          name:"ستينجر"
        },
        {
          name:"سيلتوس"
        },
        {
          name:"نيرو"
        }
        ,
        {
          name:"K5"
        }
        ,
        {
          name:"سونيت"
        }
        ,
        {
          name:"K8"
        }
      ]
    },
    {
      name:"دودج",
      TypeOfCars:[
        {
          name:"تشارجر"
        },
        {
          name:"تشالنجر"
        },
        {
          name:"دورانجو"
        },
        {
          name:"كارافان"
        },
        {
          name:"رام"
        },
        {
          name:"نيترو"
        },
        {
          name:"كاليبر"
        },
        {
          name:"فايبر"
        },
        {
          name:"دودج بيك أب"
        },
        {
          name:"كارافان"
        },
        {
          name:"انتربيد"
        }
      ]
    },
    {
      name:"ميتسوبيشي",
      TypeOfCars:[
        {
          name:"باجيرو"
        },
        {
          name:"لانسر"
        },
        {
          name:"L200"
        },
        {
          name:"ناتيفا"
        },
        {
          name:"جالنت"
        },
        {
          name:"كولت"
        },
        {
          name:"ماجنا"
        },
        {
          name:"سيجما"
        },
        {
          name:"ASX"
        },
        {
          name:"اتراج"
        },
        {
          name:" اكليبس كروس"
        },
        {
          name:"اوتلاند"
        },
        {
          name:"سبيس ستار"
        },
        {
          name:"مونتيرو"
        },
        {
          name:"اكسباندر"
        },
        {
          name:"جرانديز"
        }
      ]
    },
    {
      name:"مازدا",
      TypeOfCars:[
        {
          name:"مازدا6"
        },
        {
          name:"CX9"
        },
        {
          name:"مازدا 3"
        },
        {
          name:"323"
        },
        {
          name:"626"
        },
        {
          name:"CX7"
        },
        {
          name:"BT50"
        },
        {
          name:"MPV"
        },
        {
          name:"CX5"
        },
        {
          name:"CX2"
        },
        {
          name:"RX8"
        },
        {
          name:"MX-5"
        },
        {
          name:"CX3"
        },
        {
          name:"مازدا 2"
        },
        {
          name:"مازدا 5"
        },
        {
          name:"CX30"
        }
      ]
    },
    {
      name:"ايسوزو",
      TypeOfCars:[
        {
          name:"اسندر"
        },
        {
          name:"ديماكس"
        },
        {
          name:"MUX"
        }
      ]
    },
    {
      name:"سوزوكي",
      TypeOfCars:[
        {
          name:"فيتارا"
        },
        {
          name:"ساموراي"
        },
        {
          name:"سويفت"
        },
        {
          name:"جمني"
        },
        {
          name:"ليانا"
        },
        {
          name:"SX4"
        },
        {
          name:"ارتيجا"
        },
        {
          name:"بالينو"
        },
        {
          name:"جراند فيتارا"
        },
        {
          name:"سياز"
        },
        {
          name:"سيليريو"
        },
        {
          name:"APV بيك أب"
        },
        {
          name:"APV فان"
        },
        {
          name:"APV كاري"
        },
        {
          name:"ديزاير"
        },
        {
          name:"كيزاشي"
        }
      ]
    },
    {
      name:"جيلي",
      TypeOfCars:[
        {
          name:"EC7"
        },
        {
          name:"EC8"
        },
        {
          name:"باندا"
        },
        {
          name:"امجراند7"
        },
        {
          name:"GS امجراند"
        },
        {
          name:"X7 امجراند"
        },
        {
          name:"بينراي"
        },
        {
          name:"كولراي"
        },
        {
          name:"ازكارا"
        },
        {
          name:"توجيلا"
        },
        {
          name:"اوكافانجو "
        },
        {
          name:"مونجارو"
        }
      ]
    },
    {
      name:"بيجو",
      TypeOfCars:[
        {
          name:"307"
        },
        {
          name:"407"
        },
        {
          name:"206"
        },
        {
          name:"508"
        },
        {
          name:"406"
        },
        {
          name:"بارتنر"
        },
        {
          name:"607"
        },
        {
          name:"404"
        },
        {
          name:"3008"
        },
        {
          name:"301"
        },
        {
          name:"5008"
        },
        {
          name:"بوكسر"
        },
        {
          name:"اكسبيرت"
        }
      ]
    },
    {
      name:"بايك",
      TypeOfCars:[
        {
          name:" D50 "
        },
        {
          name:" X7 "
        }
      ]
    },
    {
      name:"فاو",
      TypeOfCars:[
        {
          name:"B70"
        },
        {
          name:"T70"
        }
      ]
    },
    {
      name:"هافال",
      TypeOfCars:[
        {
          name:"جوليان"
        },
        {
          name:"H6"
        }
      ]
    },
    {
      name:"ماكسوس",
      TypeOfCars:[
        {
          name:"T90"
        },
        {
          name:"T60"
        }
      ]
    },
    {
      name:"MG ام جي",
      TypeOfCars:[
        {
          name:"5"
        },
        {
          name:"6"
        },
        {
          name:"HS"
        },
        {
          name:"MG RX8"
        },
        {
          name:"RX5"
        },
        {
          name:"ZS"
        },
        {
          name:"T60"
        },
        {
          name:"ام جي جي تي"
        },
      ]
    },
    {
      name:"كيا",
      TypeOfCars:[
        {
          name:"اوبتيما"
        },
        {
          name:"سيراتو"
        },
        {
          name:"ريو"
        },
        {
          name:"كارنيفال"
        },
        {
          name:"سبورتاج"
        },
        {
          name:"كادينزا"
        },
        {
          name:"اوبيروس"
        },
        {
          name:"سورنتو"
        },
        {
          name:"كارينز"
        },
        {
          name:"بيكانتو"
        },
        {
          name:"موهافي"
        },
        {
          name:"كوريس"
        },
        {
          name:"سول"
        },
        {
          name:"سيفيا"
        },
        {
          name:"K900"
        },
        {
          name:"بيجاس"
        },
        {
          name:"تيلورايد"
        },
        {
          name:"ستينجر"
        },
        {
          name:"سيلتوس"
        },
        {
          name:"نيرو"
        }
        ,
        {
          name:"K5"
        }
        ,
        {
          name:"سونيت"
        }
        ,
        {
          name:"K8"
        }
      ]
    },
    {
      name:"شانجان",
      TypeOfCars:[
        {
          name:"ايدو"
        },
        {
          name:"CS35"
        },
        {
          name:"CS75"
        },
        {
          name:"CS95"
        },
        {
          name:" V7 شانجان"
        },
        {
          name:"CS85"
        },
        {
          name:"السفن "
        },
        {
          name:"هنتر"
        },
        {
          name:"CS35 بلس"
        },
        {
          name:"CS75 بلس"
        },
        {
          name:"يوني تي"
        },
        {
          name:"يوني كي"
        },
        {
          name:"يوني في"
        }
        
      ]
    },
    {
      name:"رينو",
      TypeOfCars:[
        {
          name:"ميجان"
        },
        {
          name:"فلوانس"
        },
        {
          name:"سافران"
        },
        {
          name:"لاغونا"
        },
        {
          name:" كليو"
        },
        {
          name:"تاليسمان"
        },
        {
          name:"داستر "
        },
        {
          name:"دوكر فان"
        },
        {
          name:"سيمبول"
        },
        {
          name:"كابشتر"
        },
        {
          name:"كوليوس"
        },
        {
          name:"ماستر"
        },
        {
          name:"ميجان GT"
        }
        ,
        {
          name:"ميجان RS"
        }
      ]
    },
    {
      name:"شيري",
      TypeOfCars:[
        {
          name:"QQ"
        },
        {
          name:"A5 شيري"
        },
        {
          name:"ايستار"
        },
        {
          name:"كوين"
        },
        {
          name:" A3 شيري "
        },
        {
          name:"A1 شيري"
        },
        {
          name:"اريزو 3 "
        },
        {
          name:"اريزو 5 "
        },
        {
          name:"اريزو 6"
        },
        {
          name:"تيجو 2"
        },
        {
          name:"تيجو7"
        },
        {
          name:"تيجو 8"
        },
        {
          name:"تيجو 4"
        },
        {
          name:"ميجان GT"
        }
        ,
        {
          name:"ميجان RS"
        }
      ]
    },
    {
      name:"GAC جي اي سي ",
      TypeOfCars:[
        {
          name:"GA3"
        },
        {
          name:"GA4"
        },
        {
          name:"GA8"
        },
        {
          name:"GS3"
        },
        {
          name:" GS4"
        },
        {
          name:"GS8"
        },
        {
          name:"GN6 "
        },
        {
          name:"GN8"
        },
        {
          name:"GS5"
        },
        {
          name:"GA6"
        },
        {
          name:"امباو"
        }
      ]
    },
    {
      name:"GREAT WALL جريت وول",
      TypeOfCars:[
        {
          name:"ونجل 5"
        },
        {
          name:"ونجل 6"
        },
        {
          name:"ونجل 7"
        },
        {
          name:"باور"
        }
      ]
    },
    {
      name:"تاتا",
      TypeOfCars:[
        {
          name:"زينون"
        }
      ]
    },
    {
      name:"جيتور",
      TypeOfCars:[
        {
          name:"X70"
        },
        {
          name:"X70S"
        },
        {
          name:"X90"
        },
        {
          name:"داشينج"
        }
      ]
    },
    {
      name:"ماكسيس",
      TypeOfCars:[
        {
          name:"D90"
        },
        {
          name:"V80 ماكسيس"
        },
        {
          name:"T60 ماكسيس"
        },
        {
          name:"V90"
        },
        {
          name:" T70"
        },
        {
          name:"G50"
        },
        {
          name:"G10 "
        },
        {
          name:"D90 برو "
        },
        {
          name:"D60"
        },
        {
          name:"60 تورنيدو"
        }
      ]
    },
    {
      name:"JAC",
      TypeOfCars:[
        {
          name:"جيمعها متاحة "
        }
      ]
    }
  ]



  const changeMarka = (e)=>{
    setCarMarka(e.target.value)
    setFilter(Cars.find(ctr => ctr.name === e.target.value).TypeOfCars)
  }
  const changeType = (e)=>{
    setTypeOfCar(e.target.value)
  }


  const navigate = useNavigate()

// ------صورة رخصه القيادة--------
    const handleUpload1 = (e) =>{
        console.log(e.target.files[0])
        const imgs = ref(imgDB,`Imgs/Individual/DriverLicenseImg/${v4()}`)
        uploadBytes(imgs,e.target.files[0]).then(data=>{
            getDownloadURL(data.ref).then(val=>{
                setImgDriverLicense(val)
            })
        })
    }
    
// ------صورة الهوية\الاقامة --------
const handleUpload2 = (e) =>{
    console.log(e.target.files[0])
    const imgs = ref(imgDB,`Imgs/Individual/ID-Images/${v4()}`)
    uploadBytes(imgs,e.target.files[0]).then(data=>{
        getDownloadURL(data.ref).then(val=>{
          setImgId(val)
        })
    })
}


// ------صورة تعريف الراتب  --------
const handleUpload3 = (e) =>{
    console.log(e.target.files[0])
    const imgs = ref(imgDB,`Imgs/Individual/Salary-Images/${v4()}`)
    uploadBytes(imgs,e.target.files[0]).then(data=>{
        getDownloadURL(data.ref).then(val=>{
          setImgSalary(val)
        })
    })
}


const handleSubmit = (e) => {
  e.preventDefault();
}


  return (
    <>
      <Helmet>
        <title>  طلب تمويل سيارات للافراد - رؤية لتمويل السيارات</title>
        <meta
      name="description"
      content=" طلب تمويل سيارات للافراد لرؤية لتمويل السيارات للافراد والشركات وللتمويل الشخصي "
      /> 
      <link rel="canonical" href="/IndividualRequest"></link>

      </Helmet>
    <div data-aos="fade-up" type='form' className='bg-[#f5f5f5] p-4 md:pt-11 ' lang="ar">
      <h1 className="text-center text-[rgb(47,114,140)] text-3xl  font-extrabold pb-9"> طلب تمويل للافراد</h1>
      <form onSubmit={handleSubmit} className='flex-col text-right text-black text-lg '>
        <h1 className="font-extrabold text-xl text-center text-[rgb(47,114,140)]">نرجو منكم التكرم بتعبئة النموذج التالي وسيتم الرد عليكم خلال مدة اقصاها 24 ساعة</h1>  
        <p className=' font-bold py-2'>: الاسم بالكامل</p>
      <input required  onChange={(e)=>{setFullName(e.target.value)}} className=' border border-[rgb(47,114,140)]  bg-white text-right text-black rounded-lg w-full h-[40px] ' />
      <p className=' font-bold py-2'>رقم الجوال :<spin className='text-red-700 py-2 text-xs px-2 font-bold'>الرجاء ادخال الجوال بالارقام الانجليزية</spin></p>
      <input required onChange={(e)=>{setPhoneNumber(e.target.value)}}  type="number" lang="en" className=' border border-[rgb(47,114,140)]  bg-white text-black rounded-lg w-full h-[40px] text-right ' />

      <p className=' font-bold py-2'>: البريد الالكتروني</p>
      <input required onChange={(e)=>{setEmail(e.target.value)}} className=' border border-[rgb(47,114,140)]  bg-white text-black rounded-lg w-full h-[40px] text-right ' />

      <p className=' font-bold py-2'>:المدينة</p>
      <select required onChange={(e)=>setCity(e.target.value)}  className='bg-white w-full h-[40px] rounded-lg text-right border-[rgb(47,114,140)]'>
      المدينة
      <option>اختار المدينة</option>
      <option>مكة</option>
      <option> المدينة</option>
      <option>الرياض</option>
      <option>ابها </option>
      <option> الاحساء</option>
      <option> الباحة</option>
      <option> الجبيل</option>
      <option> الجوف</option>
      <option> الدمام</option>
      <option> الطائف</option>
      <option> القصيم</option>
      <option> تبوك</option>
      <option> جدة</option>
      <option> حفر </option>
      <option> حائل </option>
      <option> عرعر </option>
      <option> عسير </option>
      <option> نجران </option>
      <option> ينبع </option>
      <option> عفيف </option>
      <option> القطيف </option>
      <option> الحاير </option>
      <option> الافلاج </option>
      <option> القويعية </option>
      <option> وادي الدواسر </option>
      <option> خميس مشيط</option>
      </select>
      
      <p className=' font-bold py-2' >:الجنسية</p>
      <select required onChange={(e)=> setNationalty(e.target.value)}  className='bg-white w-full h-[40px] rounded-lg text-right border-[rgb(47,114,140)]'>
        <option>اختار الجنسية</option>
        <option>سعودي</option>
        <option>غير سعودي</option>
      </select>

      <p className=' font-bold py-2'>:القطاع </p>
      <select onChange={(e)=> setField(e.target.value)} className='bg-white w-full h-[40px] rounded-lg text-right border-[rgb(47,114,140)]'>
        <option>اختار القطاع </option>
        <option>قطاع عسكري</option>
        <option>قطاع مدني </option>
        <option>قطاع خاص </option>
        <option>متقاعد</option>
      </select>

      <p className=' font-bold py-2'>: الراتب</p>
      <p><input required onChange={(e)=>{setSalary(e.target.value)}}  className= 'border border-[rgb(47,114,140)] bg-white w-full h-[40px] rounded-lg text-right' type='number'placeholder='الراتب'/> </p>

      <p className=' font-bold py-2'>: الالتزامات المالية</p>
      <p><input required onChange={(e)=>{setFinancialObligations(e.target.value)}} className=' border border-[rgb(47,114,140)]  bg-white w-full h-[40px] rounded-lg text-right' type='number' placeholder='الاتزامات المالية '/> </p>

      <p className=' font-bold py-2'>: البنك</p>
      <p>
        <select required onChange={(e)=>{setBank(e.target.value)}} className='bg-white w-full h-[40px] rounded-lg text-right border-[rgb(47,114,140)]'>
        <option>اختار البنك</option>
        <option>بنك الراجحي</option>
        <option> بنك الاهلي</option>
        <option>بنك الرياض </option>
        <option>البنك السعودي الفرنسي</option>
        <option>البنك الاول</option>
        <option>بنك البلاد</option>
        <option>بنك الامارات </option>
        <option>بنك الاستثمار</option>
        <option>بنك الجزيرة</option>
        <option>بنك العربي</option>
        <option>بنك سامبا</option>
        <option>بنك ساب</option>
        <option>بنك الانماء </option>
        <option>كاش</option>
        <option>يستلم بشيك</option>
        <option>تحويل وليس ايداع</option>

      </select>
      </p>
      <br/>
      <p className=' font-bold py-2'>هل لديك قرض عقاري ؟</p>
      <select required onChange={(e)=>setMortgage(e.target.value)} className='bg-white w-full h-[40px] rounded-lg text-right border-[rgb(47,114,140)]'>
        <option>هل لديك قرض عقاري ؟</option>
        <option>نعم</option>
        <option>لا</option>
      </select>
      <br/>
      <br/>
      <p className=' font-bold py-2'>هل لديك تعثر في سمة ؟</p>
      <select required onChange={(e)=>{setSema(e.target.value)}} className='bg-white w-full h-[40px] rounded-lg text-right border-[rgb(47,114,140)]'>
        <option>هل لديك تعثر في سمة ؟</option>
        <option>نعم</option>
        <option>لا</option>
      </select>

      <br/>
      <br/>
      <p className=' font-bold py-2'>: ماركة السيارة</p>
      <select required value={CarMarka} onChange={changeMarka} className='bg-white w-full h-[40px] rounded-lg text-right border-[rgb(47,114,140)]'>
        <option>ماركة السيارة</option>
        {
          Cars.map((data)=>{
            return( 
              <option value={data.name}>{data.name}</option>
            )
          })
        }
      </select>
      <br/>
      <br/>

      <p className=' font-bold py-2'>: نوع السيارة</p>
      <select required value={TypeOfCar} onChange={changeType}  className='bg-white w-full h-[40px] rounded-lg text-right border-[rgb(47,114,140)]'>
        <option>نوع السيارة</option>
        {
          Filter.map((data)=>{
            return(
              <option key={data.id} value={data.name}>{data.name}</option>
            )
          })
        }
      </select>

      <br/>
      <br/>
      <p className=' font-bold py-2'>: سنة تصنيع السيارة</p>
      <select required onChange={(e)=>{setYear(e.target.value)}} className='bg-white w-full h-[40px] rounded-lg text-right border-[rgb(47,114,140)]'>
        <option>سنة تصنيع السيارة </option>
        <option>2020</option>
        <option>2021</option>
        <option>2022</option>
        <option>2023</option>
        <option>2024</option>
        <option>2025</option>
      </select>
      <br/>
      <br/>
      <p className=' font-bold py-2'>:صورة رخصة القيادة </p>
      <input required onChange={(e)=>{setImgDriverLicense(e.target.value)}} onInput={handleUpload1} type="file" className=" bg-white"/>
      <p className=' font-bold py-2'>:صورة الهوية/الاقامة </p>
      <input required onChange={(e)=>{setImgId(e.target.value)}}  onInput={handleUpload2}  type="file" className=" bg-white"/>
      <p className=' font-bold py-2'>:صورة تعريف الراتب </p>
      <input required onChange={(e)=>{setImgSalary(e.target.value)}}  onInput={handleUpload3}  type="file" className=" bg-white"/>

      <p className=' font-bold py-2'>:هل عليك مخالفات مرورية </p>
      <select onChange={(e)=>{setTrafficViolations(e.target.value)}} className='bg-white w-full h-[40px] rounded-lg text-right border-[rgb(47,114,140)]'>
        <option>هل عليك مخالفات مرورية </option>
        <option>نعم</option>
        <option>لا</option>
      </select>
      <br/>
      <br/>
      <p className=' font-bold py-2'> هل رخصة القيادة سارية؟</p>
      <select onChange={(e)=>{setDriverLicence(e.target.value)}} className='bg-white w-full h-[40px] rounded-lg text-right border-[rgb(47,114,140)]'>
        <option>هل رخصة القيادة سارية ؟</option>
        <option>نعم</option>
        <option>لا</option>
      </select>
      <br/>
      <br/>

      <p className=' font-bold py-2'> ماهو نوع طلبك ؟</p>
      <select onChange={(e)=>{setTypeOfRequest(e.target.value)}} className='bg-white w-full h-[40px] rounded-lg text-right border-[rgb(47,114,140)]'>
        <option>ماهو نوع طلبك ؟</option>
        <option>طلب تمويل </option>
        <option>طلب استفسار</option>
      </select>
      <br/>
      <br/>
      <button onClick={ ()=>{
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger"
          },
          buttonsStyling: false
        });
        swalWithBootstrapButtons.fire({
            title: "هل أنت متأكد من ارسال البيانات ؟",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "نعم",
            cancelButtonText: "لا ",
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
              const handleClick = async () =>{
                const valRef = collection(txtDB,'Individual Requests')
                await addDoc(valRef,{FullNameVal:FullName,
                  PhoneNumberVal:PhoneNumber,EmailVal:Email
                  ,SalaryVal:Salary,FinancialObligationsVal:FinancialObligations
                  ,ImgDriverLicense:ImgDriverLicense,ImgId:ImgId,ImgSalary:ImgSalary
                  ,City:City,Nationalty:Nationalty,Field:Field,Bank:Bank,
                  Mortgage:Mortgage,Sema:Sema,CarMarkaVal:CarMarka ,TypeOfCarVal:TypeOfCar,Year:Year
                  ,TrafficViolations:TrafficViolations,DriveLicence:DriveLicence,TypeOfRequest:TypeOfRequest,
                  RequestStatus:"جديد"
                })
              navigate('/')
            }
            handleClick()
            swalWithBootstrapButtons.fire({
              title: "تم الارسال سيتم التواصل معك في اقرب وقت ",
              icon: "success"
            });
                          } else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
            ) {
              handleSubmit()
              swalWithBootstrapButtons.fire({
                title: "تم الالغاء",
                icon: "error"
              });
            }
          });
        }} type="submit" className=" px-9 mx-auto btn text-white bg-[rgb(47,114,140)]" > ارسال الطلب</button>
      </form>
    </div>
        </>
  )
}

export default IndividualReq