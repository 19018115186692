import Footer from './Component/Footer';
import NavbarMenu from'./Component/NavbarMenuMobile'
import { Route, Routes } from 'react-router';
import Home from './Pages/Home'
import About from './Pages/About'
import Offers from './Pages/Offers';
import Contact from './Pages/Contact';
import Jobs from './Pages/Jobs'
import OurServices from './Pages/OurServices'
// import WhyUs from './Pages/WhyUs'
import CompanyRequest from './Pages/CompanyReq'
// import PersonalRequest from './Pages/PersonalReq'
import PrivacyPolicy from './Pages/PrivacyPolicy'
import IndividualReq from './Pages/IndividualRequest';
import SignIn from './Pages/SignIn';
import ProtectedPage from './Pages/ProtectedRoutePage/ProtectedPage';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './Config/firebase';
import { useEffect, useState } from 'react';
import ContentMangment from './Pages/Dashboard/ContentMangment';
import CompanyRequests from './Pages/Dashboard/CompanyRequests'
import IndividualRequests from './Pages/Dashboard/IndividualRequests';
// import PersonalRequests from './Pages/Dashboard/PersonalRequests';
import ComplaintsRequests from './Pages/Dashboard/ComplaintsRequests'
import JobWantedRequests from './Pages/Dashboard/JobWantedRequests'
import JobApplication from './Pages/JobApplication';
import NavbarMenuDesktop from './Component/NavbarMenuDesktop';
import "./App.css"
import SliderController from './Pages/Dashboard/SliderController';

function App() {
  const [user,setUser]=useState(null)

  useEffect(()=>{
    const unsubscribe = onAuthStateChanged(auth , (user)=>{
      if (user){
        setUser(user);
        return;
      }

      setUser(null)
    });
    return () => unsubscribe()
    },[])

  return (
    <div className=' bg-white dark:bg-white md:min-w-full' >
    <NavbarMenu/>
    <div className='md:min-h-[50px] '>
    <NavbarMenuDesktop/>
    </div>
    <Routes>
      {/*Public Pages*/}
      <Route path='/' element={<Home/>} />
      <Route path='/about' element={<About/>} />
      <Route path='/offers' element={<Offers/>} />
      <Route path='/contact' element={<Contact/>} />
      <Route path='/jobs' element={<Jobs/>} />
      <Route path='/OurServices' element={<OurServices/>} /> 
      {/* <Route path='/WhyUs' element={<WhyUs/>}/> */}
      <Route path='/PrivacyPolicy' element={<PrivacyPolicy/>}/>
      <Route path='/IndividualRequest' element={<IndividualReq/>}/>
      <Route path='/CompanyRequest' element={<CompanyRequest/>}/>
      {/* <Route path='/PersonalRequest' element={<PersonalRequest/>}/> */}
      <Route path='/JobApplication' element={<JobApplication/>}/>
      <Route path='/SignIn' element={<SignIn/>}/>
      {/* Protected Dashboard */}
      <Route path='/ContentMangment'  element={<ProtectedPage user={user} ><ContentMangment/></ProtectedPage>}/>
      <Route path='/CompanyRequests' element={<ProtectedPage user={user}><CompanyRequests/></ProtectedPage>}/>
      <Route path='/IndividualRequests'  element={<ProtectedPage user={user}><IndividualRequests /></ProtectedPage>}/>
      <Route path='/SliderController'  element={<ProtectedPage user={user}><SliderController/></ProtectedPage>}/>
      {/* <Route path='/PersonalRequests' element={<ProtectedPage user={user}><PersonalRequests/></ProtectedPage>}/> */}
      <Route path='/ComplaintsRequests' element={<ProtectedPage user={user}><ComplaintsRequests/></ProtectedPage>}/>
      <Route path='/JobWantedRequests' element={<ProtectedPage user={user}><JobWantedRequests/></ProtectedPage>}/>
    </Routes>

    <Footer/>
    </div>
  );
}

export default App;
